// eslint-disable-next-line @typescript-eslint/naming-convention
import FingerprintJS, { BuiltinComponents } from '@fingerprintjs/fingerprintjs';
import { lastValueFrom } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { AsyncFlashSubject, ZoneService } from '@bp/frontend/rxjs';

@Injectable({
	providedIn: 'root',
})
export class FingerprintService {

	private readonly __zoneService = inject(ZoneService);

	private readonly __hash$ = new AsyncFlashSubject<string>();

	constructor() {
		void this.__generateFingerprintHash();
	}

	async getHash(): Promise<string> {
		return lastValueFrom(this.__hash$);
	}

	private __generateFingerprintHash(): void {
		void this.__zoneService.runOutsideAngular(async () => {
			const fingerprint = await FingerprintJS.load({
				monitoring: false,
			});

			const { components } = await fingerprint.get();

			this.__zoneService.runInAngularZone(() => void this.__hash$.complete(
				this.__getDeviceFingerprint(components),
			));
		});
	}

	/**
	 * We removed all components that can be different when read inside iframe on a cross-origin host and from
	 * our own host
	 */
	private __getDeviceFingerprint({
		domBlockers,
		languages,
		fonts,
		fontPreferences,
		localStorage,
		sessionStorage,
		indexedDB,
		screenFrame,
		screenResolution,
		cookiesEnabled,
		canvas,
		vendorFlavors,
		audio,
		videoCard,
		vendor,
		touchSupport,
		plugins,
		osCpu,
		openDatabase,
		cpuClass,
		hardwareConcurrency,
		deviceMemory,
		...hashComponents
	}: BuiltinComponents): string {
		return FingerprintJS.hashComponents(hashComponents);
	}
}
