import { map } from 'rxjs/operators';

import { Directive, inject, OnInit } from '@angular/core';
import { Dir, Directionality } from '@angular/cdk/bidi';

import { LanguagesService } from '@bp/frontend/domains/checkout/services';

@Directive({
	selector: 'bpBidiRoot',
	standalone: true,
	providers: [{ provide: Directionality, useExisting: BidiRootDirective }],
})
export class BidiRootDirective extends Dir implements OnInit {

	private readonly __isRtl$ = inject(LanguagesService).currentLanguage$
		.pipe(map(lang => lang?.isRightToLeft));

	ngOnInit(): void {
		this.__isRtl$
			.subscribe(isRtl => (this.dir = isRtl ? 'rtl' : 'ltr'));
	}

}
