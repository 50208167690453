// eslint-disable-next-line @typescript-eslint/naming-convention
import * as QRCode from 'qrcode';

import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, HostBinding, inject } from '@angular/core';

import { FADE } from '@bp/frontend/animations';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { CheckoutSession, CryptoTransaction, IPaymentMethod } from '@bp/checkout-frontend/models';
import { AppService, PaymentApiService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-crypto-qr-page',
	templateUrl: './crypto-qr-page.component.html',
	styleUrls: [ './crypto-qr-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class CryptoQrPageComponent extends Destroyable implements AfterViewInit {

	protected readonly _appService = inject(AppService);

	private readonly __paymentApiService = inject(PaymentApiService);

	private readonly __hostNotifier = inject(HostNotifierService);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected readonly _transaction = <CryptoTransaction> this._appService.transaction;

	protected get _cryptoOrderDetails(): CryptoTransaction['crypto'] {
		return this._transaction.crypto;
	}

	protected get _paymentMethod(): IPaymentMethod {
		return this._appService.paymentMethod;
	}

	protected _cryptoLogoAssets = 'assets/images/cryptos/';

	@HostBinding('class.expired')
	protected _isSessionExpired = false;

	protected get _amountMaxFontSize(): number {
		return this._appService.isBrightTheme ? 24 : 27;
	}

	@ViewChild('qrCode', { static: false })
	private readonly __canvasRef?: ElementRef;

	protected _buyLinks: Record<string, string> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		crypto_acquirer: this._transaction.html,
	};

	constructor() {
		super();

		this.__hostNotifier.cryptoQuickResponseCodePageOpen({
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider,
		});

		this.__scheduleCheckPspTransactionStatus(this._transaction.id);
	}

	ngAfterViewInit(): void {
		this.__mountQRCode();
	}

	private __scheduleCheckPspTransactionStatus(transactionId: string): void {
		setTimeout(
			() => this.__paymentApiService
				.checkPspTransactionStatus(transactionId)
				.pipe(takeUntilDestroyed(this))
				.subscribe({
					next: transaction => {
						this._appService.transaction = transaction;

						if (transaction.isPending)
							this.__scheduleCheckPspTransactionStatus(transaction.id);
						else
							this._appService.navigateAccordingFinalTransactionStatus(transaction);
					},
					error: (error: unknown) => {
						TelemetryService.captureError(error);
					},
				}),
			2.5 * 60 * 1000,
		);
	}

	private __mountQRCode(): void {
		if (!this.__canvasRef)
			return;

		void QRCode.toCanvas(
			this.__canvasRef.nativeElement,
			`${ this._cryptoOrderDetails.address }`,
			{
				margin: 0,
			},
		);
	}
}
