import { inject, NgModule } from '@angular/core';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

@NgModule({
	imports: [],
	declarations: [],
	providers: [],
})
export class GlobalApiMockModule {
	constructor() {
		if (MockedBackendState.isActive) {
			void inject(ApiMockPluginsManagerService)
				.register(
					GlobalApiMockModule,
					async () => import('./plugins/global-api-mock-plugin'),
				);
		}
	}
}
