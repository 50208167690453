import { Clipboard } from '@angular/cdk/clipboard';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output
} from '@angular/core';

@Component({
	selector: 'bp-copy-to-clipboard[value]',
	templateUrl: './copy-to-clipboard.component.html',
	styleUrls: [ './copy-to-clipboard.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {

	private readonly __clipboard = inject(Clipboard);

	private readonly __cdr = inject(ChangeDetectorRef);

	@Input() value!: string;

	@Input() copiedText: string | null = 'Copied to clipboard';

	@Input() copyText?: string | null = 'Copy';

	@Output() readonly copied = new EventEmitter<void>();

	protected _isCopied = false;

	copy(mouseEvent: MouseEvent): void {
		mouseEvent.stopPropagation();

		if (mouseEvent.ctrlKey || mouseEvent.metaKey || mouseEvent.button !== 0)
			return;

		mouseEvent.preventDefault();

		this.__clipboard.copy(this.value);

		this.copied.emit();

		if (this.copiedText)
			this._markAsCopiedForShortTimePeriod();
	}

	private _markAsCopiedForShortTimePeriod(): void {
		this._isCopied = true;

		setTimeout(() => {
			this._isCopied = false;

			this.__cdr.detectChanges();
		}, 5000);
	}

}
