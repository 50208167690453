import { DTO, MetadataEntity, Required } from '@bp/shared/models/metadata';

export class MigrationSource extends MetadataEntity {

	@Required()
	id!: string;

	@Required()
	name!: string;

	constructor(dto: DTO<MigrationSource>) {
		super(dto);
	}

}
