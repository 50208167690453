(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() : typeof define === 'function' && define.amd ? define(factory) : (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.helphero = factory());
})(this, function () {
  'use strict';

  var methods = ["startTour", "advanceTour", "cancelTour", "identify", "anonymous", "update", "on", "off", "openChecklist", "closeChecklist", "startChecklist", "setOptions", "showBeacon", "hideBeacon"];
  var initializedAppId;
  function init(appId) {
    if (typeof appId !== "string" || appId === "") {
      throw new Error("Invalid HelpHero App ID: " + appId);
    }
    if (initializedAppId != null && initializedAppId !== appId) {
      throw new Error("HelpHero does not support initializing multiple Apps on the same page. Trying to initialize with App ID \"" + appId + "\" which is different from previously used App ID \"" + initializedAppId + "\"");
    }
    var host = window;
    if (host.HelpHero != null) {
      return host.HelpHero;
    }
    var tasks = [];
    var instance = function () {
      tasks.push(arguments);
    };
    host.HelpHero = instance;
    instance.q = tasks;
    methods.forEach(function (method) {
      instance[method] = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        return host.HelpHero.apply(null, [method].concat(args));
      };
    });
    initializedAppId = appId;
    var script = document.createElement("script");
    script.src = "https://app.helphero.co/embed/" + appId;
    script.async = true;
    document.body.appendChild(script);
    return instance;
  }
  init["default"] = init;
  return init;
});