<div *ngIf="show" @fade [@.disabled]="!!inline">
	<ng-container *ngIf="redirecting; else defaultTpl">
		<h3>{{ 'cmpt.processing-stub.new-tab-title' | translate }}</h3>

		<div [innerHTML]="'cmpt.processing-stub.text1' | translate"></div>
	</ng-container>

	<ng-template #defaultTpl>
		<h3>
			{{
				(newTab
					? 'cmpt.processing-stub.new-tab-title'
					: checking
					? 'cmpt.processing-stub.checking-title'
					: retrying
					? 'cmpt.processing-stub.retrying-title'
					: 'cmpt.processing-stub.title'
				) | translate
			}}
		</h3>

		<div
			[innerHTML]="(newTab ? 'cmpt.processing-stub.new-tab-text' : 'cmpt.processing-stub.text1') | translate"
		></div>
		<div>{{ 'cmpt.processing-stub.text2' | translate }}</div>
	</ng-template>

	<ng-container *ngIf="secure">
		<svg class="three-d-secure-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 508">
			<path
				d="M259.4 507l-17.7-1.8c-18.1-2-35.5-6.5-52.6-12.7-15.8-5.7-30.9-12.9-45.2-21.7-8.2-5-16.2-10.5-23.8-16.3-11.9-9.1-22.7-19.4-32.8-30.4-8.9-9.7-16.9-20.1-24-31.1-5.9-9-11.3-18.2-15.9-27.9-5.6-11.7-10.6-23.7-14.3-36.1-.8-2.7 0-5.1 2.3-6.4 2.7-1.5 5.9-.2 7.2 3.1 1 2.6 1.7 5.3 2.6 7.9 4.6 12.9 10.1 25.4 16.6 37.4 6.4 11.7 13.8 22.7 21.9 33.3 3.6 4.7 7.7 9.1 11.6 13.6 2.6 3 5.3 5.9 8.2 8.7 9.7 9.4 20 18.2 31 26 12.1 8.6 24.9 16 38.3 22.3 15.2 7.1 31 12.5 47.3 16.3 7.6 1.8 15.3 3.1 23 4.2 5.4.8 10.9 1.1 16.3 1.3 8.8.3 17.5.6 26.3.5 6.1-.1 12.2-1.1 18.3-1.6 7.6-.6 15.1-2 22.5-3.7 5.7-1.3 11.3-2.6 16.9-4.2 15.5-4.4 30.3-10.4 44.6-17.9 12.2-6.4 23.8-13.9 34.8-22.2 12-9 22.8-19.3 32.9-30.3 11-11.8 20.4-24.8 28.6-38.7 3.6-6.2 7.1-12.4 10.2-18.8 6.5-13.6 11.9-27.6 15.6-42.2-.1-.1-.1-.2-.1-.3-.1-.1-.2-.1-.2-.2-.2 0-.4-.1-.6-.1h-27.1c11.2-21.5 22.3-42.7 33.7-64.2 1.4 2.5 2.6 4.6 3.7 6.7 5.7 11 11.5 22 17.2 33 3.7 7 7.4 14 11.1 21.1.5 1 .9 1.9 1.5 3.2-1 .1-1.7.2-2.5.2h-23.6c-2.4 0-2.4 0-3.1 2.4-2.7 10.2-6 20.3-10 30.1-5.3 12.9-11.6 25.2-18.8 37.1-5.4 8.9-11.5 17.4-17.9 25.6-8.3 10.5-17.5 20.2-27.4 29.2-11.4 10.4-23.7 19.7-36.7 27.8-13.2 8.3-27.1 15.2-41.7 20.9-11.7 4.6-23.7 8.3-35.9 11.1-6.2 1.4-12.4 2.4-18.7 3.3-6.9 1-13.7 1.6-20.6 2.4h-7.2c-1.6.3-3.3-.7-4.8.6h-10.8c-1.3-1.2-2.8-.3-4.2-.6h-3c-1 .1-2 .1-3 .1z"
			/>
			<path
				d="M90.7 202.4c4.3-1 8.6-2.1 12.8-3 14.1-3.3 28.3-6.5 42.5-9.7 1.3-.3 2.4-.6 3.1-2 4.3-8.5 11.6-13.2 20.6-15.3 3.2-.7 6.5-1.1 9.7-1.2 12.8-.1 25.5-.2 38.3 0 5.6.1 11.1-.6 16.5-1.9 33.6-7.8 67.2-15.6 100.9-23.3 13.3-3.1 26.5-6.1 39.8-9.2 2.3-.5 2.7-.5 3.2 1.9 2.2 10.2 4.7 20.3 7 30.5.4 1.7 1.1 2 2.6 2 9.8-.1 19.6 0 29.3 0 3.6 0 7.2.5 10.6 1.5 9 2.7 15 8.7 18.6 17.3 2 4.8 3 9.9 2.9 15.1v56.6c.2 1.4-.3 2.1-1.8 1.9-1.9-.1-3.8-.1-5.6-.2-.1-1.9-.4-3.7-.4-5.6v-53c0-4.7-1-9.3-3.1-13.6-3.9-8.1-10.7-11.7-19.4-12.3-1.1-.1-2.2 0-3.3 0H179.6c-4.9 0-9.5.9-13.9 3-7.7 3.7-11.6 10-12.3 18.4-.1 1.5-.1 3-.1 4.5v133.5c0 4.9.8 9.5 2.9 13.9 3.7 7.7 10 11.6 18.4 12.3 1.8.1 3.6.1 5.4.1h137.7c3.9 0 3.5-.5 3.5 3.5 0 4.3 0 4.3-4.2 4.3H179c-4.4 0-8.8-.3-13-1.7-9.5-3.2-15.4-10-18.6-19.2-1.4-4-2.1-8.1-2-12.3.1-3.2 0-6.4 0-9.6 0-2.2.1-2.3-2.3-2.2-7.9.2-14.7-2.5-20.2-8.1-4.5-4.5-7.5-10-9.1-16.2-1.3-5-2.4-10.1-3.6-15.1l-10.5-45.6c-2.8-13.3-5.9-26.6-9-40z"
			/>
			<path
				d="M83 165.2c.2-10.9 5.1-19 14.3-24.7 4.5-2.8 9.4-4.2 14.5-5.4 27.5-6.3 55-12.7 82.5-19.1 31.1-7.2 62.2-14.4 93.3-21.5 15.5-3.6 31-7.2 46.5-10.7 11.2-2.5 21.2-.3 29.1 8.6 3.6 4.1 6.1 8.9 7.6 14.1.7 2.5 1.2 5 1.8 7.5.4 1.8.2 2-1.6 2.4-10.3 2.4-20.5 4.8-30.8 7.1L294.6 134c-23.1 5.3-46.1 10.7-69.1 16-22.1 5.1-44.2 10.2-66.2 15.3-23.4 5.4-46.9 10.8-70.3 16.2-.5.1-1 .1-1.5.2-.8.2-1.3 0-1.5-.9-1.4-4.9-2.9-10.1-3-15.6z"
			/>
			<path
				d="M34.5 256.7C23.2 235.2 12.1 214 .8 192.5h26.5c1.4 0 2-.4 2.4-1.9 4.3-16 9.9-31.6 17.2-46.6 14-29.1 32.9-54.6 56.6-76.4 32.7-30.1 70.8-50.2 114-60.5 12.1-2.9 24.4-4.9 36.9-5.7C262.3.9 270.1.3 278 .5c56.7 1 107.8 18.4 152.6 53.2 42.3 32.9 71.3 75.1 87.2 126.3 1.6 5.2 3 10.5 4.3 15.8.3 1.4.3 3-.1 4.4-.6 1.9-2.9 3.2-4.7 3-2.1-.3-3.8-1.3-4.4-3.4-.8-2.9-1.5-5.8-2.3-8.6-9-33.7-24.5-64-46.6-91-24-29.4-53.3-51.9-87.7-67.7-18.1-8.3-36.9-14.2-56.5-17.8-10.6-1.9-21.3-3.1-32-3.7-10.3-.6-20.6-.5-30.8.3-30.2 2.3-59 9.9-86.3 23C142 47.9 117 66.4 95.6 89.8c-26.9 29.5-45.5 63.6-55.9 102.5 3.1.5 6.2.2 9.2.2 3.2.1 6.4 0 9.6 0h9.7c-11.3 21.5-22.4 42.7-33.7 64.2z"
			/>
			<path
				d="M233.7 333.5h-47.3c-1 0-2 0-3-.1-2.3-.3-4-2.2-4.1-4.4-.1-2.1 1.4-4.1 3.6-4.6 1.2-.3 2.4-.4 3.6-.4h94.6c1.2 0 2.4.1 3.5.4 2.1.5 3.5 2.6 3.3 4.7-.2 2.2-1.7 3.9-3.9 4.3-1.1.1-2.2.1-3.3.1h-47z"
			/>
			<path
				d="M208.9 287.7h21.8c1 0 2 0 3 .1 2.2.3 3.7 2.1 3.9 4.3.1 2.2-1.2 4.1-3.4 4.7-1 .3-2.2.4-3.2.4h-44.2c-1.2 0-2.4-.1-3.5-.4-1.9-.5-3.3-2.6-3.2-4.5.1-2.1 1.7-4 3.8-4.4 1-.2 2-.1 3-.1 7.3-.1 14.7-.1 22-.1z"
			/>
			<path
				d="M283.1 287.7h21.8c.9 0 1.8 0 2.7.1 2.2.3 3.8 2 4 4.2.2 2.3-1.2 4.4-3.5 4.9-1.2.3-2.4.4-3.5.4h-42.7c-1.2 0-2.4-.1-3.5-.4-2.1-.5-3.6-2.6-3.5-4.6.1-2.1 1.7-4 3.8-4.4 1-.2 2-.1 3-.1 7.1-.1 14.2-.1 21.4-.1z"
			/>
			<path
				d="M238.7 217.5h-14.4c-1.4 0-2-.3-1.9-1.8.1-3.2 0-6.4 0-9.6 0-2.1.2-2.3 2.2-2.3 6.5 0 13-.1 19.4 0 3.7.1 7.2 1.1 9.3 4.8 1.5 2.6 1.3 5.3 1.2 8.1 0 .3-.6.7-1 .8-.8.1-1.6 0-2.4 0h-12.4z"
			/>
			<path
				d="M238.8 247.1h14c1.4 0 2 .4 1.9 1.8-.1 2.4.1 4.7-1.2 6.9-2.1 3.8-5.6 4.9-9.5 5-6.5.2-12.9.1-19.4 0-2 0-2.2-.2-2.2-2.3V249c0-1.9.1-1.9 2.1-1.9h14.3z"
			/>
			<path
				d="M194.5 217.2h-13.7c-2 0-2.1-.1-2-1.9.2-5.6 1.8-9.1 6.3-10.8 1.5-.6 3.3-.9 4.9-.9 6.4-.1 12.7-.1 19.1 0 1.9 0 1.9.1 1.9 2 0 3.3-.1 6.6 0 9.9 0 1.5-.5 1.8-1.9 1.8-4.8-.1-9.7-.1-14.6-.1z"
			/>
			<path
				d="M194.8 247.1h14.4c1.4 0 1.9.4 1.9 1.9-.1 3.4-.1 6.8 0 10.2 0 1.2-.3 1.8-1.7 1.8-6.6-.1-13.2.1-19.7-.1-4.4-.1-8.3-1.6-9.9-6.2-.6-1.8-.7-3.9-1-5.8-.2-1.3.5-1.7 1.7-1.7 4.7-.1 9.5-.1 14.3-.1z"
			/>
			<path
				d="M190.9 225.6H201c1.2 0 1.6.4 1.5 1.5-.1 3.4 0 6.8 0 10.1 0 1-.3 1.4-1.4 1.4h-20.9c-1.1 0-1.6-.5-1.6-1.6v-10.1c0-1.1.6-1.3 1.5-1.3h10.8z"
			/>
			<path
				d="M243 225.6h10.2c1.2 0 1.6.4 1.6 1.5-.1 3.4 0 6.8 0 10.2 0 1-.4 1.4-1.4 1.4h-20.6c-1 0-1.4-.4-1.4-1.4v-10.2c0-1.2.5-1.5 1.6-1.5h10z"
			/>
			<path
				d="M449.4 311.6c0-3.6-3.2-8.4-7.7-8.8-2.2-.2-4.3-.4-6.5-.5-1.9-.1-1.9-.1-2-2.1 0-5.6-.1-11.2 0-16.8.1-3.7-.4-7.3-1.3-10.9-2-8.2-5.6-15.5-11.5-21.5-8-8.3-17.7-12.8-29.5-12.4-5.8.2-11.2 1.6-16.2 4.3-10.1 5.4-16.7 13.8-20.7 24.5-2.1 5.6-3.1 11.4-3 17.5v13.8c0 4.3.6 3.6-3.6 3.7-.9 0-1.8-.1-2.7 0-6.5.5-9.9 4.3-9.9 10.3.1 25.6.1 51.3 0 76.9 0 5.8 4.4 10.5 10.5 10.4 15.6-.2 31.1-.1 46.7-.1h47.6c5.6 0 9.7-4 9.7-9.5 0-26.3 0-52.6.1-78.8zm-51.9 42.7c1.2 3.1 2.2 6.3 3.3 9.5.8 2.4 1.8 4.8 1.1 7.4-.6 2-1.2 2.7-3.2 2.8-2.2.1-4.4 0-6.6 0h-6c-2.1-.1-3.4-1.3-3.5-3.3 0-1.5.1-3 .5-4.4 1.1-3.7 2.3-7.3 3.7-10.9.5-1.2.1-2.6-.9-3.4-7-5.6-3.7-17.3 6.4-17.7 2.3-.1 4.6.8 6.3 2.4 3 2.7 4.4 6.1 3.2 10.2-.7 2.3-1.6 4.3-3.7 5.6-.5.4-.8 1.1-.6 1.8zm17.4-52h-46.2c-2.1 0-2.1 0-2.1-2.1 0-5.7-.2-11.4.1-17.1.4-9.1 3.6-17.1 10.7-23 8-6.7 17-7.7 26.1-2.1 7.1 4.3 10.7 10.9 12.7 18.7.5 1.8.7 3.7.7 5.6.1 6 .1 12 .1 18 0 2 0 2-2.1 2z"
			/>
		</svg>
	</ng-container>

	<mat-progress-bar mode="indeterminate" />
</div>
