<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<bp-psps-device-fingerprint-collector
	*ngIf="_isPspsFingerprintsCollectionRequired"
	[pspsFingerprintCollectionHtmls]="_paymentMethod.ddc_htmls!"
	(pspsFingerprints)="_pspsFingerprints = $event"
	(allFingerprintsCollected)="_allFingerprintsCollected$.next(true)"
/>

<div cdkTrapFocus cdkTrapFocusAutoCapture class="card-container">
	<bp-back-btn [disabled]="_isSubmitting$ | async" />

	<section *ngIf="_session" cdkFocusInitial class="card payment-card" [class.submitting]="_isSubmitting$ | async">
		<bp-processing-stub
			[show]="!_embeddedData.disableProcessingMessages && (_isSubmitting$ | async)"
			[newTab]="_appService.isEmbedded && _paymentMethod.open_in_new_window"
		/>

		<ng-container *ngIf="!_shouldSubmitOnInit">
			<img *ngIf="_apmResponseImg; else formTpl" alt="" [src]="_apmResponseImg" />

			<ng-template #formTpl>
				<form [formGroup]="_form" (ngSubmit)="submit()">
					<!-- we gotta wait till the form group directive form property got set that underlying
form controls got property updateOn setting -->
					<ng-container *bpDelayedRender>
						<div *ngIf="_session.isPayWithCheckout" class="page-amount" @fadeIn>
							<h1 class="amount">
								{{ _getAmount() | currency: _currency.code }}
							</h1>

							<bp-secure-payment-static-text />
						</div>

						<span *ngIf="_session.isRegularLikeCheckout" class="title">{{
							'page.apm.title' | translate
						}}</span>

						<bp-amount
							*ngIf="_appService.isBrightTheme && !_session.isPayWithCheckout"
							[config]="_paymentMethod"
							[control]="_controls.amount"
							[fiatCurrencies]="_fiatCurrencies"
							[amountLock]="_session.amountLock"
							[(currency)]="_currency"
						/>

						<div class="row">
							<div class="left">
								<div
									*ngIf="_session.isPayWithCheckout"
									class="mat-body-1 mb-n2 flex-justify-content-between"
								>
									<div>{{ 'Payment information' | translate }}</div>
									<bp-payment-instance-out-of-total-text class="capitalize-first-letter" />
								</div>

								<div *ngIf="_hasPspPaymentOptionTypesDropdown" class="row">
									<mat-form-field>
										<mat-label>{{ 'payment option type' | translate }}</mat-label>

										<mat-select formControlName="bankPaymentType" [required]="true">
											<mat-select-trigger>
												<ng-container
													*ngTemplateOutlet="
														bankPaymentTypeTpl;
														context: { '\$implicit': _controls.bankPaymentType!.value }
													"
												/>
											</mat-select-trigger>

											<mat-option
												*ngFor="let bankPaymentType of _paymentMethod.banks_payment_types"
												[value]="bankPaymentType"
											>
												<ng-container
													*ngTemplateOutlet="
														bankPaymentTypeTpl;
														context: {
															'\$implicit': bankPaymentType,
														}
													"
												/>
											</mat-option>

											<ng-template #bankPaymentTypeTpl let-bankPaymentType>
												{{ bankPaymentType | titleCase }}
											</ng-template>
										</mat-select>

										<mat-error bpFieldError="bankPaymentType" />
									</mat-form-field>
								</div>

								<div
									*ngIf="
										_hasPspPaymentOptionTypesDropdown
											? _controls!.bankPaymentType!.value
											: _hasPspPaymentOptionsDropdown
									"
									class="row"
									@slide
									[@.disabled]="!_hasPspPaymentOptionTypesDropdown"
								>
									<mat-form-field>
										<mat-label>{{ 'payment option' | translate }}</mat-label>

										<mat-select
											formControlName="bank"
											[required]="true"
											(valueChange)="_currency = $event.currency"
										>
											<mat-select-trigger>
												<ng-container
													*ngTemplateOutlet="
														bankOptionTpl;
														context: { '\$implicit': _controls.bank!.value }
													"
												/>
											</mat-select-trigger>

											<mat-option
												*ngFor="
													let bank of _hasPspPaymentOptionTypesDropdown
														? _paymentMethod.banks_by_payment_type!.get(
																_controls.bankPaymentType!.value
															)
														: _paymentMethod.banks
												"
												[value]="bank"
											>
												<ng-container
													*ngTemplateOutlet="bankOptionTpl; context: { '\$implicit': bank }"
												/>
											</mat-option>

											<ng-template #bankOptionTpl let-bank>
												<div *ngIf="bank && _castToBank(bank)" class="flex-align-items-center">
													{{ bank.name }}
													<bp-img
														class="bank-img ml-auto mr-2"
														hiddenOnFailure
														[urls]="bank.logo.urls"
													/>
												</div>
											</ng-template>
										</mat-select>

										<mat-error bpFieldError="bank" />
									</mat-form-field>
								</div>

								<mat-form-field *ngIf="_shouldShowDefaultField('address')">
									<mat-label>{{ 'address' | translate }}</mat-label>

									<input
										type="text"
										formControlName="address"
										matInput
										autocomplete="street-address"
										[required]="true"
										[maxlength]="_textFieldMaxLength"
									/>

									<mat-error bpFieldError="address" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('addressStreet')">
									<mat-label>{{ 'address street' | translate }}</mat-label>

									<input
										type="text"
										formControlName="addressStreet"
										matInput
										[required]="_isAdditionalRequired('addressNumber')"
										[maxlength]="_textFieldMaxLength"
									/>

									<mat-error bpFieldError="addressStreet" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('addressNumber')">
									<mat-label>{{ 'address number' | translate }}</mat-label>

									<input
										type="text"
										formControlName="addressNumber"
										matInput
										[required]="_isAdditionalRequired('addressNumber')"
										[maxlength]="_textFieldMaxLength"
									/>

									<mat-error bpFieldError="addressNumber" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('addressDistrict')">
									<mat-label>{{ 'address district' | translate }}</mat-label>

									<input
										type="text"
										formControlName="addressDistrict"
										matInput
										[required]="true"
										[maxlength]="_textFieldMaxLength"
									/>

									<mat-error bpFieldError="addressDistrict" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('addressComplement')">
									<mat-label>{{ 'address complement' | translate }}</mat-label>

									<input
										type="text"
										formControlName="addressComplement"
										matInput
										[required]="true"
										[maxlength]="_textFieldMaxLength"
									/>

									<mat-error bpFieldError="addressComplement" />
								</mat-form-field>

								<div
									*ngIf="_shouldShowDefaultField('city') || _shouldShowDefaultField('zipCode')"
									class="row"
								>
									<mat-form-field *ngIf="_shouldShowDefaultField('city')">
										<mat-label>{{ 'city' | translate }}</mat-label>

										<input
											type="text"
											formControlName="city"
											matInput
											autocomplete="locality"
											[required]="true"
											[maxlength]="_textFieldMaxLength"
										/>

										<mat-error bpFieldError="city" />
									</mat-form-field>

									<mat-form-field *ngIf="_shouldShowDefaultField('zipCode')">
										<mat-label>{{ 'zip-code' | translate }}</mat-label>

										<input
											type="text"
											formControlName="zipCode"
											matInput
											minlength="3"
											autocomplete="postal-code"
											[required]="true"
											[maxlength]="_textFieldMaxLength"
										/>

										<mat-error bpFieldError="zipCode" />
									</mat-form-field>
								</div>

								<div
									*ngIf="_shouldShowDefaultField('stateCode') || _shouldShowDefaultField('phone')"
									class="row"
								>
									<mat-form-field *ngIf="_shouldShowDefaultField('stateCode')">
										<mat-label
											>{{ (_session.country.code === 'CA' ? 'province' : 'state') | translate }}
										</mat-label>

										<input
											#stateInput
											type="text"
											formControlName="state"
											matInput
											[required]="true"
											[matAutocomplete]="stateAutocomplete"
										/>

										<mat-autocomplete #stateAutocomplete="matAutocomplete">
											<mat-option
												*ngFor="
													let state of _session.country.states!
														| bpFilterStates: stateInput.value
												"
												[value]="state.name"
											>
												{{ state.name }}
											</mat-option>
										</mat-autocomplete>

										<mat-error bpFieldError="state" />
									</mat-form-field>

									<bp-phone-input
										*ngIf="_shouldShowDefaultField('phone')"
										label="phone"
										formControlName="phone"
										name="phone"
										nativeAutocomplete="tel"
										floatLabel="always"
										[required]="true"
										[initialDialCodeCountry]="_session.country!"
										(dialCodeChange)="_controls.countryDialCode!.setValue($event)"
									/>
								</div>

								<mat-form-field *ngIf="_shouldShowDefaultField('email')">
									<mat-label>{{ 'email' | translate }}</mat-label>

									<input
										formControlName="email"
										matInput
										type="email"
										autocomplete="email"
										inputmode="email"
										[required]="true"
									/>

									<mat-error bpFieldError="email" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('bankAccountName')">
									<mat-label>Bank Account Name</mat-label>

									<input
										formControlName="bankAccountName"
										matInput
										type="text"
										autocomplete="bankAccountName"
										[required]="_isAdditionalRequired('bankAccountName')"
									/>

									<mat-error bpFieldError="bankAccountName" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('bankAgency')">
									<mat-label>Bank Agency</mat-label>

									<input
										formControlName="bankAgency"
										matInput
										type="text"
										autocomplete="bankAgency"
										[required]="_isAdditionalRequired('bankAgency')"
									/>

									<mat-error bpFieldError="bankAgency" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('iban')">
									<mat-label>International Bank Account Number (IBAN)</mat-label>

									<input
										formControlName="iban"
										matInput
										type="text"
										autocomplete="iban"
										[required]="_isAdditionalRequired('iban')"
									/>

									<mat-error bpFieldError="iban" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('sortCode')">
									<mat-label>SORT Code</mat-label>

									<input
										formControlName="sortCode"
										matInput
										type="text"
										autocomplete="sortCode"
										[required]="_isAdditionalRequired('sortCode')"
									/>

									<mat-error bpFieldError="sortCode" />
								</mat-form-field>

								<mat-form-field *ngIf="_isAdditional('accountNumber')">
									<mat-label>Account Number</mat-label>

									<input
										formControlName="accountNumber"
										matInput
										type="text"
										autocomplete="accountNumber"
										[required]="true"
									/>

									<mat-error bpFieldError="accountNumber" />
								</mat-form-field>

								<mat-form-field
									*ngIf="_isAdditional('personalId') && !_paymentMethod.personal_id_types"
								>
									<mat-label
										>{{
											_isGateToPayProvider
												? ('page.deposit.personal-id.gatetopay' | translate)
												: _session.country.code === 'CO' &&
													  _paymentMethod.provider === 'payretailers'
													? 'Cédula de Ciudadanía'
													: _paymentMethod.provider === 'beeteller'
														? 'CPF'
														: (_session.country | bpCountryPersonalIdName)
										}}
									</mat-label>

									<input
										formControlName="personalId"
										matInput
										type="text"
										autocomplete="personalId"
										[required]="_isAdditionalRequired('personalId')"
									/>

									<mat-hint *ngIf="_isGateToPayProvider">
										{{ 'page.deposit.personal-id.gatetopay.hint' | translate }}
									</mat-hint>

									<mat-error bpFieldError="personalId" />
								</mat-form-field>

								<div
									*ngIf="_isAdditional('personalId') && !!_paymentMethod.personal_id_types"
									class="row"
								>
									<mat-form-field class="flex-third">
										<mat-label>{{ 'ID Type' | translate }}</mat-label>

										<mat-select formControlName="personalIdType" [required]="true">
											<mat-option
												*ngFor="let personalIdType of _paymentMethod.personal_id_types!"
												[value]="personalIdType"
											>
												{{ personalIdType.title }}
											</mat-option>
										</mat-select>

										<mat-error bpFieldError="personalIdType" />
									</mat-form-field>

									<mat-form-field>
										<mat-label> {{ _controls.personalIdType!.value.title }} </mat-label>

										<input
											formControlName="personalId"
											matInput
											type="text"
											autocomplete="personalId"
											[required]="_isAdditionalRequired('personalId')"
										/>

										<mat-error bpFieldError="personalId" />
									</mat-form-field>
								</div>

								<mat-form-field *ngFor="let fieldMetadata of _paymentMethod.fields">
									<mat-label>{{ fieldMetadata.title | translate }}</mat-label>

									<mat-select
										*ngIf="fieldMetadata.controlType.isSelect"
										[formControlName]="fieldMetadata.name"
										[required]="!!fieldMetadata.required"
									>
										<mat-option *ngFor="let item of fieldMetadata.items!" [value]="item.id">
											{{ item.title | translate }}
										</mat-option>
									</mat-select>

									<input
										*ngIf="fieldMetadata.controlType.isInput"
										matInput
										type="text"
										[autocomplete]="fieldMetadata.name"
										[formControlName]="fieldMetadata.name"
										[required]="!!fieldMetadata.required"
									/>

									<mat-error [bpFieldError]="fieldMetadata.name" />
								</mat-form-field>

								<bp-payment-card-expire-input
									*ngIf="_isAdditional('expiryDate')"
									name="expiryDate"
									formControlName="expiryDate"
									[required]="_isAdditionalRequired('expiryDate')"
								/>

								<div *ngIf="_isAdditional('birthDate') || _isAdditional('gender')" class="row">
									<mat-form-field *ngIf="_isAdditional('birthDate')">
										<mat-label>{{ 'birthday' | translate }}</mat-label>

										<input
											type="text"
											formControlName="birthDate"
											matInput
											autocomplete="bday"
											x-autocompletetype="birthday"
											[matDatepicker]="dp"
											[max]="_outOfAgeDate"
											[required]="_isAdditionalRequired('birthDate')"
										/>
										<mat-datepicker-toggle matSuffix [for]="dp" />

										<mat-datepicker #dp startView="multi-year" [startAt]="_startDate" />

										<mat-error bpFieldError="birthDate" />
									</mat-form-field>

									<mat-form-field *ngIf="_isAdditional('gender')">
										<mat-label>{{ 'gender.title' | translate }}</mat-label>

										<mat-select
											formControlName="gender"
											[required]="_isAdditionalRequired('gender')"
										>
											<mat-option *ngFor="let v of _genders$ | async" [value]="v.value">
												{{ v.display }}
											</mat-option>
										</mat-select>

										<mat-error bpFieldError="gender" />
									</mat-form-field>
								</div>

								<div
									*ngIf="_shouldShowDefaultField('firstName') || _shouldShowDefaultField('lastName')"
									class="row"
								>
									<mat-form-field [class.readonly]="_session.firstName.valid">
										<mat-label>{{ 'first-name' | translate }}</mat-label>

										<input
											type="text"
											formControlName="firstName"
											matInput
											autocomplete="first-name"
											[readonly]="_session.firstName.valid"
											[maxlength]="_textFieldMaxLength"
											[required]="true"
										/>

										<mat-error bpFieldError="firstName" />
									</mat-form-field>

									<mat-form-field [class.readonly]="_session.lastName.valid">
										<mat-label>{{ 'last-name' | translate }}</mat-label>

										<input
											type="text"
											formControlName="lastName"
											matInput
											autocomplete="last-name"
											[readonly]="_session.lastName.valid"
											[maxlength]="_textFieldMaxLength"
											[required]="true"
										/>

										<mat-error bpFieldError="lastName" />
									</mat-form-field>
								</div>

								<mat-form-field *ngIf="_isAdditional('otpCode')">
									<mat-label>OTP Code</mat-label>

									<input
										formControlName="otpCode"
										matInput
										type="text"
										autocomplete="otpCode"
										minlength="6"
										maxlength="6"
										[required]="true"
									/>

									<mat-error bpFieldError="otpCode" />
								</mat-form-field>

								<div *ngIf="_globalError" class="global-error mat-error row" @slide>
									{{ _globalError | translate }}
								</div>

								<ng-container *ngIf="_appService.isNotBrightTheme">
									<bp-page-note
										*ngIf="_paymentMethod.provider === 'gigadat'"
										[note]="'gigadat-trademark' | translate"
									/>

									<bp-page-note forPage="apm" [paymentMethod]="_paymentMethod" />

									<bp-page-note *ngIf="_pspBadge$ | async as pspBadge" [note]="pspBadge" />
								</ng-container>
							</div>

							<ng-container *ngIf="_appService.isBrightTheme">
								<bp-page-note
									*ngIf="_paymentMethod.provider === 'gigadat'"
									[note]="'gigadat-trademark' | translate"
								/>

								<bp-page-note forPage="apm" [paymentMethod]="_paymentMethod" />

								<bp-page-note *ngIf="_pspBadge$ | async as pspBadge" [note]="pspBadge" />
							</ng-container>

							<div class="right">
								<bp-page-note
									*ngIf="_appService.isNotBrightTheme && (_pspBadge$ | async) as pspBadge"
									[note]="pspBadge"
								/>

								<div class="credit-detail">
									<bp-amount
										*ngIf="_appService.isNotBrightTheme"
										[config]="_paymentMethod"
										[control]="_controls.amount"
										[fiatCurrencies]="_fiatCurrencies"
										[amountLock]="_session.amountLock"
										[(currency)]="_currency"
										(inputFocus)="_markAsDirtyAllControls()"
									/>

									<button
										class="btn btn-submit"
										type="submit"
										matRipple
										[matRippleDisabled]="_cannotSubmit"
										[class.card-float-button-position]="_appService.isNotBrightTheme"
										[disabled]="_cannotSubmit"
									>
										{{
											_session.depositButtonText || (_session.payMode ? 'btn.pay' : 'btn.deposit')
												| translate
										}}

										<ng-container *ngIf="_appService.isBrightTheme">
											{{ _getAmount() | currency: _currency.code }}
										</ng-container>

										<bp-dots-wave
											*ngIf="_appService.isNotBrightTheme"
											[show]="_isSubmitting$ | async"
										/>
									</button>
								</div>
							</div>
						</div>

						<bp-payment-summary *ngIf="_session.isPayWithCheckout" />
					</ng-container>
				</form>
			</ng-template>
		</ng-container>
	</section>
</div>
