import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { IValidationErrors } from '@bp/shared/features/validation/models';

import { ErrorsTextsProviderBaseService } from '@bp/frontend/features/validation';

@Injectable({ providedIn: 'root' })
export class TranslatedErrorsTextsProviderService extends ErrorsTextsProviderBaseService {

	private readonly __translateService = inject(TranslateService);

	renderFirstErrorTextAndObserveChanges(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): Observable<string> {
		return this.__translateService.onLangChange.pipe(
			startWith(null),
			map(() => this.renderFirstErrorText(validationErrors, controlName)),
		);
	}

	renderFirstErrorText(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): string {
		return this._renderValidationErrorsAsTexts(validationErrors, controlName)[0];
	}

	protected _fetchErrorTextWithMasks(validatorName: string, controlName?: number | string | null): string {
		return controlName
			? this.__tryGetTranslatedControlSpecificErrorMessage(validatorName, controlName)
				?? this.__getTranslatedGeneralErrorMessage(validatorName)
			: this.__getTranslatedGeneralErrorMessage(validatorName);

	}

	private __tryGetTranslatedControlSpecificErrorMessage(validatorName: string, controlName: number | string): string | null {
		const controlSpecificErrorPath = `error.${ validatorName }.${ controlName }`;
		let controlSpecificError: string | null = <string> this.__translateService.instant(controlSpecificErrorPath);

		controlSpecificError = controlSpecificError === controlSpecificErrorPath
			? null
			: controlSpecificError;

		return controlSpecificError;
	}

	private __getTranslatedGeneralErrorMessage(validatorName: string): string {
		const generalErrorPath = `error.${ validatorName }`;
		let generalError = <string> this.__translateService.instant(generalErrorPath);

		if (generalError === generalErrorPath)
			generalError = validatorName;

		return generalError;
	}
}
