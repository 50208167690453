import { DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { ThreeDsProcessor } from './three-ds-processor';

export class ThreeDsProcessorsCreateResponse extends MetadataEntity {

	@Mapper(ThreeDsProcessor)
	processors!: ThreeDsProcessor[];

	constructor(dto: DTO<ThreeDsProcessorsCreateResponse>) {
		super(dto);
	}

}
