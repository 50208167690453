import { range } from 'lodash-es';

import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { TextMaskConfig } from '@bp/shared/features/text-mask';
import { CARD_HOLDER_CHARACTER_ALLOWED } from '@bp/shared/features/validation/models';

import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { InputComponent, SharedComponentsControlsModule } from '@bp/frontend/components/controls';

@Component({
	selector: 'bp-payment-card-holder-input',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		SharedComponentsControlsModule,
	],
	templateUrl: './payment-card-holder-input.component.html',
	styleUrls: [ './payment-card-holder-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardHolderInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardHolderInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardHolderInputComponent extends FormFieldControlComponent<string | null> {

	@Input() allowAnyCharInValue?: boolean;

	@Input() maxValueLength = 255;

	@ViewChild(InputComponent, { static: true })
	private readonly __input!: InputComponent;

	protected _mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		guide: false,
		mask: (rawValue: string) => range(Math.min(rawValue.length, this.maxValueLength))
			.map(() => this.allowAnyCharInValue
				? /./u
				: CARD_HOLDER_CHARACTER_ALLOWED),
	});

	focus(): void {
		this.__input.focus();
	}

}
