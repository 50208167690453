import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CheckoutType } from '@bp/frontend/domains/checkout/core';

import { CheckoutSession } from '@bp/checkout-frontend/models';
import { AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-paywith-payment-options-page',
	templateUrl: './paywith-payment-options-page.component.html',
	styleUrls: [ './paywith-payment-options-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywithPaymentOptionsPageComponent {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	protected readonly _appService = inject(AppService);

	protected readonly _router = inject(Router);

	protected readonly _activatedRoute = inject(ActivatedRoute);

	protected readonly _CheckoutType = CheckoutType;

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

}
