<input
	type="text"
	tabindex="-1"
	[matAutocomplete]="savedPaymentCardTokensAutocomplete"
	[matAutocompleteConnectedTo]="connectedTo"
/>

<mat-autocomplete
	#savedPaymentCardTokensAutocomplete="matAutocomplete"
	class="saved-payment-cards-autocomplete-panel"
	(optionSelected)="paymentCardTokenSelected.emit($event.option.value)"
>
	<mat-option *ngFor="let paymentCardToken of paymentCardTokens" [value]="paymentCardToken">
		<div dir="ltr">
			<span class="card-number">
				{{ paymentCardToken.number }}
			</span>

			<span class="card-expiration-date">
				{{ paymentCardToken.expirationDate }}
			</span>
		</div>

		<div>
			<bp-payment-card-brand-icon *ngIf="paymentCardToken.brand as brand" [src]="brand" />
		</div>
	</mat-option>
</mat-autocomplete>
