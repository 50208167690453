<div
	class="logo"
	[ngClass]="[paymentOption.type.name, paymentOption.provider || '']"
	[class.country-flag]="!!paymentOption.countryFlag"
>
	<ng-container *ngIf="paymentOption.countryFlag; else defaultTpl">
		<div class="flag-container">
			<img class="flag" src="flags/4x3/{{ paymentOption.countryFlag | lowercase }}.svg" />
		</div>
		<div class="logo-name">Local Solution</div>
	</ng-container>

	<ng-template #defaultTpl>
		<div class="img-container" [class.animated-logos-stack]="paymentOption.logos.length > 1">
			<bp-img
				*ngFor="let logo of paymentOption.logos; let i = index; let length = count"
				class="img-white-background-eraser"
				withPlaceholder
				[style.animation-duration.s]="length * _durationOfSlide"
				[style.animation-delay.s]="i * _durationOfSlide"
				[alt]="logo.alt"
				[urls]="logo.urls"
			>
				<span bpImgError [ngClass]="{ 'mb-0 h2': !_session!.theme.isBright }" [bpFitText]="{ maxSize: 27 }">
					{{ logo.displayName ? '' : logo.alt }}
				</span>
			</bp-img>
		</div>

		<div *ngIf="paymentOption.logos[0].displayName as displayName" class="logo-name">{{ displayName }}</div>
	</ng-template>
</div>
