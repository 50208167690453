
import { Component, ChangeDetectionStrategy } from '@angular/core';

import { PspResultPageBaseComponent } from '@bp/checkout-frontend/components';

@Component({
	selector: 'bp-card-proceed-on-open-tab-page',
	templateUrl: './card-proceed-on-open-tab-page.component.html',
	styleUrls: [ './card-proceed-on-open-tab-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProceedOnOpenTabPageComponent extends PspResultPageBaseComponent<null> {

	protected _redirectUrl!: string;

	constructor() {
		super();

		this._notifyHostAboutPaymentStatus('pending');
	}

	onManualProceed(): void {
		this._appService.openTab = window.open(this._appService.paymentProcessingPageUrl!, '_blank');
	}

}
