import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';
import { PATH_SEGMENTS } from '@bp/three-ds/shared/domains/three-ds-processing';

import { ThreeDsProcessingPageComponent } from './three-ds-processing-page';
import { ThreeDsProcessingResultCallbackPageComponent } from './three-ds-processing-result-callback-page';
import { ThreeDsProcessingSessionResolver } from './services';

const routes: Routes = [
	{
		matcher: matchRoutePathWithVersionPathSegment(PATH_SEGMENTS.threeDsProcessing),
		children: [
			{
				path: PATH_SEGMENTS.resultCallback,
				component: ThreeDsProcessingResultCallbackPageComponent,
			},
			{
				path: ':bpTransactionId',
				component: ThreeDsProcessingPageComponent,
				resolve: {
					session: ThreeDsProcessingSessionResolver,
				},
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class ThreeDsProcessingRoutingModule { }
