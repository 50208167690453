import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { PaymentCardCvvInputComponent } from '@bp/frontend/components/payment-card/cvv-input';
import { PaymentCardExpireInputComponent } from '@bp/frontend/components/payment-card/expire-input';
import { PaymentCardHolderInputComponent } from '@bp/frontend/components/payment-card/holder-input';
import { PaymentCardNumberInputComponent } from '@bp/frontend/components/payment-card/number-input';
import { SavedPaymentCardTokensSelectComponent } from '@bp/frontend/components/payment-card/saved-payment-card-tokens-select';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { PaymentsRoutingModule } from './payments-routing.module';
import { AmountComponent, DictionaryTableComponent } from './components';
import {
	WireTransferPageComponent, CryptoPageComponent, CardPageComponent, GeneralPaymentOptionsPageComponent,
	ThreeDSecurePageComponent, ApmPageComponent, CryptoQrPageComponent, BanksPageComponent, IFramePageComponent, CryptoWalletsPageComponent, PaywithPaymentOptionsPageComponent,
	BanksPaymentTypesPageComponent, PaymentOptionComponent, ApmWireTransferDetailsPageComponent
} from './pages';

const EXPOSED = [
	GeneralPaymentOptionsPageComponent,
	PaywithPaymentOptionsPageComponent,
	PaymentOptionComponent,
	WireTransferPageComponent,
	CryptoPageComponent,
	CryptoQrPageComponent,
	CardPageComponent,
	ApmPageComponent,
	ThreeDSecurePageComponent,
	BanksPageComponent,
	BanksPaymentTypesPageComponent,
	IFramePageComponent,
	CryptoWalletsPageComponent,
	ApmWireTransferDetailsPageComponent,

	AmountComponent,
	DictionaryTableComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		SharedComponentsControlsModule,
		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
		PaymentsRoutingModule,

		PaymentCardCvvInputComponent,
		PaymentCardExpireInputComponent,
		PaymentCardHolderInputComponent,
		PaymentCardNumberInputComponent,
		SavedPaymentCardTokensSelectComponent,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class PaymentsModule { }
