import { SimpleCrypto, CipherText } from 'simple-crypto-js';

import { inject, Injectable } from '@angular/core';

import { PlainObject } from '@bp/shared/typings';

import { FingerprintService } from './fingerprint.service';

@Injectable({
	providedIn: 'root',
})
export class CypherService {

	private readonly __fingerprintService = inject(FingerprintService);

	private readonly __cipher = this.__buildCipherBasedOnFingerprintHash();

	private async __buildCipherBasedOnFingerprintHash(): Promise<SimpleCrypto> {
		return new SimpleCrypto(
			await this.__fingerprintService.getHash(),
		);
	}

	async decrypt<T = unknown>(cipheredText: CipherText): Promise<PlainObject<T>> {
		const cipher = await this.__cipher;

		return <PlainObject<T>> <unknown>cipher.decrypt(cipheredText, true);
	}

	async encrypt<T>(data: PlainObject<T>): Promise<CipherText> {
		const cipher = await this.__cipher;

		return cipher.encrypt(<any> data);
	}

}
