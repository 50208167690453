import { Moment } from 'moment';

import {
	booleanMapper, Copyable, Default, DTO, Entity, FieldViewType, Hint, Mapper, momentMapper,
	Required, Sortable, Table, View
} from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities/core';

export class User extends Entity {

	@Required()
	override name!: string; // Calculated field

	@Table()
	@Copyable()
	email!: string;

	@Hint('Also known as Merchant ID')
	@Copyable()
	organizationId!: string;

	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	@Sortable()
	isPermanentlyLocked!: boolean;

	@Mapper(booleanMapper)
	@View(FieldViewType.boolean)
	@Sortable()
	hasSecurityQuestions?: boolean;

	@Sortable({ isDefault: true })
	@Table()
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	override created!: Moment | null;

	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	@Table()
	@Sortable()
	lastLogin!: Moment | null;

	@Required()
	firstName!: string;

	@Default(null)
	lastName!: string | null;

	constructor(dto: Omit<DTO<User>, 'name'>) {
		super(dto);

		this.name = [ this.firstName, this.lastName ].filter(isPresent).join(' ');
	}

}
