<div>
	<bp-back-btn />

	<div class="row">
		<div
			*ngFor="let bankPaymentType of _paymentMethod.banks_payment_types"
			class="payment-option-box"
			matRipple
			(click)="_navigate(bankPaymentType)"
		>
			<bp-img
				class="img-white-background-eraser"
				url="assets/images/payment-methods/{{ _paymentMethod.provider }}_{{ bankPaymentType | bpSnakeCase }}"
			>
				<bp-img-error>
					{{ bankPaymentType | titleCase }}
				</bp-img-error>
			</bp-img>
		</div>
	</div>
</div>
