<section class="card payment-card">
	<h1 *ngIf="_isSessionExpired; else qrCodeTpl">SESSION EXPIRED</h1>

	<ng-template #qrCodeTpl>
		<span class="title">
			{{ 'page.' + (_session.payMode ? 'pay' : 'deposit') + '-crypto.title' | translate }}

			<a
				*ngIf="_paymentMethod && _buyLinks[_paymentMethod.provider] as link"
				class="purple"
				target="_blank"
				rel="noreferrer"
				[href]="link"
			>
				Buy {{ _cryptoOrderDetails.currency!.code }}!
			</a>
		</span>

		<div class="amount-and-qr-code">
			<div class="amount">
				<bp-img
					class="crypto-logo"
					[urls]="[
						_cryptoLogoAssets + _cryptoOrderDetails.currency!.code + '.svg',
						_cryptoLogoAssets + _cryptoOrderDetails.currency!.code + '.png'
					]"
				/>

				<h2>
					<div [bpFitText]="{ maxSize: _amountMaxFontSize }">{{ _cryptoOrderDetails.amount }}</div>

					<bp-copy-to-clipboard-btn copyText="Copy the amount" value="{{ _cryptoOrderDetails.amount }}" />
				</h2>
			</div>

			<canvas #qrCode></canvas>
		</div>

		<div class="address-section">
			<mat-hint *ngIf="_cryptoOrderDetails.expires_at" class="mat-caption">
				The address gets invalid
				<bp-countdown [upAt]="_cryptoOrderDetails.expires_at" (up)="_isSessionExpired = true" />
			</mat-hint>

			<hr />

			<div>
				<span class="address">
					{{ _cryptoOrderDetails.address }}
				</span>

				<bp-copy-to-clipboard-btn copyText="Copy the address" [value]="_cryptoOrderDetails.address!" />
			</div>
		</div>

		<div *ngIf="_cryptoOrderDetails.memo" class="address-section">
			<div>
				<span class="address"> Memo: {{ _cryptoOrderDetails.memo }} </span>

				<bp-copy-to-clipboard-btn copyText="Copy the memo" [value]="_cryptoOrderDetails.memo" />
			</div>
		</div>

		<bp-page-note forPage="cryptoQR" [class.mt-3]="_appService.isNotBrightTheme" [paymentMethod]="_paymentMethod" />
	</ng-template>
</section>
