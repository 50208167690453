<ng-container *ngIf="_appService.isBrightTheme">
	<h1 class="mb-0">Payment methods</h1>
	<p>Please select a payment method</p>
</ng-container>

<div class="row">
	<bp-payment-option
		*ngFor="let paymentMethod of _paymentMethods"
		[method]="paymentMethod"
		(click)="_navigateToPaymentOption(paymentMethod)"
	/>
</div>
