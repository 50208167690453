import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-global-spinner',
	templateUrl: './global-spinner.component.html',
	styleUrls: [ './global-spinner.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSpinnerComponent {

}
