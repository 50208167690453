<div class="session-amount page-amount mb-3" @fadeIn>
	<h1 class="amount">
		{{ session!.$amount | currency: session!.currency.code }}
	</h1>

	<bp-secure-payment-static-text />
</div>

<div class="description mat-body-1 mb-2" @fadeIn>
	{{ 'Each payment method can be selected more than once.' | translate }}
</div>

<ng-container *ngIf="_paymentOptionInstancesManager.instances!.length < session!.paywithMaxInstancesLimit!">
	<div class="payment-options" @fadeIn>
		<div
			*ngFor="let paymentOption of session!.paywithPaymentMethods"
			class="payment-option"
			(click)="_paymentOptionInstancesManager.addPaymentOptionInstance(paymentOption)"
		>
			<button class="payment-option-box reset-default-decoration" type="button" matRipple>
				<bp-payment-option-logo [paymentOption]="paymentOption" />
			</button>

			<button class="round-action-button add-button reset-default-decoration" type="button" matRipple>
				<mat-icon class="material-icons-round">add</mat-icon>
			</button>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="_paymentOptionInstancesManager.instances!.length">
	<div class="payment-instances-selected-caption mat-body-1 mt-4 mb-2" @fadeIn>
		{{ 'Payment summary' | translate }}
	</div>

	<div class="instances-wrapper" @fadeIn>
		<div class="instances">
			<div
				*ngFor="
					let instance of _paymentOptionInstancesManager.instances;
					trackBy: trackBy;
					let i = index;
					let isLast = last;
					let isFirst = first
				"
				class="instance status-{{ instance.status }} type-{{ instance.paymentOption.type }}"
				[class.active]="instance === _paymentOptionInstancesManager.activeInstance"
			>
				<div class="cell cell-bordered cell-bordered-start instance-logo-cell">
					<div class="instance-logo">
						<bp-payment-option-logo [paymentOption]="instance.paymentOption" />
					</div>
				</div>

				<div class="cell cell-bordered status-cell">
					<div class="status">
						<ng-container [ngSwitch]="true">
							<ng-container *ngSwitchCase="instance.isSuccess">
								{{ 'Completed' | translate }}
							</ng-container>
							<ng-container *ngSwitchCase="instance.isError">
								{{ 'Declined' | translate }}
							</ng-container>
						</ng-container>
					</div>
				</div>

				<div class="cell cell-bordered cell-bordered-end price-cell">
					<div class="price">
						<div class="w-100 fitty-wrapper">
							<div [bpFitText]="{ maxSize: 17 }">
								{{ instance.amount | currency: session!.currency.code : 'symbol' : '1.0-2' }}
							</div>
						</div>
					</div>
				</div>

				<div class="cell button-cell">
					<div class="button-wrapper">
						<button
							class="round-action-button"
							type="button"
							matRipple
							[matRippleDisabled]="instance.isSuccess"
							[disabled]="instance.isSuccess"
							(click)="_paymentOptionInstancesManager.removePaymentOptionInstance(instance.index)"
						>
							<mat-icon class="material-icons-round">delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<div class="next-button-wrapper mt-4">
	<button
		matRipple
		class="btn continue w-100"
		type="button"
		[disabled]="_paymentOptionInstancesManager.instancesAmountIsInvalid"
		(click)="_paymentOptionInstancesManager.continue()"
	>
		<ng-container [ngSwitch]="true">
			<ng-container *ngSwitchCase="_paymentOptionInstancesManager.hasNoInstancesSelected">
				{{ 'Select payment method' | translate }}
			</ng-container>
			<ng-container
				*ngSwitchCase="
					_paymentOptionInstancesManager.hasInstancesSelected && _paymentOptionInstancesManager.noAttemptsMade
				"
			>
				{{
					('Pay with' | translate) +
						' ' +
						_paymentOptionInstancesManager.instances!.length +
						' ' +
						('payment methods' | pluralizeBy: _paymentOptionInstancesManager.instances!.length | translate)
				}}
			</ng-container>
			<ng-container *ngSwitchDefault>
				{{ 'Continue payment' | translate }}
			</ng-container>
		</ng-container>
	</button>

	<ng-content />
</div>

<bp-payment-summary *ngIf="!_paymentOptionInstancesManager.noAttemptsMade" class="mt-4" />
