import { lastValueFrom, timer } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { uuid } from '@bp/shared/utilities/core';

import { bypassAuthorizationHeaderCheck } from '@bp/frontend/services/http';

export const CLOUDFLARE_ACCESS_CHECK_PATHNAME = 'cf-access-check';

@Injectable({
	providedIn: 'root',
})
export class CloudflareAccessService {

	constructor(private readonly _http: HttpClient) { }

	whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage(): void {
		timer(0, 1000 * 60 * 60 * 1)
			.subscribe(() => void this.checkAccessAndTryRedirectToCFLogin());
	}

	async checkAccessAndTryRedirectToCFLogin(): Promise<void> {
		try {
			const { url } = await this._tryGetCloudflareLoginUrl();

			if (url)
				location.href = url;
		} catch { }
	}

	private async _tryGetCloudflareLoginUrl(): Promise<{ url?: string }> {
		return lastValueFrom(
			this._http.get(
				`/${ CLOUDFLARE_ACCESS_CHECK_PATHNAME }`,
				{
					params: {
						'cache-bust': uuid(),
					},
					context: bypassAuthorizationHeaderCheck(),
				},
			),
		);
	}
}
