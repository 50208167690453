import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

import { CopyToClipboardBtnComponent, CopyToClipboardComponent } from './components';

const EXPOSED = [
	CopyToClipboardBtnComponent,
	CopyToClipboardComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,

		SharedFeaturesTooltipModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesCopyToClipboardModule { }
