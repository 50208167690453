@if (_environment.isNotProduction || _environment.isBackoffice || show) {
	@for (
		repositorySummaryPerDeployment of _environment.repositorySummariesPerDeployment$ | async | keyvalue;
		track repositorySummaryPerDeployment.key
	) {
		@if (repositorySummaryPerDeployment.value.pullRequestTitle) {
			<div
				*ngIf="repositorySummaryPerDeployment.value.pullRequestTitle"
				class="panel pull-request-summary-panel"
				[ngClass]="repositorySummaryPerDeployment.key.cssClass"
				[class.panel-open]="_isPullRequestSummaryOpen"
			>
				<div
					*ngIf="_isPullRequestSummaryOpen; else pullRequestSummaryPanelExpansionButtonTpl"
					class="pull-request-summary"
					@slideHorizontal
				>
					<button
						mat-icon-button
						class="bp-icon-button-md"
						type="button"
						bpTooltip="Close {{ repositorySummaryPerDeployment.key.displayName | lowercase }} pull request
			summary "
						(click)="_isPullRequestSummaryOpen = false"
					>
						<bp-svg-icon name="git-pull-request" />
						<span class="deployment-kind-mark">{{
							repositorySummaryPerDeployment.key.displayName | slice: 0 : 1
						}}</span>
					</button>

					<span>
						<a
							class="link"
							bpTargetBlank
							href="https://github.com/BRIDGERPAY/{{
								repositorySummaryPerDeployment.value.pullRequestRepository
							}}/pull/{{ repositorySummaryPerDeployment.value.pullRequestNumber }}"
						>
							#{{ repositorySummaryPerDeployment.value.pullRequestNumber }}
						</a>
						:

						<span
							[innerHtml]="
								repositorySummaryPerDeployment.value.pullRequestTitle
									| bpTurnTrackerIssueNumbersIntoLinks
							"
						></span>

						<span>
							| updated

							<span
								class="interactive link use-current-color"
								bpTooltip="{{
									repositorySummaryPerDeployment.value.pullRequestUpdatedAt
										| momentFormat: 'LLL'
										| async
								}}"
							>
								{{ repositorySummaryPerDeployment.value.pullRequestUpdatedAt | momentFromNow | async }}
							</span>
						</span>

						by {{ repositorySummaryPerDeployment.value.pullRequestUpdatedBy }}
					</span>

					<button
						mat-icon-button
						class="bp-icon-button-md"
						type="button"
						bpTooltip="Close {{
							repositorySummaryPerDeployment.key.displayName | lowercase
						}} pull request summary "
						(click)="_isPullRequestSummaryOpen = false"
					>
						<mat-icon class="material-icons-round">close_rounded</mat-icon>
					</button>
				</div>

				<ng-template #pullRequestSummaryPanelExpansionButtonTpl>
					<button
						mat-icon-button
						class="bp-icon-button-md"
						type="button"
						bpTooltip="Open {{
							repositorySummaryPerDeployment.key.displayName | lowercase
						}} pull request summary"
						(click)="_isPullRequestSummaryOpen = true"
					>
						<bp-svg-icon name="git-pull-request" />
						<span class="deployment-kind-mark">{{
							repositorySummaryPerDeployment.key.displayName | slice: 0 : 1
						}}</span>
					</button>
				</ng-template>
			</div>
		}
	}

	<div class="panel developer-overlay-panel" [class.panel-open]="_DeveloperOverlayPanelExpansionState.isActive">
		<div
			*ngIf="_DeveloperOverlayPanelExpansionState.isActive; else expandPanelButtonTpl"
			class="developer-overlay-panel-buttons"
			@slideHorizontal
		>
			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Close developer panel"
				(click)="_DeveloperOverlayPanelExpansionState.toggle()"
			>
				<mat-icon class="material-icons-round">developer_mode</mat-icon>
			</button>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Switch {{ _LocalBackendState.isActive ? 'off' : 'on' }} local backend"
				[class.active]="_LocalBackendState.isActive"
				(click)="_LocalBackendState.toggleAndReloadPage()"
			>
				<mat-icon class="material-icons-round">dns</mat-icon>
			</button>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Switch {{ _MockedBackendState.isActive ? 'off' : 'on' }} mocked backend"
				[class.active]="_MockedBackendState.isActive"
				(click)="_MockedBackendState.toggleAndReloadPage()"
			>
				<mat-icon class="material-icons-round">api</mat-icon>
			</button>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Switch {{ _LocalFirebaseState.isActive ? 'off' : 'on' }} local firebase"
				[class.active]="_LocalFirebaseState.isActive"
				(click)="_LocalFirebaseState.toggleAndReloadPage()"
			>
				<mat-icon class="material-icons-round">local_fire_department_rounded</mat-icon>
			</button>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Switch {{ _ConsoleLoggingState.isActive ? 'off' : 'on' }} console logging"
				[class.active]="_ConsoleLoggingState.isActive"
				(click)="_ConsoleLoggingState.toggleAndReloadPage()"
			>
				<mat-icon class="material-icons-round">terminal_rounded</mat-icon>
			</button>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Switch {{ _ConsoleLoggingVerboseState.isActive ? 'off' : 'on' }} verbose console logging"
				[class.active]="_ConsoleLoggingVerboseState.isActive"
				(click)="_ConsoleLoggingVerboseState.toggleAndReloadPage()"
			>
				<mat-icon class="material-icons-round">campaign</mat-icon>
			</button>

			<a
				*ngIf="_telemetryService.userSessionRecordingUrl$ | async as userSessionRecordingUrl"
				mat-icon-button
				class="bp-icon-button-md"
				bpTooltip="Logrocket Recording Url"
				bpTargetBlank
				[href]="userSessionRecordingUrl"
			>
				<mat-icon class="material-icons-round">rocket_launch</mat-icon>
			</a>

			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Close developer panel"
				(click)="_DeveloperOverlayPanelExpansionState.toggle()"
			>
				<mat-icon class="material-icons-round">close_rounded</mat-icon>
			</button>
		</div>

		<ng-template #expandPanelButtonTpl>
			<button
				mat-icon-button
				class="bp-icon-button-md"
				type="button"
				bpTooltip="Open developer panel"
				[class.has-active-develop-modes]="
					_LocalBackendState.isActive ||
					_MockedBackendState.isActive ||
					_LocalFirebaseState.isActive ||
					_ConsoleLoggingState.isActive ||
					_ConsoleLoggingVerboseState.isActive
				"
				(click)="_DeveloperOverlayPanelExpansionState.toggle()"
			>
				<mat-icon class="material-icons-round">developer_mode</mat-icon>
			</button>
		</ng-template>
	</div>
}
