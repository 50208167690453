import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { CryptoCurrency } from '@bp/shared/models/currencies';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { CheckoutSession } from '@bp/checkout-frontend/models';
import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-crypto-wallets-page',
	templateUrl: './crypto-wallets-page.component.html',
	styleUrls: [ './crypto-wallets-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CryptoWalletsPageComponent {

	protected readonly _appService = inject(AppService);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _cryptoWalletsPaymentMethod = this._session.paymentMethods
		.find(v => v.type === PaymentOptionType.cryptoWallet)!;

	protected _cryptoWallets: CryptoWallet[] = (<CryptoWalletPaymentOption> this._cryptoWalletsPaymentMethod.parsedJson![0]).wallets.map(dto => ({
		address: dto.address,
		cryptoCurrency: new CryptoCurrency(dto.cryptoCurrency),
	}));

	protected _cryptoLogoAssets = 'assets/images/cryptos/';
}

type CryptoWalletPaymentOption = { wallets: CryptoWallet[] };

type CryptoWallet = {
	address: string;
	cryptoCurrency: CryptoCurrency;
};
