
import { EventEmitter, Output, Input, ViewChild, Component, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyAutocompleteModule, MatLegacyAutocompleteOrigin, MatLegacyAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { CommonModule } from '@angular/common';

import { Destroyable } from '@bp/frontend/models/common';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { PaymentCardBrandIconComponent } from '@bp/frontend/components/payment-card/brand';
import { SavedPaymentCardToken } from '@bp/frontend/components/payment-card/models';

@Component({
	selector: 'bp-saved-payment-card-tokens-select',
	standalone: true,
	imports: [
		CommonModule,
		MatLegacyAutocompleteModule,

		SharedPipesModule,

		PaymentCardBrandIconComponent,
	],
	templateUrl: './saved-payment-card-tokens-select.component.html',
	styleUrls: [ './saved-payment-card-tokens-select.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedPaymentCardTokensSelectComponent extends Destroyable {

	@Input() connectedTo!: MatLegacyAutocompleteOrigin;

	@Input() paymentCardTokens!: SavedPaymentCardToken[] | null;

	@Output()
	readonly paymentCardTokenSelected = new EventEmitter<SavedPaymentCardToken>();

	@ViewChild(MatLegacyAutocompleteTrigger)
	protected _dropdownTrigger?: MatLegacyAutocompleteTrigger;

	private get __$dropdownPanel(): HTMLElement | null {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- the props may be undefined
		return this._dropdownTrigger?.autocomplete?.panel?.nativeElement;
	}

	open(): void {
		void this._dropdownTrigger?.openPanel();
	}

	close(): void {
		void this._dropdownTrigger?.closePanel();
	}

	closeIfWasntFocused(event: FocusEvent): void {
		if (this.__$dropdownPanel?.contains(<Node>event.relatedTarget))
			return;

		this.close();
	}
}
