import { Injectable } from '@angular/core';

import { ObsoleteUrlsRerouterService } from './obsolete-urls-rerouter.service';
import { TitleService } from './title.service';

/**
 * The service for starting and managing app-wide different services and their intercommuncation at one place
 */
@Injectable({
	providedIn: 'root',
})
export class SharedStartupService {

	constructor(
		private readonly __obsoleteUrlsRerouter: ObsoleteUrlsRerouterService,
		private readonly __titleService: TitleService,
	) { }

	init(): void {
		this.__obsoleteUrlsRerouter.init();

		this.__titleService.init();
	}

}
