import { Enumeration } from '@bp/shared/models/core/enum';

export class ThreeDsProcessingResultCallbackEvent extends Enumeration {

	static readonly threeDsMethodFinished = new ThreeDsProcessingResultCallbackEvent();

	static readonly threeDsMethodSkipped = new ThreeDsProcessingResultCallbackEvent();

	static readonly threeDsMethodHasError = new ThreeDsProcessingResultCallbackEvent();

	static readonly authResultReady = new ThreeDsProcessingResultCallbackEvent();

	static readonly initAuthTimedOut = new ThreeDsProcessingResultCallbackEvent();

	static override parseHook = (value: unknown): ThreeDsProcessingResultCallbackEvent | null => {
		switch (value) {
			case '3DSMethodFinished':

			case '3DsMethodFinished':
				return ThreeDsProcessingResultCallbackEvent.threeDsMethodFinished;

			case '3DSMethodSkipped':

			case '3DsMethodSkipped':
				return ThreeDsProcessingResultCallbackEvent.threeDsMethodSkipped;

			case '3DSMethodHasError':

			case '3DsMethodHasError':
				return ThreeDsProcessingResultCallbackEvent.threeDsMethodHasError;

			default:
				return null;
		}
	};
}
