import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { TRUST_LOGOS_FOR_WHITE_BG } from '@bp/frontend/models/business';

import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

	protected readonly _environment = inject(EnvironmentService);

	protected readonly _appService = inject(AppService);

	protected readonly _trustLogosDefs = TRUST_LOGOS_FOR_WHITE_BG;

}
