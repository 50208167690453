import { DTO, Mapper } from '@bp/shared/models/metadata';

import { ThreeDsProcessorKind } from '../processors';

import { ThreeDsProcessorAuthenticateResponse } from './three-ds-processor-authenticate-response';
import { ThreeDsProcessorGetAuthenticationResultResponse } from './three-ds-processor-get-authentication-result-response';
import { ThreeDsProcessorGetTransactionResponse } from './three-ds-processor-get-transaction-response';

export type ThreeDsProcessingGetAuthenticationResultResponseDTO = DTO<ThreeDsProcessingGetAuthenticationResultResponse>;

export class ThreeDsProcessingGetAuthenticationResultResponse extends ThreeDsProcessorAuthenticateResponse {

	readonly threeDSProcessorKind: ThreeDsProcessorKind = ThreeDsProcessorKind.gpayments;

	@Mapper(ThreeDsProcessorGetTransactionResponse)
	transaction!: ThreeDsProcessorGetTransactionResponse;

	@Mapper(ThreeDsProcessorGetAuthenticationResultResponse)
	authenticationResult!: ThreeDsProcessorGetAuthenticationResultResponse;

	constructor(dto: ThreeDsProcessingGetAuthenticationResultResponseDTO) {
		super(dto);
	}

}
