import { DTO, MapFromDTO, MetadataEntity, Required } from '@bp/shared/models/metadata';

export type ThreeDsProcessingSessionDTO = DTO<ThreeDsProcessingSession>;

export class ThreeDsProcessingSession extends MetadataEntity {

	@Required()
	bpTransactionId!: string;

	@Required()
	checkoutSessionId!: string;

	@Required()
	threeDsProcessorBrowserInfoCollectionUrl!: string;

	@MapFromDTO()
	threeDsProcessorBrowserInfoCollectionMonitoringUrl?: string;

	constructor(dto: ThreeDsProcessingSessionDTO) {
		super(dto);
	}

}
