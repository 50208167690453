import { Moment } from 'moment';

import { DTO, FieldViewType, Label, Mapper, MetadataEntity, momentMapper, View } from '@bp/shared/models/metadata';
import { User } from '@bp/shared/domains/users';

export class PaymentCardTokenSneakPeekAuditEventVm extends MetadataEntity {

	@View(FieldViewType.personEmail)
	@Mapper(User)
	user!: User;

	@Label('Created at')
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	created!: Moment;

	constructor(dto?: DTO<PaymentCardTokenSneakPeekAuditEventVm>) {
		super(dto);
	}

}
