/* eslint-disable @typescript-eslint/naming-convention */
import { map } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { AppService, DepositProcessingService } from '@bp/checkout-frontend/providers';
import { HAS_SELF_SUBMIT_FORM_KEY, IApiTransaction, IS_MPI, Transaction } from '@bp/checkout-frontend/models';

type DepositProcessingPageQueryParams = IApiTransaction & {
	continue3ds?: string;
	depositToken?: string;
	psp_name?: string;
	threeDsProcessorAuthResultReady?: string;
};

@Component({
	selector: 'bp-deposit-processing-page',
	templateUrl: './deposit-processing-page.component.html',
	styleUrls: [ './deposit-processing-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositProcessingPageComponent extends Destroyable {

	private readonly __activateRoute = inject(ActivatedRoute);

	protected readonly _appService = inject(AppService);

	private readonly __depositProcessingService = inject(DepositProcessingService);

	private readonly __depositRequest = this._appService.getPaymentRequestBody();

	constructor() {
		super();

		this._appService.markAppReady();

		this._appService.alertBeforeUnload();

		this.__activateRoute.queryParams
			.pipe(
				takeUntilDestroyed(this),
				map(queryParams => <DepositProcessingPageQueryParams>queryParams),
			)
			.subscribe(queryParams => {
				TelemetryService.log('Deposit processing page component', queryParams);

				if (queryParams.psp_name)
					this.__depositRequest!.provider = queryParams.psp_name;

				if (queryParams.continue3ds && JSON.parse(queryParams.continue3ds)) {
					void this.__depositProcessingService.continueProcessing({
						transactionId: queryParams.transaction_id,
					});

					return;
				}

				if (queryParams.threeDsProcessorAuthResultReady) {
					void this.__depositProcessingService.continueProcessing({
						transactionId: queryParams.transaction_id,
						threeDsProcessorAuthResultReady: !!queryParams.threeDsProcessorAuthResultReady,
					});

					return;
				}

				if (queryParams.depositToken) {
					void this.__depositProcessingService.deposit(this.__depositRequest!);

					return;
				}

				const trx = this._appService.transaction = new Transaction(queryParams);

				if (trx.isTryAgain) {
					if (this.__depositRequest)
						void this.__depositProcessingService.deposit(this.__depositRequest);
					else
						this._appService.navigate([ '/status', 'card-declined' ]);
				} else if (trx.id)
					void this.__depositProcessingService.checkPspTransactionStatus(trx.id);
				else if (IS_MPI && trx.transaction_id)
					void this.__depositProcessingService.continueMpiTransaction(trx.transaction_id);
				else
					void this.__depositProcessingService.deposit(this.__depositRequest!);
			});

		this.__depositProcessingService
			.redepositSuccessHtmlOrURL$
			.pipe(takeUntilDestroyed(this))
			.subscribe(htmlOrLink => {
				if (this._appService.isEmbedded || htmlOrLink.includes(HAS_SELF_SUBMIT_FORM_KEY)) {
					if (this._appService.transaction!.payment_method!.isCreditCard)
						this._appService.navigateTo3dSecure(htmlOrLink);

					if (this._appService.transaction!.payment_method!.isApm)
						this._appService.navigateToApmIframe({ htmlOrLink });
				} else
					this._appService.navigatePageTo3dParty(htmlOrLink);
			});
	}

}
