<bp-input
	inputmode="numeric"
	[nativeAutocomplete]="nativeAutocomplete ? 'cc-number' : nativeAutocomplete"
	[name]="name"
	[label]="label"
	[color]="color"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[placeholder]="placeholder"
	[hideClearButton]="hideClearButton"
	[required]="!!required"
	[hint]="hint"
	[pending]="pending"
	[mask]="_mask"
	[formControl]="_internalControl"
	[isSecret]="true"
>
	<bp-payment-card-brand-icon *ngIf="_paymentCardBrand" noTooltip @fade [src]="_paymentCardBrand" />
</bp-input>
