import { isEmpty, isUndefined, omitBy } from 'lodash-es';

import { inject, Injectable } from '@angular/core';

import { CHECKOUT_EVENT_SCOPE } from '@bp/frontend/domains/checkout/core';

import { ExternalExtensionService, IPagesExternalExtension } from './external-extension.service';

type IBpNotification = {
	event?: string;
	payload?: {
		currentPaymentMethodNote?: string;
		pages?: IPagesExternalExtension;
	};
};

@Injectable({
	providedIn: 'root',
})
export class HostNotificationsListenerService {

	private readonly __externalExtensionService = inject(ExternalExtensionService);

	constructor() {
		window.addEventListener('message', ({ data }: { data?: IBpNotification }) => {
			if (!data?.event?.includes(CHECKOUT_EVENT_SCOPE))
				return;

			const { event, payload } = data;

			if (event !== `${ CHECKOUT_EVENT_SCOPE }notification` || isEmpty(payload))
				return;

			const pagesExternalExtension: IPagesExternalExtension = omitBy({
				...payload.pages,
				deprecatedNote: payload.currentPaymentMethodNote,
			}, isUndefined);

			if (!isEmpty(pagesExternalExtension))
				this.__externalExtensionService.setPagesExternalExtension(pagesExternalExtension);
		});
	}

}
