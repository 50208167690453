import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { DepositProcessingRoutingModule } from './deposit-processing-routing.module';
import { DepositProcessingPageComponent } from './deposit-processing-page';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		DepositProcessingRoutingModule,
	],
	declarations: [ DepositProcessingPageComponent ],
})
export class DepositProcessingModule { }
