<div>
	<div class="filler"></div>
	<div class="gradient"></div>
	<div class="filler"></div>
	<div class="gradient"></div>
	<div class="filler"></div>
	<div class="gradient"></div>
	<div class="filler"></div>
	<div class="gradient"></div>
	<div class="filler"></div>
</div>
