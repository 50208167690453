import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { CountryComponent } from '@bp/frontend/components/core';
import { LanguagesService } from '@bp/frontend/domains/checkout/services';

@Component({
	selector: 'bp-language-select',
	standalone: true,
	imports: [
		CommonModule,
		MatSelectModule,
		MatButtonModule,

		FormsModule,

		CountryComponent,
	],
	templateUrl: './language-select.component.html',
	styleUrls: [ './language-select.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent {

	protected readonly _languagesService = inject(LanguagesService);

}
