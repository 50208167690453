
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EncryptCvvRequest, EncryptCvvResponse, PaymentCardToken, TokenizePaymentCardOutboundRequest } from '@bp/shared/domains/payment-card-tokens';
import { DTO } from '@bp/shared/models/metadata';
import { ENCRYPT_CVV_PATH_SEGMENT, NEST_PATH_SEGMENT, PAYMENT_CARD_TOKENS_PATH_SEGMENT, TOKENIZE_PATH_SEGMENT } from '@bp/shared/models/core';

import { mapTo } from '@bp/frontend/rxjs';
import { setJsonNamingStrategyHeader } from '@bp/frontend/services/http';

@Injectable({
	providedIn: 'root',
})
export class PaymentCardTokensApiService {

	private readonly __http = inject(HttpClient);

	private readonly __collectionPath = `${ NEST_PATH_SEGMENT }/${ PAYMENT_CARD_TOKENS_PATH_SEGMENT }`;

	private readonly __requestContext = setJsonNamingStrategyHeader('camelcase');

	tokenize(request: TokenizePaymentCardOutboundRequest): Observable<PaymentCardToken> {
		return this.__http
			.post<DTO<PaymentCardToken>>(
				`${ this.__collectionPath }/${ TOKENIZE_PATH_SEGMENT }`,
				request,
				{
					context: this.__requestContext,
				},
			)
			.pipe(mapTo(PaymentCardToken));
	}

	encryptCvv(request: EncryptCvvRequest): Observable<EncryptCvvResponse> {
		return this.__http
			.post<DTO<EncryptCvvResponse>>(
				`${ this.__collectionPath }/${ ENCRYPT_CVV_PATH_SEGMENT }`,
				request,
				{
					context: this.__requestContext,
				},
			)
			.pipe(mapTo(EncryptCvvResponse));
	}

}
