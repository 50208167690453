import { DTO, MapFromDTO, Secret } from '@bp/shared/models/metadata';

import { ThreeDsProcessorAuthenticateResponse } from './three-ds-processor-authenticate-response';

export type ThreeDsProcessorGetAuthenticationResultResponseDTO = DTO<ThreeDsProcessorGetAuthenticationResultResponse>;

/**
 * https://docs.activeserver.cloud/en/api/auth/#/Auth%20API%20V2/BRW_RESULT_V2_1
 */
export class ThreeDsProcessorGetAuthenticationResultResponse extends ThreeDsProcessorAuthenticateResponse {

	@MapFromDTO()
	challengeCancel?: string;

	@Secret()
	interactionCounter?: string;

	@Secret()
	threeDSRequestorTransID?: string;

	constructor(dto: ThreeDsProcessorGetAuthenticationResultResponseDTO) {
		super(dto);
	}

}
