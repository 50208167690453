import { Country } from '@bp/shared/models/countries';
import { DTO, FieldViewType, MapFromDTO, MetadataEntity, Required, View } from '@bp/shared/models/metadata';

export class MaskedPaymentCard extends MetadataEntity {

	@Required()
	maskedNumber!: string;

	@Required()
	bin!: string;

	@Required()
	last4Digits!: string;

	@Required()
	expireMonth!: number;

	@Required()
	expireYear!: number;

	@MapFromDTO()
	holderName?: string;

	@View(FieldViewType.paymentOptionBrand)
	brand?: string;

	@MapFromDTO()
	type?: string;

	@MapFromDTO()
	issuer?: string | null;

	@View(FieldViewType.country)
	country?: Country;

	constructor(dto: DTO<MaskedPaymentCard>) {
		super(dto);
	}

}
