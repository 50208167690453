<div>
	<bp-back-btn />

	<div class="row">
		<div *ngFor="let bank of _banks" class="payment-option-box" matRipple (click)="_navigate(bank)">
			<div class="bank-name">{{ bank.name }}</div>

			<bp-img *ngIf="bank.logo.urls as urls" class="img-white-background-eraser" hiddenOnFailure [urls]="urls" />
		</div>
	</div>
</div>
