{
	"BR": "CPF/CNPJ",
	"UY": "CI",
	"NI": "CEDULA DE IDENTIDAD",
	"NG": "Tax ID",
	"AR": "DNI / CUIT / CUIL",
	"BO": "CI / CIE / PASS / NIT",
	"CM": "PASS / CI / DL (Driving License)",
	"CA": "DL (Driving License) / HC (Health Card) / PASS (Passport)",
	"CL": "ID / RUN / RUT",
	"CO": "CC / NIT / CE / PASS",
	"CR": "CI",
	"CI": "ID",
	"DO": "CIE",
	"EC": "CC / RUC / PASS",
	"SV": "DUI",
	"GH": "ID",
	"GT": "DPI",
	"IN": "ID (PAN) / DL (Driver's License) / UID (Aadhar Card)",
	"ID": "NIK / KTP",
	"JP": "DL / ID / PASS / RD (Resident Registration Card)",
	"KE": "ID",
	"MY": "ID",
	"MX": "CURP / RFC / IFE / PASS / Residencia",
	"PA": "CIP / PASS",
	"PY": "CIC (Cédula de Identidad Civil)",
	"PE": "CE / DNI / PASS / RUC",
	"PH": "PSN",
	"SG": "NRIC / PASS",
	"ZA": "ID",
	"TZ": "ID",
	"TH": "ID",
	"UG": "RIC / NID",
	"VE": "CI / RIF",
	"VN": "ID"
}
