/* eslint-disable @typescript-eslint/naming-convention */

import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { NumberMaskConfig } from '@bp/shared/features/text-mask';
import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { CheckoutValidators } from '@bp/frontend/domains/checkout/validation';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { AppService } from '@bp/checkout-frontend/providers';
import { CheckoutSession } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-amount',
	templateUrl: './amount.component.html',
	styleUrls: [ './amount.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountComponent extends Destroyable implements OnInit, OnChanges {

	protected readonly _appService = inject(AppService);

	private readonly __activatedRoute = inject(ActivatedRoute);

	private readonly __cdr = inject(ChangeDetectorRef);

	@Input() config!: {
		amount: number;
		min_amount: number;
		max_amount: number;
		currency_rate?: {
			rate: number;
		};
	};

	@Input() control?: FormControl | null;

	@Input() installmentAmount?: number;

	@Input()
	@HostBinding('class.amount-lock')
	amountLock!: boolean;

	@Input() currency?: FiatCurrency;

	@Output() readonly currencyChange = new EventEmitter();

	@Input() fiatCurrencies?: FiatCurrency[];

	@Input() noDecimals!: boolean;

	@Output() readonly inputFocus = new EventEmitter();

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _initialAmount!: number;

	protected get _rate(): number | undefined {
		return this.config.currency_rate?.rate;
	}

	protected get _amountMaxFontSize(): number {
		return this._appService.isBrightTheme ? 24 : 29;
	}

	protected _min!: number;

	protected _max!: number;

	protected _value$ = new BehaviorSubject<string>('');

	protected _currentCurrency?: FiatCurrency;

	protected _previousCurrency?: FiatCurrency;

	protected _amountMask!: NumberMaskConfig;

	private get __allowDecimal(): boolean {
		return this.noDecimals ? false : !!this._currentCurrency?.allowDecimal;
	}

	ngOnInit(): void {
		this._currentCurrency = this._previousCurrency = this.currency;

		this.__activatedRoute.params
			.pipe(takeUntilDestroyed(this))
			.subscribe(params => {
				if (!this.currency) {
					this._currentCurrency = this._previousCurrency = this.currency = new FiatCurrency(params['currencyCode']);

					this.currencyChange.emit(this._currentCurrency);
				}

				this._amountMask = this.__createAmountMask(this._currentCurrency!);
			});

		this._initialAmount = this.config.amount || Number(this._session.amount.value);

		this._initialAmount = this._rate
			? this._initialAmount / this._rate
			: this._initialAmount;

		this._initialAmount = this.__allowDecimal
			? Number(this._initialAmount.toFixed(this._currentCurrency!.decimalLimit))
			: Math.round(this._initialAmount);

		this.__updateAmountAccordingToCurrency(true);

		this.control?.valueChanges
			.pipe(
				startWith(this.control.value),
				takeUntilDestroyed(this),
			)
			.subscribe(v => void this._value$.next(v));
	}

	ngOnChanges({ currency }: SimpleChanges<this>): void {
		if (currency && !currency.firstChange && this.currency && this.currency !== this._currentCurrency)
			this._onCurrencyChange(this.currency);
	}

	private __createAmountMask(currency: FiatCurrency): NumberMaskConfig {
		return new NumberMaskConfig({
			placeholderChar: NumberMaskConfig.whitespace,
			includeMaskInValue: false,
			guide: false,
			prefix: currency.symbol,
			thousandsSeparatorSymbol: currency.thousandsSeparatorSymbol,
			allowDecimal: this.noDecimals ? false : currency.allowDecimal,
			decimalSeparatorSymbol: currency.decimalSeparatorSymbol,
			decimalLimit: currency.decimalLimit,
		});
	}

	protected _onCurrencyChange(currency: FiatCurrency): void {
		this._previousCurrency = this._currentCurrency;

		this._currentCurrency = currency;

		this._amountMask = this.__createAmountMask(currency);

		this.currencyChange.emit(currency);

		this.__updateAmountAccordingToCurrency();

		this.__cdr.detectChanges();
	}

	private __updateAmountAccordingToCurrency(init = false): void {
		const targetRate = this._session.limitsCurrenciesConversionRates[this._currentCurrency!.code] || 1;
		const baseRate = this._session.limitsCurrenciesConversionRates[this._previousCurrency!.code] || 1;

		this._min = Math.ceil((this.config.min_amount || 0) * targetRate);

		this._max = Math.floor((this.config.max_amount || Number.POSITIVE_INFINITY) * targetRate);

		if (this.control) {
			const controlValue = this.__allowDecimal ? Number.parseFloat(this.control.value) : Number.parseInt(this.control.value);
			const controlValueInBaseCurrency = controlValue / baseRate;

			const amount = init
				? this._initialAmount
				: controlValueInBaseCurrency * targetRate;

			let value = Math.max(this._min, Math.min(amount, this._max));

			value = this.__allowDecimal
				? Number(value.toFixed(this._currentCurrency!.decimalLimit))
				: Math.round(value);

			this.control.setValue(value);

			this.control.setValidators(this.__getAmountValidators());

			this.control.updateValueAndValidity();
		}

	}

	private __getAmountValidators(): IValidatorFunc[] {
		return [
			Validators.required,
			Validators.minimum(this._min),
			Validators.maximum(this._max),
			CheckoutValidators.amount,
		];
	}

}
