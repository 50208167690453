import { Mapper } from '@bp/shared/models/metadata';

import { ThreeDsProcessor } from './three-ds-processor';
import { ThreeDsProcessorsCreateRequest } from './three-ds-processors-create-request';

export class ThreeDsProcessorsUpdateRequest extends ThreeDsProcessorsCreateRequest {

	@Mapper(ThreeDsProcessor)
	processors!: ThreeDsProcessor[];

}
