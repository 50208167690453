import { Enumeration } from '@bp/shared/models/core/enum';

/**
 * https://docs.activeserver.cloud/en/api/auth/#/Auth%20API%20V2/BRW_AUTH_V2_1
 * `transStatus` property description in response
 */
export class ThreeDsProcessingAuthenticationStatus extends Enumeration {

	static readonly y = new ThreeDsProcessingAuthenticationStatus();

	static readonly n = new ThreeDsProcessingAuthenticationStatus();

	static readonly u = new ThreeDsProcessingAuthenticationStatus();

	static readonly a = new ThreeDsProcessingAuthenticationStatus();

	static readonly r = new ThreeDsProcessingAuthenticationStatus();

	static readonly c = new ThreeDsProcessingAuthenticationStatus();

	static readonly d = new ThreeDsProcessingAuthenticationStatus();

	static readonly i = new ThreeDsProcessingAuthenticationStatus();

}
