<div class="card-container">
	<button
		*ngIf="_session.initialCheckoutType.isRegular && _paymentOptionInstancesManager.paidAmount === 0"
		mat-button
		class="back-button"
		type="button"
		(click)="_appService.setCheckoutType(_CheckoutType.regular); _appService.navigateByUrl('/')"
	>
		{{ 'btn.payment-options' | translate }}
	</button>

	<bp-payment-option-instances-breakdown />
</div>
