import { exhaustMap, firstValueFrom } from 'rxjs';
import { mapValues } from 'lodash-es';

import { Injectable, inject } from '@angular/core';

import { MetadataEntity, MetadataObjectValidator } from '@bp/shared/models/metadata';
import { ValidationError } from '@bp/shared/features/validation/models';
import { EncryptCvvRequest, PaymentCardTokenType, TokenizePaymentCardOutboundRequest } from '@bp/shared/domains/payment-card-tokens';
import { PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { Dictionary } from '@bp/shared/typings';

import { BloxType, BloxControllerHostEvent } from '@bp/frontend/domains/checkout/core';
import { mapTo } from '@bp/frontend/rxjs';
import { ErrorsTextsProviderService } from '@bp/frontend/features/validation';

import { PaymentCardTokensApiService } from '@bp/checkout-frontend/providers';

import { BloxPageComponent } from '../pages/blox/blox-page.component';
import { PaymentCardTokenCreateEventPayload } from '../models';

import { BloxHostNotifierService } from './blox-host-notifier.service';
import { BloxHostEventsListenerService } from './blox-host-events-listener.service';

@Injectable({
	providedIn: 'root',
})
export class BloxService {

	private readonly __validator = new MetadataObjectValidator();

	private readonly __hostNotifier = inject(BloxHostNotifierService);

	private readonly __hostEventsListener = inject(BloxHostEventsListenerService);

	private readonly __paymentCardTokensApiService = inject(PaymentCardTokensApiService);

	private readonly __errorsTextsProviders = inject(ErrorsTextsProviderService);

	private __controllerId!: string;

	get controllerId(): string {
		return this.__controllerId;
	}

	constructor() {
		this.__listenForHostCreatePaymentCardTokenEvent();

		this.__listenForHostEncryptCvvEvent();
	}

	setControllerId(controllerId: string): void {
		this.__controllerId = controllerId;
	}

	validate(metadataObject: MetadataEntity): boolean {
		try {
			this.__validator.validate(metadataObject);

			return true;
		} catch (error) {
			void this.__hostNotifier.error({
				error: error instanceof ValidationError
					? this.__convertToHumanErrors(error)
					: 'Unexpected error',
			});

			return false;
		}
	}

	private __convertToHumanErrors(error: ValidationError): Dictionary<string> {
		return mapValues(
			error.validationErrors,
			(validationErrors, propertyName) => this.__errorsTextsProviders
				.renderFirstErrorText(validationErrors, propertyName),
		);
	}

	getControllerBloxByType(bloxType: BloxType): BloxPageComponent | null {
		return this.getControllerBloxByTypeMap().get(bloxType) ?? null;
	}

	getControllerBloxByTypeMap(): Map<BloxType, BloxPageComponent> {
		const controllerBloxMap = new Map<BloxType, BloxPageComponent>();

		// eslint-disable-next-line @typescript-eslint/prefer-for-of -- frames prop is not iterable
		for (let index = 0; index < window.parent.frames.length; index++) {
			const frame = window.parent.frames[index];

			try {
				if (frame.blox && frame.blox.controllerId === this.__controllerId) {
					controllerBloxMap.set(
						BloxType.parseStrict(frame.blox.bloxType),
						frame.blox,
					);
				}
			} catch {
			}
		}

		return controllerBloxMap;
	}

	private __listenForHostCreatePaymentCardTokenEvent(): void {
		this.__hostEventsListener
			.on(BloxControllerHostEvent.createPaymentCardToken)
			.pipe(
				mapTo(PaymentCardTokenCreateEventPayload),
				exhaustMap(async eventPayload => this.__tryTokenizePaymentCard(eventPayload)),
			)
			.subscribe();
	}

	private __listenForHostEncryptCvvEvent(): void {
		this.__hostEventsListener
			.on(BloxControllerHostEvent.encryptCvv)
			.subscribe(() => void this.__tryEncryptCvv());
	}

	private async __tryTokenizePaymentCard(eventPayload: PaymentCardTokenCreateEventPayload): Promise<void> {
		const tokenizePaymentCardRequest = this.__tryBuildTokenizePaymentCardRequestPayload(eventPayload);

		if (!tokenizePaymentCardRequest)
			return;

		try {
			const paymentCardToken = await firstValueFrom(this.__paymentCardTokensApiService.tokenize(
				tokenizePaymentCardRequest,
			));

			this.__hostNotifier.paymentCardToken(paymentCardToken);
		} catch (error: unknown) {
			void this.__hostNotifier.error({ error: `${ error }` });
		}
	}

	private __tryBuildTokenizePaymentCardRequestPayload(
		eventPayload: PaymentCardTokenCreateEventPayload,
	): TokenizePaymentCardOutboundRequest | null {
		if (!this.validate(eventPayload))
			return null;

		const cvv = this.getControllerBloxByType(BloxType.cardCvv)?.value;
		const { month, year } = PaymentCardsUtils.parseExpireDateString(
			this.getControllerBloxByType(BloxType.cardExpiryDate)?.value,
		);

		const tokenizePaymentCardRequest = new TokenizePaymentCardOutboundRequest({
			cardHolderName: eventPayload.cardHolderName,
			type: eventPayload.singleUse
				? PaymentCardTokenType.singleUse
				: PaymentCardTokenType.multiUse,
			cvv: cvv ?? undefined,
			expireMonth: Number.isNaN(month) ? undefined : month,
			expireYear: Number.isNaN(year) ? undefined : year,
			paymentCardNumber: this.getControllerBloxByType(BloxType.cardNumber)?.value,
		});

		if (!this.validate(tokenizePaymentCardRequest))
			return null;

		return tokenizePaymentCardRequest;
	}

	private async __tryEncryptCvv(): Promise<void> {
		const encryptCvvRequest = this.__tryBuildEncryptCvvRequestPayload();

		if (!encryptCvvRequest)
			return;

		try {
			const encryptedCvvResponse = await firstValueFrom(
				this.__paymentCardTokensApiService.encryptCvv(encryptCvvRequest),
			);

			this.__hostNotifier.encryptedCvv(encryptedCvvResponse);
		} catch (error: unknown) {
			void this.__hostNotifier.error({ error: `${ error }` });
		}
	}

	private __tryBuildEncryptCvvRequestPayload(): EncryptCvvRequest | null {
		const encryptCvvRequest = new EncryptCvvRequest({
			cvv: this.getControllerBloxByType(BloxType.cardCvv)?.value,
		});

		if (!this.validate(encryptCvvRequest))
			return null;

		return encryptCvvRequest;
	}
}
