<form>
	<bp-payment-card-number-input
		*ngIf="bloxType.isCardNumber"
		name="cardNumber"
		hideErrorText
		[placeholder]="_config?.placeholder"
		[required]="true"
		[formControl]="_control"
		[skipLuhnValidation]="_environment.isNotProduction || !!_config?.skipLuhnValidation"
		(paymentCardBrandChange)="_onPaymentCardBrandChange($event)"
		(valueChange)="_onValueChange()"
	/>

	<bp-payment-card-expire-input
		*ngIf="bloxType.isCardExpiryDate"
		name="cardExpiryDate"
		hideErrorText
		[required]="true"
		[formControl]="_control"
		[placeholder]="_config?.placeholder"
		(valueChange)="_onValueChange()"
	/>

	<bp-payment-card-cvv-input
		*ngIf="bloxType.isCardCvv"
		name="cardCvv"
		hideErrorText
		[useMaskedChars]="!_config?.unmaskedCvv"
		[required]="true"
		[paymentCardBrand]="_paymentCardBrand"
		[formControl]="_control"
		[placeholder]="_config?.placeholder"
		(valueChange)="_onValueChange()"
	/>
</form>
