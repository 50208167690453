<bp-payment-card-brands-svg-icons-definitions />
<bp-svg-shared-icons-definitions />

<ng-container *ngIf="_appService.isBlox; else defaultCheckoutTpl">
	<router-outlet />
</ng-container>

<ng-template #defaultCheckoutTpl>
	<bp-global-spinner *ngIf="(_isShowGlobalSpinner$ | async) === true" @fadeOut />

	<div *ngIf="(_networkIsLongOffline$ | async) === true" class="offline-vail" @fadeIn>
		<span> You device is offline, please check your connection. </span>
	</div>

	<div class="wrapper">
		<bp-header />

		<main>
			<div class="container">
				<router-outlet />
			</div>
		</main>

		<bp-footer />
	</div>

	<bp-order-summary *ngIf="_appService.isStandalone" />
</ng-template>
