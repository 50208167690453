import { camelCase, isNil, isObject, kebabCase, mapKeys, omitBy, pickBy } from 'lodash-es';
import { Memoize } from 'typescript-memoize';
import { map } from 'rxjs';

import { Component, ChangeDetectionStrategy, inject, Input, OnInit, ChangeDetectorRef, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { Validators } from '@bp/shared/features/validation/models';
import { delay } from '@bp/shared/utilities/core';
import { Dictionary } from '@bp/shared/typings';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { BloxStateEventPayload, BloxType, BloxHostEvent, BloxStyle, IBloxConfiguration } from '@bp/frontend/domains/checkout/core';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { ErrorsTextsProviderService } from '@bp/frontend/features/validation';

import { AppService } from '@bp/checkout-frontend/providers';

import { BloxHostEventsListenerService, BloxHostNotifierService, BloxService } from '../../services';

@Component({
	selector: 'bp-blox-page',
	templateUrl: './blox-page.component.html',
	styleUrls: [ './blox-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BloxPageComponent extends Destroyable implements OnInit, AfterViewInit {

	private readonly __appService = inject(AppService);

	private readonly __bloxService = inject(BloxService);

	protected readonly _environment = inject(EnvironmentService);

	private readonly __hostNotifier = inject(BloxHostNotifierService);

	private readonly __hostEventsListener = inject(BloxHostEventsListenerService);

	private readonly __errorsTextsProviders = inject(ErrorsTextsProviderService);

	private readonly __cdr = inject(ChangeDetectorRef);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	@Input()
	controllerId!: string;

	@Input()
	bloxId!: string;

	@Input({ transform: (value: string) => BloxType.parseStrict(value) })
	bloxType!: BloxType;

	get value(): string | undefined {
		return this._control.value ?? undefined;
	}

	@Memoize()
	get $input(): HTMLInputElement {
		return this.__$host.querySelector('input')!;
	}

	protected _paymentCardBrand: PaymentCardBrand | null = null;

	protected readonly _control = new FormControl('', Validators.required);

	protected _config?: IBloxConfiguration;

	private readonly __$style = document.createElement('style');

	constructor() {
		super();

		document.head.append(this.__$style);

		this.__setConfigAndStyleOnHostEvent();

		this.__setPaymentCardBrandHostEvent();

		this.__clearControlOnHostEvent();

		this.__validateControlOnHostEvent();

		window.blox = this;
	}

	ngOnInit(): void {
		this.__init();
	}

	ngAfterViewInit(): void {
		void this.__whenRenderedNotifyHost();
	}

	setPaymentCardBrand(brand: PaymentCardBrand | null): void {
		this._paymentCardBrand = brand;

		this.__cdr.detectChanges();

		if (this.bloxType.isCardCvv && this._control.value)
			this.__hostNotifier.validate(this.__buildStateEventPayload());
	}

	protected _onPaymentCardBrandChange(brand: PaymentCardBrand | null): void {
		this.setPaymentCardBrand(brand);

		this.__bloxService
			.getControllerBloxByType(BloxType.cardCvv)!
			.setPaymentCardBrand(brand);
	}

	protected async _onValueChange(): Promise<void> {
		await delay(0); // wait for the control to update

		this.__hostNotifier.change(this.__buildStateEventPayload());
	}

	@HostListener('focusin', [ '$event' ])
	protected _onFocus(): void {
		this.__hostNotifier.focus(this.__buildStateEventPayload());
	}

	@HostListener('focusout', [ '$event' ])
	protected _onBlur(): void {
		this.__hostNotifier.blur(this.__buildStateEventPayload());
	}

	private __init(): void {
		this.__bloxService.setControllerId(this.controllerId);

		this.__hostNotifier.setConfig({
			scope: this.bloxType.kebabCase,
			id: this.bloxId,
		});

		this.__hostNotifier.bloxInit();
	}

	private __whenRenderedNotifyHost(): void {
		this.__hostNotifier.bloxRendered();
	}

	private __buildStateEventPayload(): BloxStateEventPayload {
		return <BloxStateEventPayload>omitBy(
			{
				empty: this._control.value === '',
				valid: this._control.valid,
				invalid: this._control.invalid,
				dirty: this._control.dirty,
				pristine: this._control.pristine,
				touched: this._control.touched,
				untouched: this._control.untouched,
				error: this.__getHumanError(),
				brand: this._paymentCardBrand?.name,
			} satisfies BloxStateEventPayload,
			isNil,
		);
	}

	private __setConfigAndStyleOnHostEvent(): void {
		this.__hostEventsListener
			.on(BloxHostEvent.setConfig)
			.pipe(
				map(config => <IBloxConfiguration> <unknown>mapKeys(config, (value, key) => camelCase(key))),
				takeUntilDestroyed(this),
			)
			.subscribe(config => {
				this._config = config;

				this.__buildAndApplyInputStylesheetBasedOnHostConfig();

				this.__loadFontsFromConfig();

				this.__cdr.detectChanges();
			});
	}

	private __setPaymentCardBrandHostEvent(): void {
		this.__hostEventsListener
			.on(BloxHostEvent.setPaymentCardBrand)
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ cardBrand }) => {
				if (cardBrand) {
					const parsedCardBrand = PaymentCardBrand.parse(cardBrand);

					if (parsedCardBrand) {
						this.setPaymentCardBrand(parsedCardBrand);

						this.__hostNotifier.setPaymentCardBrand({ valid: true });
					} else {
						this.__hostNotifier.setPaymentCardBrand({
							valid: false,
							error: `Unknown brand: "${ cardBrand }"; \n Available brands: ${ PaymentCardBrand.assignable.join(', ') }`,
						});
					}
				} else {
					this.setPaymentCardBrand(null);

					this.__hostNotifier.setPaymentCardBrand({ valid: true });
				}
			});
	}

	private __loadFontsFromConfig(): void {
		if (this._config?.fontsSources)
			this.__appService.loadFonts(this._config.fontsSources);
	}

	private __clearControlOnHostEvent(): void {
		this.__hostEventsListener
			.on(BloxHostEvent.clear)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._control.setValue(''));
	}

	private __validateControlOnHostEvent(): void {
		this.__hostEventsListener
			.on(BloxHostEvent.validate)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				void this._control.updateValueAndValidity();

				this.__hostNotifier.validate(this.__buildStateEventPayload());
			});
	}

	private __getHumanError(): string | null {
		return this._control.errors
			? this.__errorsTextsProviders.renderFirstErrorText(this._control.errors, this.bloxType.name)
			: null;
	}

	private __buildAndApplyInputStylesheetBasedOnHostConfig(): void {
		if (!this._config?.style)
			return;

		const baseSelector = ':root .control input.mat-input-element';

		this.__$style.innerHTML = `
			${ this.__bloxStyleToCssText(baseSelector, this._config.style.base) }
			${ this.__bloxStyleToCssText(`${ baseSelector }.ng-invalid`, this._config.style.invalid) }
			${ this.__bloxStyleToCssText(`${ baseSelector }.ng-valid`, this._config.style.valid) }
			${ this.__bloxStyleToCssText(':root .control.empty input.mat-input-element', this._config.style.empty) }
		`;

	}

	private __bloxStyleToCssText(baseSelector: string, bloxStyle?: BloxStyle): string {
		if (!bloxStyle)
			return '';

		const cssStyleDeclaration = <CSSStyleDeclaration>omitBy(bloxStyle, isObject);
		const pseudoStatesStyles = <Dictionary<CSSStyleDeclaration>>pickBy(bloxStyle, isObject);

		let stylesheet = `
				${ baseSelector } {
					${ cssStyleDeclarationToCssText(cssStyleDeclaration) }
				}
			`;

		for (const [ pseudoState, styles ] of Object.entries(pseudoStatesStyles)) {
			const pseudoStateStylesheet = `
				${ baseSelector }${ pseudoState } {
					${ cssStyleDeclarationToCssText(styles) }
				}
			`;

			stylesheet += pseudoStateStylesheet;
		}

		return stylesheet;

		function cssStyleDeclarationToCssText(style: CSSStyleDeclaration): string {
			return Object.entries(style)
				.map(([ key, value ]) => `${ kebabCase(key) }:${ value };`)
				.join('\n');
		}
	}

}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		blox?: BloxPageComponent;
	}
}
