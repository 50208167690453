
import { camelCase, forOwn } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, skip } from 'rxjs';

import { Component, ChangeDetectionStrategy, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { Typify, ensureFormGroupConfig } from '@bp/shared/typings';
import { PaymentCardBrand, PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { Validators } from '@bp/shared/features/validation/models';
import { PaymentCardToken, PaymentCardTokenType, TokenizePaymentCardOutboundRequest } from '@bp/shared/domains/payment-card-tokens';
import { buildUrl } from '@bp/shared/utilities/core';

import { BpError } from '@bp/frontend/models/core';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { FADE, FADE_IN, SLIDE } from '@bp/frontend/animations';

import { AppService, PaymentCardTokensApiService } from '@bp/checkout-frontend/providers';
import { CheckoutSession, EmbeddedData } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-payment-card-token-page',
	templateUrl: './payment-card-token-page.component.html',
	styleUrls: [ './payment-card-token-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE, FADE_IN ],
})
export class PaymentCardTokenPageComponent extends Destroyable {

	protected readonly _appService = inject(AppService);

	protected readonly _environment = inject(EnvironmentService);

	private readonly __formBuilder = inject(FormBuilder);

	private readonly __paymentCardTokensApiService = inject(PaymentCardTokensApiService);

	private readonly __hostNotifier = inject(HostNotifierService);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected get _embeddedData(): EmbeddedData {
		return this._appService.embedded!;
	}

	protected readonly _isSubmitting$ = new BehaviorSubject(false);

	protected _form: FormGroup;

	protected _globalError?: string | null;

	protected _textFieldMaxLength = 255;

	protected get _controls(): Typify<IPaymentCardTokenForm, FormControl | undefined> {
		return <any> this._form.controls;
	}

	@ViewChild(FormGroupDirective)
	protected _formGroupDirective?: FormGroupDirective;

	protected _secureLogosPrefix = this._session.theme.isDark ? '-white' : '';

	protected _paymentCardBrand: PaymentCardBrand | null = null;

	protected get _cannotSubmit(): boolean {
		return (!this._session.validateInputsOnFocusOut && this._form.invalid) || this._form.pending;
	}

	constructor() {
		super();

		this._isSubmitting$
			.pipe(
				skip(1),
				takeUntilDestroyed(this),
			)
			.subscribe(isSubmitting => void this.__hostNotifier.tokenizingPaymentCard(isSubmitting));

		this._form = this.__formBuilder.group(
			ensureFormGroupConfig<IPaymentCardTokenForm>({
				...(this._session.cardHolderName.valid ? {} : {
					cardHolderName: [
						'',
						{ validators: this._session.cardHolderName.validator },
					],
				}),

				cardNumber: [
					this._embeddedData.creditCard?.number.toString() ?? '',
					{
						validators: Validators.required,
					},
				],

				cardExpiryDate: [
					this._embeddedData.creditCard
						? `${ this._embeddedData.creditCard.expireMonth }/${ this._embeddedData.creditCard.expireYear }`
						: '',
					{ validators: Validators.required },
				],

				cardCvv: [
					this._embeddedData.creditCard?.cvv.toString() ?? '',
					{
						validators: Validators.required,
					},
				],

				saveCard: [ this._session.tickSaveCreditCardCheckboxByDefault ],

			}),
			{
				updateOn: this._session.validateInputsOnFocusOut ? 'blur' : 'change',
			},
		);
	}

	protected _originalOrderKeyValueComparator = () => 0;

	protected _markAsDirtyAllControls(): void {
		forOwn(
			this._controls,
			control => {
				if (control?.pristine) {
					void control.markAsDirty();

					void control.updateValueAndValidity(); // invoke changes
				}
			},
		);
	}

	protected _revalidatePaymentCardNumber(): void {
		this._controls.cardNumber?.updateValueAndValidity();
	}

	// #region submitting
	protected async _submit(): Promise<void> {
		if (this._isSubmitting$.value)
			return;

		TelemetryService.log('Payment card token form submitted');

		if (this._form.invalid) {
			this._markAsDirtyAllControls();

			TelemetryService.log('Payment card token form is invalid');

			return;
		}

		this._globalError = null;

		this._isSubmitting$.next(true);

		await this.__tokenizeCard();
	}

	private async __tokenizeCard(): Promise<void> {
		this._appService.alertBeforeUnload();

		try {
			TelemetryService.log('Tokenize payment card');

			const result = await firstValueFrom(
				this.__paymentCardTokensApiService.tokenize(
					this.__buildRequestBody(),
				),
			);

			this.__onTokenizeCardResponse(result);
		} catch (error: unknown) {
			TelemetryService.log('Tokenization failed', error);

			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private __onTokenizeCardResponse(token: PaymentCardToken): void {
		this._appService.removeAlertBeforeUnload();

		this.__hostNotifier.paymentCardToken(token);

		if (this._session.url!.success) {
			const redirectUrl = buildUrl(this._session.url!.success, {
				orderId: this._session.orderId,
				sessionId: this._session.id,
			});

			this._appService.redirectHostTo(redirectUrl);
		} else
			TelemetryService.log('Payment card tokenization succeeded but no success url provided');
	}

	private __onPaymentError(error: BpError): void {
		this._appService.removeAlertBeforeUnload();

		this._appService.setError(error);

		this._isSubmitting$.next(false);

		this._globalError = error.messages.find(errorMessage => !errorMessage.field)?.message ?? null;

		for (const { message, field } of error.messages.filter(errorMessage => errorMessage.field)) {
			const control = this.__getControlForErrorField(<keyof TokenizePaymentCardOutboundRequest>field);

			if (control) {
				control.setErrors({ server: message });

				control.markAsTouched();
			}
		}
	}

	private __buildRequestBody(): TokenizePaymentCardOutboundRequest {
		const { month, year } = PaymentCardsUtils.parseExpireDateString(
			this._controls.cardExpiryDate!.value,
		);

		return new TokenizePaymentCardOutboundRequest({
			type: this._session.hideSaveCreditCardCheckbox || this.__getControlValue('saveCard')
				? PaymentCardTokenType.multiUse
				: PaymentCardTokenType.singleUse,
			email: this._session.email.value ?? null,
			cardHolderName: this.__getControlValue('cardHolderName')?.trim() ?? this._session.cardHolderName.value ?? null,
			paymentCardNumber: this.__getControlValue('cardNumber')!.replace(/\s/ug, ''),
			expireMonth: month,
			expireYear: year,
			cvv: this.__getControlValue('cardCvv')!,
		});
	}

	private __getControlValue<TKey extends keyof IPaymentCardTokenForm>(key: TKey): IPaymentCardTokenForm[TKey] | undefined {
		return this._controls[key]?.value;
	}

	private __mapRequestPropertyNameToControlName(field: keyof TokenizePaymentCardOutboundRequest): keyof IPaymentCardTokenForm {
		let controlName: keyof IPaymentCardTokenForm;

		switch (field) {
			case 'paymentCardNumber':
				controlName = 'cardNumber';
				break;

			case 'expireMonth':

			case 'expireYear':
				controlName = 'cardExpiryDate';
				break;

			case 'cvv':
				controlName = 'cardCvv';
				break;

			default:
				controlName = <keyof IPaymentCardTokenForm>camelCase(field);
		}

		return controlName;
	}

	private __getControlForErrorField(field: keyof TokenizePaymentCardOutboundRequest): FormControl | null {
		return this._controls[this.__mapRequestPropertyNameToControlName(field)] ?? null;
	}

	// #endregion submitting

}

interface IPaymentCardTokenForm {
	cardHolderName: string;
	cardNumber: string;
	cardExpiryDate: string;
	cardCvv: string;
	saveCard: boolean;
}
