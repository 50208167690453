import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

@Component({
	selector: 'bp-dictionary-table',
	templateUrl: './dictionary-table.component.html',
	styleUrls: [ './dictionary-table.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionaryTableComponent {

	@Input() src!: Dictionary<string>;

}
