import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { DTO, MapFromDTO, Mapper, MetadataEntity, Required, Secret } from '@bp/shared/models/metadata';

/**
 * The properties are optional cause its the only one to remove a provider by nulling properties on gpayments side
 */
export class ThreeDsProcessorProvider extends MetadataEntity {

	@Required()
	@Mapper((dto: unknown): PaymentCardBrand => {
		const brand = PaymentCardBrand.parseStrict(dto);

		if (brand === PaymentCardBrand.amex)
			return PaymentCardBrand.americanexpress;

		return brand;
	})
    provider!: PaymentCardBrand;

	@Secret()
	acquirerBin?: string;

	@MapFromDTO()
    acquirerMerchantCategoryCode?: string;

	@Secret()
	overrideAcquirerMerchantId?: string;

	@MapFromDTO()
	requestorId?: string;

	@MapFromDTO()
    requestorName?: string;

	constructor(dto?: DTO<ThreeDsProcessorProvider>) {
		super(dto);
	}

}
