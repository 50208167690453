<bp-back-btn />

<section class="card">
	<mat-tab-group *ngIf="_requisites.length > 1; else aloneModelTpl" [dynamicHeight]="true">
		<mat-tab *ngFor="let requisite of _requisites" [label]="requisite['tab_name'] || requisite['currency'].code">
			<ng-container *ngTemplateOutlet="tableViewTpl; context: { '\$implicit': requisite }" />
		</mat-tab>
	</mat-tab-group>

	<ng-template #aloneModelTpl>
		<ng-container *ngTemplateOutlet="tableViewTpl; context: { '\$implicit': _requisites[0], standalone: true }" />
	</ng-template>
</section>

<ng-template #tableViewTpl let-requisite let-standalone="standalone">
	<span *ngIf="standalone" class="title">
		{{
			requisite['tab_name']
				? requisite['tab_name']
				: 'Bank Wire Transfer Details: ' + requisite['currency'].code + ' currency'
		}}
	</span>

	<table class="dictionary-table">
		<tr *ngIf="!!requisite['tab_name']">
			<td class="caption">Currency</td>
			<td class="text">
				{{ requisite['currency'].code }}
			</td>
		</tr>
		<ng-container *ngFor="let row of _rows">
			<tr *ngIf="requisite[row.prop]">
				<td class="caption">{{ row.label }}</td>
				<td class="text">
					{{ row.prop === 'minimum_deposit' ? requisite['currency'].symbol : '' }}{{ requisite[row.prop] }}
				</td>
			</tr>
		</ng-container>
	</table>
</ng-template>
