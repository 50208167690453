import { TranslateService } from '@ngx-translate/core';

import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';

import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { SLIDE } from '@bp/frontend/animations';

import { CheckoutSession, IPaymentMethod } from '@bp/checkout-frontend/models';
import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-payment-option',
	templateUrl: './payment-option.component.html',
	styleUrls: [ './payment-option.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class PaymentOptionComponent {

	protected readonly _appService = inject(AppService);

	private readonly __translateService = inject(TranslateService);

	private readonly __hostNotifier = inject(HostNotifierService);

	@Input() method!: IPaymentMethod;

	@HostBinding('class')
	protected get _hostClass(): string {
		return this.method.type.kebabCase;
	}

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _i8nPaywithCaptionKey = 'Use <b>PayWith<span class="sup">TM</span></b> to divide your purchase amount between multiple payment methods, in one checkout.';

	protected _onMethodClick(method: IPaymentMethod): void {
		this.__hostNotifier.clickOnPaymentMethod({
			provider: method.provider,
			type: method.typeInSnakeCase,
		});
	}

	protected _getCaption(method: IPaymentMethod): string {
		const providerCaption = <string> this.__translateService.instant(
			this.__getProviderI18nKey(method.provider),
		);

		if (providerCaption !== this.__getProviderI18nKey(method.provider))
			return providerCaption;

		const subtypeCaption = <string> this.__translateService.instant(
			this.__getPaymentOptionSubtypeI18nKey(method),
		);

		if (subtypeCaption !== this.__getPaymentOptionSubtypeI18nKey(method))
			return subtypeCaption;

		return this.__translateService.instant(this.__getPaymentOptionTypeI18nKey(method));
	}

	private __getProviderI18nKey(provider: string): string {
		return `page.${ this._session.payMode ? 'pay' : 'deposit' }-payment-methods.provider.${ provider }`;
	}

	private __getPaymentOptionTypeI18nKey(method: IPaymentMethod): string {
		return `page.${ this._session.payMode ? 'pay' : 'deposit' }-payment-methods.method.${ method.type }`;
	}

	private __getPaymentOptionSubtypeI18nKey(method: IPaymentMethod): string {
		return `${ this.__getPaymentOptionTypeI18nKey(method) }.subtype.${ method.subtype }`;
	}
}
