import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';

import { PaymentCardTokenPageComponent } from './pages';

const routes: Routes = [

	{
		matcher: matchRoutePathWithVersionPathSegment('payment-card-token'),
		component: PaymentCardTokenPageComponent,
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class PaymentCardTokenRoutingModule { }
