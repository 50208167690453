<div cdkTrapFocus cdkTrapFocusAutoCapture class="card-container">
	<bp-back-btn [disabled]="_isSubmitting$ | async" />

	<section cdkFocusInitial class="card payment-card">
		<bp-processing-stub [show]="_isSubmitting$ | async" />

		<span class="title">
			{{ 'page.' + (_session.payMode ? 'pay' : 'deposit') + '-crypto.title' | translate }}
		</span>

		<div class="card-content">
			<div class="fiat-amount-to-crypto">
				<bp-amount
					[config]="_paymentMethod"
					[control]="_amountCtrl"
					[amountLock]="_session.amountLock"
					[fiatCurrencies]="_fiatCurrencies"
					[currency]="_initialFiatCurrency"
					(currencyChange)="_fiatCurrency$.next($event)"
				/>

				<div class="as">
					{{ 'page.crypto.as' | translate }}
				</div>

				<div class="crypto-block">
					<mat-form-field
						*ngIf="_cryptoCurrencies.length > 1; else singleCryptoTpl"
						class="cryptocurrency-selector"
					>
						<mat-select
							panelClass="cryptocurrency-selector-overlay"
							[value]="_initialCryptoCurrency"
							(valueChange)="_cryptoCurrency$.next($event)"
						>
							<mat-select-trigger>
								<ng-container
									*ngTemplateOutlet="
										displayCryptoTpl;
										context: { '\$implicit': _cryptoCurrency$ | async }
									"
								/>
							</mat-select-trigger>

							<mat-option *ngFor="let cryptoCurrency of _cryptoCurrencies" [value]="cryptoCurrency">
								<ng-container
									*ngTemplateOutlet="displayCryptoTpl; context: { '\$implicit': cryptoCurrency }"
								/>
							</mat-option>
						</mat-select>
					</mat-form-field>

					<ng-template #singleCryptoTpl>
						<ng-container
							*ngTemplateOutlet="displayCryptoTpl; context: { '\$implicit': _cryptoCurrency$ | async }"
						/>
					</ng-template>
				</div>
			</div>

			<mat-form-field *ngIf="_emailCtrl" class="email-form-field">
				<mat-label>{{ 'email' | translate }}</mat-label>

				<input
					matInput
					type="email"
					autocomplete="email"
					inputmode="email"
					[formControl]="_emailCtrl"
					[required]="true"
				/>

				<mat-error bpFieldError />
			</mat-form-field>
		</div>

		<bp-page-note
			class="mt-2"
			forPage="crypto"
			[class.mt-2]="_appService.isNotBrightTheme"
			[paymentMethod]="_paymentMethod"
		/>

		<button
			class="btn btn-submit"
			type="submit"
			matRipple
			[class.card-float-button-position]="_appService.isNotBrightTheme"
			[matRippleDisabled]="_cannotSubmit"
			[disabled]="_cannotSubmit"
			(click)="_submit()"
		>
			{{ _session.depositButtonText || (_session.payMode ? 'btn.pay' : 'btn.deposit') | translate }}
			<bp-dots-wave *ngIf="_appService.isNotBrightTheme" [show]="_isSubmitting$ | async" />
		</button>
	</section>

	<ng-template #displayCryptoTpl let-crypto>
		<div class="crypto-option">
			<bp-img
				class="crypto-logo"
				[urls]="[_cryptoLogoAssets + crypto.code + '.svg', _cryptoLogoAssets + crypto.code + '.png']"
			/>
			<div>
				<div class="crypto-description">{{ crypto.description }}</div>
				<div class="crypto-code">{{ crypto.code }}</div>
			</div>
		</div>
	</ng-template>
</div>
