<section class="card status-card">
	<div class="header">
		<div class="heading">
			<div class="title">
				<ng-container *ngIf="_appService.openTab; else clickToProceedTpl">
					{{ 'page.card-proceed-on-open-tab.title' | translate }}
				</ng-container>

				<ng-template #clickToProceedTpl>
					{{ 'page.card-click-to-proceed-on-open-tab.title' | translate }}
				</ng-template>
			</div>
		</div>
	</div>

	<div class="buttons">
		<button
			*ngIf="_appService.openTab?.closed === false"
			class="btn right"
			type="button"
			[class.card-float-button-position]="_appService.isNotBrightTheme"
			(click)="_appService.openTab!.focus()"
		>
			{{ 'btn.continue' | translate }}
		</button>

		<!-- open tab can be null if popup blocker blocked opening of a new tab -->
		<button
			*ngIf="!_appService.openTab"
			class="btn right"
			type="button"
			[class.card-float-button-position]="_appService.isNotBrightTheme"
			(click)="onManualProceed()"
		>
			{{ 'btn.continue' | translate }}
		</button>
	</div>
</section>
