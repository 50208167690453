<div
	*ngIf="_appService.isBrightTheme && method.type.isPaywith"
	bpStopClickPropagation
	class="paywith-promotion mat-body-1"
	innerHTML="{{ _i8nPaywithCaptionKey | translate }}"
></div>

<div class="payment-option-box has-hover-caption" matRipple>
	<mat-progress-bar
		*ngIf="_appService.loadingPaymentMethodDependencies === method"
		color="primary"
		mode="indeterminate"
		@slide
	/>

	<div *ngIf="_appService.isBrightTheme && method.type.isPaywith" class="text-primary">
		{{ 'Pay using' | translate }}
	</div>

	<bp-payment-option-logo [paymentOption]="method" />

	<div class="hover-caption">
		<p>{{ _getCaption(method) }}</p>

		<button class="btn payment-option-cta-btn" type="button" (click)="_onMethodClick(method)">
			{{ (_session.payMode ? 'btn.pay' : 'btn.deposit') | translate }}
		</button>
	</div>
</div>

<div *ngIf="_appService.isBrightTheme && method.type.isPaywith" bpStopClickPropagation class="text-align-center">
	or
</div>
