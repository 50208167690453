import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { IRepositorySummary } from '@bp/shared/typings';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import {
	ConsoleLoggingState, ConsoleLoggingVerboseState, LocalFirebaseState, LocalBackendState, MockedBackendState
} from '@bp/frontend/services/persistent-state-keepers';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { SLIDE_HORIZONTAL } from '@bp/frontend/animations';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { SharedPipesModule } from '@bp/frontend/pipes';

import { DeveloperOverlayPanelExpansionState } from './developer-overlay-panel-expansion-state';

@Component({
	selector: 'bp-developer-overlay-panel',
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatLegacyButtonModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesMomentPipesModule,
		SharedFeaturesSvgIconsModule,

		SharedPipesModule,
	],
	templateUrl: './developer-overlay-panel.component.html',
	styleUrls: [ './developer-overlay-panel.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_HORIZONTAL ],
})
export class DeveloperOverlayPanelComponent {
	protected readonly _environment = inject(EnvironmentService);

	protected readonly _telemetryService = inject(TelemetryService);

	@Input() show? = false;

	protected readonly _ConsoleLoggingState = ConsoleLoggingState;

	protected readonly _ConsoleLoggingVerboseState = ConsoleLoggingVerboseState;

	protected readonly _LocalFirebaseState = LocalFirebaseState;

	protected readonly _LocalBackendState = LocalBackendState;

	protected readonly _MockedBackendState = MockedBackendState;

	protected readonly _DeveloperOverlayPanelExpansionState = DeveloperOverlayPanelExpansionState;

	protected get _repoSummary(): IRepositorySummary {
		return this._environment.repositorySummary;
	}

	protected _isPullRequestSummaryOpen = false;
}
