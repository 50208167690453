import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { ThreeDsProcessingRoutingModule } from './three-ds-processing-routing.module';
import { ThreeDsProcessingPageComponent } from './three-ds-processing-page';
import { ThreeDsProcessingResultCallbackPageComponent } from './three-ds-processing-result-callback-page';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ThreeDsProcessingRoutingModule,
	],
	declarations: [
		ThreeDsProcessingPageComponent,
		ThreeDsProcessingResultCallbackPageComponent,
	],
})
export class TheeDSProcessingModule { }
