import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';

import { PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { TextMaskConfig } from '@bp/shared/features/text-mask';
import { Validators, IValidatorFunc } from '@bp/shared/features/validation/models';

import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { InputComponent, SharedComponentsControlsModule } from '@bp/frontend/components/controls';

@Component({
	selector: 'bp-payment-card-expire-input',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		SharedComponentsControlsModule,
	],
	templateUrl: './payment-card-expire-input.component.html',
	styleUrls: [ './payment-card-expire-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardExpireInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardExpireInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardExpireInputComponent extends FormFieldControlComponent<string | null> {

	@ViewChild(InputComponent, { static: true })
	private readonly __input!: InputComponent;

	protected _mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		includeMaskInValue: true,
		maskOnFocus: false,
		guide: true,
		mask: (rawValue: string) => [
			// eslint-disable-next-line array-element-newline
			/\d/u, /\d/u, ' ', '/', ' ', /\d/u, /\d/u,
			...(rawValue.trim().length >= 7 ? [ /\d/u, /\d/u ] : []),
		],
	});

	protected override _validator: IValidatorFunc<string | null> | null = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		const { month, year } = PaymentCardsUtils.parseExpireDateString(value);

		if (Number.isNaN(month) || Number.isNaN(year) || month > 12 || month === 0)
			return { ccExpireDate: null };
		else if (PaymentCardsUtils.isExpired(value))
			return { ccExpired: null };

		return null;
	};

	focus(): void {
		this.__input.focus();
	}

}
