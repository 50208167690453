import { DTO, MapFromDTO, MetadataEntity, Required, Secret } from '@bp/shared/models/metadata';

export type ThreeDsProcessorAuthenticateResponseDTO = DTO<ThreeDsProcessorAuthenticateResponse>;

/**
 * https://docs.activeserver.cloud/en/api/auth/#/Auth%20API%20V2/BRW_AUTH_V2_1
 */
export class ThreeDsProcessorAuthenticateResponse extends MetadataEntity {

	@Secret()
	dsReferenceNumber!: string;

	@Secret()
	dsTransID!: string;

	@MapFromDTO()
	threeDSServerTransID!: string;

	@MapFromDTO()
	transStatus!: string;

	@MapFromDTO()
	transStatusReason?: string;

	@Required()
	messageVersion!: string;

	@MapFromDTO()
	resolvedCardScheme?: string;

	@MapFromDTO()
	eci?: string;

	@MapFromDTO()
	challengeUrl?: string;

	@MapFromDTO()
	resultMonUrl?: string;

	@Secret()
	acsChallengeMandated?: string;

	@Secret()
	acsDecConInd?: string;

	@Secret()
	acsReferenceNumber?: string;

	@Secret()
	acsTransID?: string;

	@MapFromDTO()
	amexDsTransID?: string;

	@Secret()
	authenticationType?: string;

	@Secret()
	authenticationValue?: string;

	@Secret()
	cardholderInfo?: string;

	@MapFromDTO()
	whiteListStatus?: string;

	@MapFromDTO()
	whiteListStatusSource?: string;

	constructor(dto: ThreeDsProcessorAuthenticateResponseDTO) {
		super(dto);
	}

}
