import { isEmpty, keys, mapValues, omit, omitBy, values } from 'lodash-es';
import { startWith } from 'rxjs';

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';

import { MODAL_OUTLET } from '@bp/shared/models/core';
import type { Dictionary } from '@bp/shared/typings';

import { RouterService } from '@bp/frontend/services/router';

export const DEFAULT_PAGE_TITLE_MASK = '{pageTitle}';

const TITLES_DELIMITER = ' – ';
const OUTLETS_DELIMITER = ' / ';

@Injectable({
	providedIn: 'root',
})
export class TitleService {

	private readonly _default = this._ngMeta.getTag('property="og:site_name"')?.content ?? '';

	private _previousTitledOutlets: string[] = [];

	/**
	 * Title with the mask substitutions if present
	 */
	private _rawTitle!: string;

	private _substitutionsReducers: Dictionary<(rawTitle: string) => string> = {};

	private _inited = false;

	constructor(
		private readonly _router: RouterService,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _ngTitle: Title,
		private readonly _ngMeta: Meta,
	) { }

	init() {
		if (this._inited)
			return;

		this._router.navigationEnd$
			.pipe(startWith(null))
			.subscribe(() => void this._updateTitle());

		this._inited = true;
	}

	setMaskValue(maskValue: Record<string, string>) {
		this._substitutionsReducers = {
			...this._substitutionsReducers,
			...mapValues(maskValue, (v, k) => (rawTitle: string) => rawTitle.replace(`{${ k }}`, v)),
		};

		this._substituteMasksAndSetTitle(this._rawTitle);
	}

	private _substituteMasksAndSetTitle(rawTitle: string = '') {
		this._ngTitle.setTitle(
			values(this._substitutionsReducers)
				.reduce(
					(title, substitute) => substitute(title),
					rawTitle,
				),
		);
	}

	private _updateTitle() {
		const outletsTitles = mapValues(
			this._harvestTitles(),
			titles => titles
				.reverse()
				.join(TITLES_DELIMITER),
		);
		const primaryTitle = outletsTitles[PRIMARY_OUTLET];
		const modalTitle = outletsTitles[MODAL_OUTLET];
		const rightDrawersTitle = values(omit(outletsTitles, PRIMARY_OUTLET, MODAL_OUTLET))
			.reverse()
			.join(OUTLETS_DELIMITER);

		this._rawTitle = (modalTitle
			? modalTitle + TITLES_DELIMITER
			: (rightDrawersTitle ? rightDrawersTitle + OUTLETS_DELIMITER : '')
			+ (primaryTitle ? primaryTitle + TITLES_DELIMITER : ''))
			+ this._default;

		this._substituteMasksAndSetTitle(this._rawTitle);
	}

	private _harvestTitles() {
		const walkedMap = new Map<ActivatedRouteSnapshot, number>();

		// We need the previous variable to restore the outlets titles order
		let outletsTitles = this._keysToObject(this._previousTitledOutlets);
		let current: ActivatedRouteSnapshot | null = this._activatedRoute.snapshot;
		let currentOutlet = PRIMARY_OUTLET;

		while (current) {
			if (current.outlet !== PRIMARY_OUTLET)
				currentOutlet = current.outlet;

			const hasWalked = walkedMap.has(current);

			if (!hasWalked && current.routeConfig?.data?.['title']) {
				const previousOutletTitles = <string[] | undefined> outletsTitles[currentOutlet] ?? [];

				outletsTitles[currentOutlet] = [ ...previousOutletTitles, <string> current.routeConfig.data['title'] ];
			}

			const toCheckChildIndex: number = (walkedMap.get(current) ?? 0) + 1;

			if (current.children.length > 0 && (!hasWalked || toCheckChildIndex < current.children.length)) {
				const next = hasWalked ? toCheckChildIndex : 0;

				walkedMap.set(current, next);

				current = current.children[next];
			} else
				current = current.parent ?? null;
		}

		outletsTitles = omitBy(outletsTitles, isEmpty);

		this._previousTitledOutlets = keys(outletsTitles);

		return outletsTitles;
	}

	private _keysToObject(value: string[] = []): Dictionary<string[]> {
		return Object.fromEntries(value.map(
			v => [ v, []],
		));
	}
}
