import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { HostNotifierService } from '@bp/frontend/domains/checkout/services';

import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

	protected readonly _appService = inject(AppService);

	protected readonly _hostNotifier = inject(HostNotifierService);

	closeModal(): void {
		if (this._appService.hasDeposited)
			this._hostNotifier.reloadHostPage();
		else
			this._hostNotifier.closeModal();
	}
}
