import { DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class EncryptCvvResponse extends MetadataEntity {

	@MapFromDTO()
	encryptedCvv!: string;

	constructor(dto: DTO<EncryptCvvResponse>) {
		super(dto);
	}

}
