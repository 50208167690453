<mat-select
	*ngIf="_languagesService.currentLanguage$ | async as currentLanguage"
	class="languages"
	panelClass="languages"
	[ngModel]="currentLanguage"
	(selectionChange)="_languagesService.use($event.value)"
>
	<mat-select-trigger>
		<button type="button" mat-button>
			<bp-country hideTooltip compact [src]="currentLanguage!.country" />
			<small class="uppercase ml-1">
				{{ currentLanguage!.displayCode ?? currentLanguage!.iso }}
			</small>
		</button>
	</mat-select-trigger>

	<mat-option *ngFor="let lng of _languagesService.languages" [value]="lng">
		<bp-country class="mr-3" hideTooltip compact [src]="lng.country" />
		{{ lng.name }}
	</mat-option>
</mat-select>
