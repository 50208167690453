import { DTO, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { ThreeDsProcessingAuthenticationStatus } from './three-ds-processing-authentication-status.enum';

export type ThreeDsProcessingAuthenticateResponseDTO = DTO<ThreeDsProcessingAuthenticateResponse>;

export class ThreeDsProcessingAuthenticateResponse extends MetadataEntity {

	@Mapper(ThreeDsProcessingAuthenticationStatus)
	status!: ThreeDsProcessingAuthenticationStatus;

	@MapFromDTO()
	challengeUrl?: string;

	@MapFromDTO()
	resultMonUrl?: string;

	constructor(dto: ThreeDsProcessingAuthenticateResponseDTO) {
		super(dto);
	}

}
