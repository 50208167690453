import fileSaver from 'file-saver';
import { without } from 'lodash-es';
import { last, takeUntil, tap } from 'rxjs/operators';

import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { MetadataQueryParamsBase } from '@bp/shared/models/metadata';

import { ProcessingFile } from '@bp/frontend/models/common';
import { BpError } from '@bp/frontend/models/core';
import { OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { toHttpParams } from '@bp/frontend/utilities/common';

const { saveAs } = fileSaver;

@Injectable({
	providedIn: 'root',
})
export class FileLoaderService {

	private readonly _telemetryService = inject(TelemetryService);

	private readonly _processingFiles$ = new OptionalBehaviorSubject<ProcessingFile[]>([]);

	processingFiles$ = this._processingFiles$.asObservable();

	constructor(private readonly _http: HttpClient) { }

	download(name: string, url: string, params: MetadataQueryParamsBase): ProcessingFile {
		const file = new ProcessingFile(name, 'download');

		this._addToProcessingFiles(file);

		this._http
			.request<Blob>(new HttpRequest('GET', url, {
				params: toHttpParams(params),
				responseType: 'blob',
				reportProgress: true,
			}))
			.pipe(
				tap(httpEvent => void this._updateProgress(httpEvent, file)),
				takeUntil(file.cancel$),
				last(), // Return last (completed) message to caller
			)
			.subscribe({
				next: httpEvent => httpEvent instanceof HttpResponse && httpEvent.body && void saveAs(httpEvent.body, file.name),
				error: (value: unknown) => void this._handleError(BpError.fromUnknown(value), file),
				complete: () => void this._complete(file),
			});

		return file;
	}

	private _complete(file: ProcessingFile) {
		file.finish();

		this._removeFromProcessingFiles(file);
	}

	private _handleError(value: BpError, file: ProcessingFile): void {
		this._telemetryService.captureError(value);

		file.error(value);

		this._removeFromProcessingFiles(file);
	}

	private _updateProgress(event: HttpEvent<any>, file: ProcessingFile) {
		if (event.type === HttpEventType.UploadProgress || event.type === HttpEventType.DownloadProgress)
			file.progress = Math.round(event.total ? event.loaded * 100 / event.total : 88);
	}

	private _addToProcessingFiles(file: ProcessingFile) {
		this._processingFiles$.next([ ...(this._processingFiles$.value ?? []), file ]);
	}

	private _removeFromProcessingFiles(file: ProcessingFile) {
		this._processingFiles$.next(without(this._processingFiles$.value, file));
	}

}
