import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Sensitive } from '@bp/shared/models/core';

import { SessionStorageService } from '@bp/frontend/services/storage';
import { EnvironmentService, PLATFORM } from '@bp/frontend/services/environment';

const IS_SUPPORT_AGENT_SESSION_KEY = 'is-support-agent-session';

@Injectable({ providedIn: 'root' })
export class SupportAgentDetectorService {

	private readonly __document = inject(DOCUMENT);

	private readonly __platform = inject(PLATFORM);

	private readonly __sessionStorageService = inject(SessionStorageService);

	readonly isSupportAgent: boolean;

	constructor() {
		this.isSupportAgent = this.__checkIfSupportAgentSession();

		if (this.isSupportAgent)
			this.__persistSupportAgentSessionInfo();
	}

	private __checkIfSupportAgentSession(): boolean {
		if (this.__sessionStorageService.get(IS_SUPPORT_AGENT_SESSION_KEY))
			return true;

		const isNavigatedToMerchantAdminFromConsole = this.__platform === 'merchant-admin'
			&& this.__document.referrer.startsWith(inject(EnvironmentService).bridgerAdminOrigin);

		const hasJwtQueryParam = this.__document.defaultView
			? (new URLSearchParams(this.__document.defaultView.location.search)).has(Sensitive.jwt)
			: false;

		return isNavigatedToMerchantAdminFromConsole && hasJwtQueryParam;
	}

	private __persistSupportAgentSessionInfo(): void {
		this.__sessionStorageService.set(IS_SUPPORT_AGENT_SESSION_KEY, true);
	}

}
