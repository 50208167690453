import { Pipe, PipeTransform } from '@angular/core';

import { State } from '@bp/shared/models/countries';

@Pipe({
	name: 'bpFilterStates',
})
export class FilterStatesPipe implements PipeTransform {

	transform(items: State[], sample: string): State[] {
		const formatted = sample.toLowerCase();

		return items.filter(index => index.name.toLowerCase().includes(formatted));
	}

}
