import { range } from 'lodash-es';

import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';
import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';
import { TextMask, TextMaskConfig } from '@bp/shared/features/text-mask';

import { FormFieldControlComponent } from '@bp/frontend/components/core';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';
import { InputComponent, SharedComponentsControlsModule } from '@bp/frontend/components/controls';

const DEFAULT_CVV_LENGTH = 3;

@Component({
	selector: 'bp-payment-card-cvv-input',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		SharedComponentsControlsModule,
	],
	templateUrl: './payment-card-cvv-input.component.html',
	styleUrls: [ './payment-card-cvv-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardCvvInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardCvvInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardCvvInputComponent extends FormFieldControlComponent<string | null> implements OnChanges {

	@Input()
	paymentCardBrand?: PaymentCardBrand | null;

	@Input()
	cvvLength?: number | null;

	@Input()

	get placeholderChar(): string {
		return this.__placeholderChar;
	}

	set placeholderChar(value: string | null) {
		this.__placeholderChar = value ?? TextMaskConfig.whitespace;
	}

	private __placeholderChar = TextMaskConfig.whitespace;

	@ViewChild(InputComponent, { static: true })
	private readonly __input!: InputComponent;

	@Input()
	useMaskedChars = true;

	mask!: TextMaskConfig | null;

	private get __requiredCvvLength(): number {
		return this.cvvLength ?? this.paymentCardBrand?.scheme.code.length ?? DEFAULT_CVV_LENGTH;
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.paymentCardBrand)
			this.__buildMaskAccordingToPaymentCardBrand();
	}

	focus(): void {
		this.__input.focus();
	}

	private __buildMaskAccordingToPaymentCardBrand(): void {
		const cvvMask: TextMask = range(this.__requiredCvvLength).map(() => /\d/u);

		this.mask = new TextMaskConfig({
			placeholderChar: this.placeholderChar,
			placeholderFromMask: false,
			maskOnFocus: false,
			guide: false,
			mask: () => cvvMask,
		});

		this.validatorOnChange();
	}

	protected override _validator: IValidatorFunc<string | null> | null = ({ value }) => {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		if (this.__requiredCvvLength === value.length)
			return null;

		return { ccVerificationCode: null };
	};
}
