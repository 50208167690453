<bp-back-btn *ngIf="_canGoHome" />

<section class="card status-card error-card">
	<div class="header">
		<div class="heading">
			<div class="title">
				<ng-container
					*ngIf="
						_error && !_error.isNoResponse && !_error?.isInternalServerError && _error.statusText;
						else defaultErrorTitleTpl
					"
				>
					{{ (_error.status ? _error.status + ': ' : '') | translate }}{{ _error.statusText | translate }}
				</ng-container>

				<ng-template #defaultErrorTitleTpl>
					<div class="text-align-center pre-wrap">
						{{ _publicErrorText ?? 'page.error.default-title' | translate }}
					</div>
				</ng-template>
			</div>
		</div>
	</div>

	<div *ngIf="_error?.isHttpError && !_error?.isInternalServerError" class="card-content">
		<ng-container *ngFor="let msg of _error?.messages">
			<div *ngIf="msg.message !== _error?.statusText || !!msg.type" class="message">
				<div *ngIf="msg.message !== _error?.statusText" class="text">
					{{ msg.message | translate }}
				</div>

				<div *ngIf="msg.type" class="type">
					{{ msg.type | translate }}
				</div>
			</div>
		</ng-container>
	</div>

	<button
		*ngIf="_appService.embedded?.isMerchantAdmin"
		class="btn right card-float-button-position"
		type="button"
		(click)="_hostNotifier.requestNewOrderId()"
	>
		{{ 'btn.home' | translate }}
	</button>
</section>
