import { nth, sumBy, partition } from 'lodash-es';

import { ChangeDetectorRef, inject, Injectable } from '@angular/core';

import {
	CheckoutSession, IPaymentMethod, PaymentOptionInstance, PaymentOptionInstanceStatus, TransactionInfo
} from '@bp/checkout-frontend/models';

import { SessionApiService } from './api';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentOptionInstancesManager {

	private readonly _sessionApi = inject(SessionApiService);

	appService!: AppService;

	get session(): CheckoutSession {
		return this.appService.session!;
	}

	instances: PaymentOptionInstance[] = [];

	get sessionAmount(): number {
		return this.session.$amount;
	}

	get hasNoInstancesSelected(): boolean {
		return this.instances.length === 0;
	}

	get hasInstancesSelected(): boolean {
		return !this.hasNoInstancesSelected;
	}

	get total(): number {
		return this.instances.length;
	}

	get isCompleted(): boolean {
		return this.instances.length > 0 && this.instances.every(v => v.isSuccess);
	}

	get isPartiallyCompleted(): boolean {
		return !this.isCompleted && this.instances.some(v => v.isSuccess);
	}

	get isActiveInstanceLast(): boolean {
		return this.activeInstance?.index === (this.instances.length - 1);
	}

	instancesAmountIsInvalid = true;

	instancesAmount = 0;

	paidAmount = 0;

	activeInstance?: PaymentOptionInstance;

	noAttemptsMade = true;

	private __cdrs: ChangeDetectorRef[] = [];

	init(appService: AppService): void {
		this.appService = appService;

		this.instances = this.session.paymentMethodInstances ?? [];

		this.noAttemptsMade = !this.instances
			.some(instance => instance.isSuccess || instance.isPending || instance.isError);

		this.updateInstances();

		this.__whenIsCompletedRedirectToSuccessPage();

		this.__whenAnyPendingInstanceRedirectToPendingPage();
	}

	setCdr(cdr: ChangeDetectorRef): void {
		this.__cdrs.push(cdr);
	}

	removeCdr(cdr: ChangeDetectorRef): void {
		this.__cdrs = this.__cdrs.filter(v => v !== cdr);
	}

	addPaymentOptionInstance(option: IPaymentMethod): void {
		this.instances.push(new PaymentOptionInstance({
			index: this.instances.length,
			paymentOption: option,
			status: PaymentOptionInstanceStatus.planned,
		}));

		this.updateInstances();

		this.__savePaymentMethodInstancesState();
	}

	removePaymentOptionInstance(optionInstanceIndex: number): void {
		this.instances = this.instances.filter((_, index) => index !== optionInstanceIndex);

		this.updateInstances();

		this.__savePaymentMethodInstancesState();
	}

	updateInstances(): void {
		const [ successInstances, restInstances ] = partition(this.instances, v => v.isSuccess);

		this.paidAmount = sumBy(successInstances, 'amount');
		const leftAmountToPay = this.sessionAmount - this.paidAmount;

		this.instances = [
			...successInstances,
			...restInstances
				.map((v, index) => {
					const fractionlessInstanceAmount = Math.floor(leftAmountToPay / restInstances.length);

					let instanceAmount = index === 0
						? Math.abs(fractionlessInstanceAmount * (restInstances.length - 1) - leftAmountToPay)
						: fractionlessInstanceAmount;

					instanceAmount = Number.isInteger(instanceAmount) ? instanceAmount : Number(instanceAmount.toFixed(2));

					return new PaymentOptionInstance({
						...v,
						index,
						amount: instanceAmount,
					});
				}),
		];

		this.instancesAmount = sumBy(this.instances, 'amount');

		this.instancesAmountIsInvalid = this.instancesAmount < this.sessionAmount;

		this.instances.forEach((v, index) => {
			v.index = index;
		});

		this.__detectChanges();
	}

	continue(): void {
		this.updateInstances();

		const hasActiveInstanceOnContinue = !!this.activeInstance;

		const nextInstance = this.instances.find(v => v.status === PaymentOptionInstanceStatus.error || v.status === PaymentOptionInstanceStatus.planned);

		this.activeInstance = nextInstance;

		this.__detectChanges();

		if (hasActiveInstanceOnContinue && nextInstance?.isError && nextInstance.hasSkippedForm || !nextInstance)
			this.goToPlanManagement();
		else
			this.appService.navigateToPaymentOption(nextInstance.paymentOption);
	}

	markAsPending(): void {
		this.activeInstance!.status = PaymentOptionInstanceStatus.pending;

		this.__savePaymentMethodInstancesState();

		this.__detectChanges();
	}

	successContinue(trx: TransactionInfo): void {
		this.noAttemptsMade = false;

		this.activeInstance!.status = PaymentOptionInstanceStatus.success;

		this.activeInstance!.transaction = trx;

		this.activeInstance!.processingDate = trx.processing_date;

		this.__savePaymentMethodInstancesState();

		this.__detectChanges();

		this.appService.navigate([ '/status/card-success' ]);
	}

	handleDeclinedTransaction(): void {
		this.noAttemptsMade = false;

		this.activeInstance!.status = PaymentOptionInstanceStatus.error;

		this.__detectChanges();

		this.__savePaymentMethodInstancesState();

		this.appService.navigate([ '/status/card-declined' ]);
	}

	next(): void {
		const nextPlanned = nth(this.instances, this.activeInstance!.index + 1);

		this.activeInstance = nextPlanned;

		this.__detectChanges();
	}

	prev(): void {
		const previousPlanned = nth(this.instances, this.activeInstance!.index - 1);

		this.activeInstance = previousPlanned;

		this.__detectChanges();
	}

	goToPlanManagement(): void {
		this.activeInstance = undefined;

		this.appService.navigate([ '/payments/paywith' ]);
	}

	private __whenIsCompletedRedirectToSuccessPage(): void {
		if (this.isCompleted)
			this.appService.navigate([ '/status/card-success' ]);
	}

	private __detectChanges(): void {
		this.__cdrs.forEach(v => void v.detectChanges());
	}

	private __savePaymentMethodInstancesState(): void {
		this._sessionApi
			.savePaymentMethodInstances(this.instances, this.session.id)
			.subscribe();
	}

	private __whenAnyPendingInstanceRedirectToPendingPage(): void {
		const pendingInstance = this.instances.find(v => v.isPending);

		if (pendingInstance) {
			this.activeInstance = pendingInstance;

			this.appService.navigate([
				'/status',
				this.appService.session!.id,
				pendingInstance.transactionId,
			]);
		}
	}
}
