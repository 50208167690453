import { TranslateModule } from '@ngx-translate/core';
import { noop } from 'lodash-es';

import { NgModule, ModuleWithProviders, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedPipesModule, TranslatePipeProviderService } from '@bp/frontend/pipes';
import {
	TranslatedErrorsTextsProviderService, HostNotificationsListenerService, TranslationsProviderService
} from '@bp/frontend/domains/checkout/services';
import { ErrorsTextsProviderService, SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { FitTextDirective } from '@bp/frontend/directives/fit-text';
import { TextMaskDirective } from '@bp/frontend/features/text-mask';
import { IntlCompactNumberPipe, IntlCurrencyNamePipe } from '@bp/frontend/domains/checkout/pipes';
import { ViewportOverflownObserverDirective } from '@bp/frontend/directives/viewport-overflow-observer';
import { SharedFeaturesCopyToClipboardModule } from '@bp/frontend/features/copy-to-clipboard';
import { SharedFeaturesMediaModule } from '@bp/frontend/features/media';
import { SharedFeaturesPluralizeModule } from '@bp/frontend/features/pluralize';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';

import {
	DotsWaveComponent, ProcessingStubComponent, BackBtnComponent, CountdownComponent, PspsDeviceFingerprintCollectorComponent, PaymentOptionLogoComponent, PageNoteComponent, SecurePaymentStaticTextComponent, PaymentOptionInstancesBreakdownComponent, PaymentSummaryComponent, PaymentInstanceOutOfTotalTextComponent, OrderSummaryComponent
} from './components';
import { ClearServerErrorOnFormControlChangeDirective } from './directives';
import { FilterStatesPipe, MultiplyPipe, CountryPersonalIdNamePipe } from './pipes';
import { MaterialsModule } from './materials.module';
import { AppErrorHandler } from './providers';

const modules = [
	CommonModule,
	MaterialsModule,
	FormsModule,
	ReactiveFormsModule,
	TranslateModule,
	SharedPipesModule,
	SharedFeaturesCopyToClipboardModule,
	SharedComponentsCoreModule,
	SharedFeaturesValidationModule,
	SharedFeaturesMediaModule,
	SharedFeaturesPluralizeModule,
];

const exposed = [
	DotsWaveComponent,
	ProcessingStubComponent,
	BackBtnComponent,
	CountdownComponent,
	PaymentOptionLogoComponent,
	PspsDeviceFingerprintCollectorComponent,
	PageNoteComponent,
	SecurePaymentStaticTextComponent,

	ClearServerErrorOnFormControlChangeDirective,

	FilterStatesPipe,
	MultiplyPipe,
	CountryPersonalIdNamePipe,

	PaymentOptionInstancesBreakdownComponent,
	PaymentSummaryComponent,
	PaymentInstanceOutOfTotalTextComponent,
	OrderSummaryComponent,
];

const standalone = [
	TextMaskDirective,
	ViewportOverflownObserverDirective,

	FitTextDirective,
	IntlCurrencyNamePipe,
	IntlCompactNumberPipe,
];

@NgModule({
	imports: [
		modules,
		standalone,
	],
	declarations: exposed,
	exports: [ exposed, modules, standalone ],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [
				{ provide: ErrorHandler, useClass: AppErrorHandler },
				{ provide: ErrorsTextsProviderService, useClass: TranslatedErrorsTextsProviderService },
				{ provide: TranslatePipeProviderService, useClass: TranslationsProviderService },
				{
					provide: APP_INITIALIZER,
					useFactory: () => noop,
					deps: [ HostNotificationsListenerService ],
					multi: true,
				},
			],
		};
	}
}
