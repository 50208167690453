import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, inject } from '@angular/core';

import { AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-payment-summary',
	templateUrl: './payment-summary.component.html',
	styleUrls: [ './payment-summary.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentSummaryComponent implements OnDestroy {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	private readonly _appService = inject(AppService);

	private readonly __cdr = inject(ChangeDetectorRef);

	@Input() planning!: boolean;

	protected get _session() {
		return this._appService.session!;
	}

	constructor() {
		this._paymentOptionInstancesManager.setCdr(this.__cdr);
	}

	ngOnDestroy(): void {
		this._paymentOptionInstancesManager.removeCdr(this.__cdr);
	}
}
