import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { IconSize } from '@bp/shared/models/core';

@Component({
	selector: 'bp-copy-to-clipboard-btn[value]',
	templateUrl: './copy-to-clipboard-btn.component.html',
	styleUrls: [ './copy-to-clipboard-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardBtnComponent {

	@Input() value!: string;

	@Input() iconSize: IconSize = 'md';

	@Input() color?: ThemePalette;

	@Input() copyText?: string | null = 'Copy';

}
