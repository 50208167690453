<h1 *ngIf="_environment.isNotProduction" class="text-align-center">Bridgerpay 3DS Engine</h1>

<div
	*ngIf="!_appService.isNestedInsideAnotherCheckout && _isShowProcessingStub"
	class="processing-stub-wrapper"
	@fadeOut
>
	<div class="card transaction-processing-card status-card">
		<bp-processing-stub [show]="true" [secure]="true" />
	</div>
</div>
