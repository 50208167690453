<table class="dictionary-table">
	<ng-container *ngFor="let entry of src | entries">
		<tr *ngIf="entry[1]">
			<td class="caption">{{ entry[0] | titleCase }}</td>
			<td class="text">
				{{ entry[1] }}
			</td>
		</tr>
	</ng-container>
</table>
