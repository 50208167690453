import { of, map, startWith, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';

import { inject, Injectable } from '@angular/core';

import { TranslatePipeProviderService } from '@bp/frontend/pipes';

@Injectable({ providedIn: 'root' })
export class TranslationsProviderService implements TranslatePipeProviderService {

	private readonly __translateService = inject(TranslateService);

	get(query: string, ..._args: any[]): Observable<string> {
		if (isNil(query) || query.length === 0)
			return of('');

		return this.__translateService.onLangChange.pipe(
			startWith(null),
			map(() => this.__translateService.instant(query)),
		);
	}

}
