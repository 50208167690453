import { ErrorHandler, inject, NgZone } from '@angular/core';

import { BpError } from '@bp/frontend/models/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { AppService } from './app.service';

export class AppErrorHandler implements ErrorHandler {

	private readonly __appService = inject(AppService);

	private readonly __zone = inject(NgZone);

	handleError(error: unknown): void {
		TelemetryService.appErrorHandler(error);

		this.__appService.setError(BpError.fromUnknown(error));

		this.__zone.run(() => void this.__appService.navigate([ '/error' ]));
	}

}
