import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { inject, Injectable } from '@angular/core';

import { CheckoutLanguages, CheckoutLanguage } from '@bp/frontend/domains/checkout/core';
import { pending } from '@bp/frontend/rxjs';

@Injectable({
	providedIn: 'root',
})
export class LanguagesService {

	private readonly __translate = inject(TranslateService);

	languages = CheckoutLanguages.list;

	currenciesDisplayNamesPerLanguageMap: Map<string, Intl.DisplayNames> | null = this.__createCurrenciesDisplayNamesPerLanguageMap();

	private readonly __currentLanguage$ = new BehaviorSubject<CheckoutLanguage | null>(null);

	currentLanguage$ = this.__currentLanguage$.asObservable();

	get currentLanguage(): CheckoutLanguage | null {
		return this.__currentLanguage$.value;
	}

	private readonly __loading$ = new BehaviorSubject<boolean>(false);

	loading$ = this.__loading$.asObservable();

	constructor() {
		// this language will be used as a fallback when a translation isn't found in the current language
		this.__translate.setDefaultLang('en');

		this.__whenTranslateServiceLangChangeSetCurrentLanguage();
	}

	getSupportedBrowserLanguage(): CheckoutLanguage {
		const browserLangIso = this.__translate.getBrowserLang();

		return CheckoutLanguages.findByIso(
			browserLangIso && this.isSupportedLangIso(browserLangIso) ? browserLangIso : 'en',
		)!;
	}

	isSupportedLangIso(lang: string): boolean {
		return CheckoutLanguages.includesIso(lang);
	}

	use(lang: CheckoutLanguage): Observable<void> {
		if (this.currentLanguage === lang)
			return EMPTY;

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		return this.__translate
			.use(lang.iso)
			.pipe(pending(this.__loading$));
	}

	private __whenTranslateServiceLangChangeSetCurrentLanguage(): void {
		this.__translate.onLangChange
			.pipe(map(({ lang }) => CheckoutLanguages.findByIso(lang)))
			.subscribe(lang => {
				if (lang)
					document.body.dir = lang.isRightToLeft ? 'rtl' : 'ltr';

				this.__currentLanguage$.next(lang);
			});
	}

	private __createCurrenciesDisplayNamesPerLanguageMap(): Map<string, Intl.DisplayNames> | null {
		try {
			return new Map(this.languages.map(({ iso }) => [
				iso,
				new Intl.DisplayNames(iso, { type: 'currency' }),
			]));
		} catch {
			return null;
		}
	}

}
