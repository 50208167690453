<button
	*ngIf="_session?.isPayWithCheckout; else defaultTpl"
	mat-button
	class="back-button"
	type="button"
	[disabled]="!!disabled"
	(click)="_paymentOptionsInstancesManager.goToPlanManagement()"
>
	{{ 'back' | translate }}
</button>

<ng-template #defaultTpl>
	<button
		*ngIf="url || (_appService.showPaymentMethodsPage$ | async)"
		mat-button
		class="back-button"
		type="button"
		[disabled]="!!disabled"
		(click)="_appService.navigateByUrl(url ? url : '/')"
	>
		{{ text || 'back' | translate }}
	</button>
</ng-template>
