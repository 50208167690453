<div *ngIf="(_appService.session$ | async)?.orderSummary as orderSummary">
	<h1>Order details</h1>

	<div class="details">
		<div *ngFor="let entry of orderSummary.$$details | entries" class="entry">
			<div class="caption">{{ entry[0] | upperFirst }}</div>
			<div class="text">{{ entry[1] }}</div>
		</div>
	</div>

	<div *ngIf="orderSummary.items as items" class="items">
		<div *ngFor="let item of items" class="item">
			<div class="item-image">
				<img alt="" srcset="" [src]="item.image_url" />
			</div>

			<div class="item-summary">
				<div class="item-name">
					<a *ngIf="item.url; else justNameTpl" target="_blank" rel="noreferrer" [href]="item.url">
						{{ item.name }}
					</a>
					<ng-template #justNameTpl>
						{{ item.name }}
					</ng-template>
				</div>
				<div class="item-price">
					<div class="quantity">x{{ item.quantity }}</div>
					<div class="price">{{ item.price | currency: _session!.currency.code : 'symbol' : '1.0-2' }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
