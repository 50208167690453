@if (renderVersion) {
	<span class="frontend-version" bpTooltip="Frontend">
		@if (_environment.isDeployed) {
			v{{
				_environment.isProduction && !_environment.isBackoffice
					? _appVersion.release
					: _appVersion.releaseTitle + (_environment.isBackoffice ? '' : '-' + _environment.name)
			}}
		}
		@if (_environment.isLocal) {
			v0.0.0-localhost
		}
	</span>

	@if (_environment.isNotProduction) {
		@for (
			repositorySummaryPerDeployment of _environment.repositorySummariesPerDeployment$ | async | keyvalue;
			track repositorySummaryPerDeployment.key
		) {
			@if (repositorySummaryPerDeployment.key.isBackend) {
				<span class="backend-version" [bpTooltip]="repositorySummaryPerDeployment.key.displayName">
					/ v{{ repositorySummaryPerDeployment.value.releaseTitle }}
				</span>
			}
		}
	}
}
