import { Component, Input, ChangeDetectionStrategy, inject, OnDestroy } from '@angular/core';

import { ExtendablePages, ExternalExtensionService } from '@bp/frontend/domains/checkout/services';
import { FADE } from '@bp/frontend/animations';
import { Destroyable } from '@bp/frontend/models/common';

import { IPaymentMethod } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-page-note',
	templateUrl: './page-note.component.html',
	styleUrls: [ './page-note.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class PageNoteComponent extends Destroyable implements OnDestroy {

	protected readonly _externalExtensionService = inject(ExternalExtensionService);

	@Input()
	paymentMethod: IPaymentMethod | null = null;

	@Input()
	forPage: ExtendablePages | null = null;

	@Input()
	note: string | null = null;

	ngOnDestroy(): void {
		if (this.forPage) {
			this._externalExtensionService.setPagesExternalExtension({
				deprecatedNote: null,
			});
		}
	}

}
