import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';

import { BloxPageComponent, BloxControllerPageComponent } from './pages';
import { BloxControllerResolver } from './services';

const routes: Routes = [

	{
		matcher: matchRoutePathWithVersionPathSegment('blox'),
		children: [
			{
				path: 'controller/:controllerId',
				resolve: {
					session: BloxControllerResolver,
				},
				component: BloxControllerPageComponent,
			},
			{
				path: ':controllerId/:bloxType/:bloxId',
				component: BloxPageComponent,
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class BloxRoutingModule { }
