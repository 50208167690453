import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SessionResolver } from '@bp/checkout-frontend/providers';
import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';

import { HomePageComponent } from './pages';

const routes: Routes = [

	{
		matcher: matchRoutePathWithVersionPathSegment(''),
		component: HomePageComponent,
		resolve: {
			session: SessionResolver,
		},
		data: {
			cashierFirstInitialization: true,
		},
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class HomeRoutingModule { }
