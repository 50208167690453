import { interval } from 'rxjs';
import { map, takeWhile, startWith } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { OnDestroy, Directive, inject, ChangeDetectorRef } from '@angular/core';

import { buildUrl } from '@bp/shared/utilities/core';

import { Destroyable } from '@bp/frontend/models/common';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { CheckoutPaymentResult } from '@bp/frontend/domains/checkout/core';
import { BpError } from '@bp/frontend/models/core';

import { CheckoutSession, TransactionInfo } from '../models';
import { AppService, PaymentOptionInstancesManager } from '../providers';

@Directive()
export abstract class PspResultPageBaseComponent<TExpectedTransaction extends(TransactionInfo | null)> extends Destroyable implements OnDestroy {

	protected _appService = inject(AppService);

	protected _activatedRoute = inject(ActivatedRoute);

	protected _hostNotifier = inject(HostNotifierService);

	protected _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	protected _cdr = inject(ChangeDetectorRef);

	protected abstract _redirectUrl: string | null;

	protected _timeoutBeforeRedirect = 7;

	protected _timeoutBeforeRedirectMs = this._timeoutBeforeRedirect * 1000;

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected get _error(): BpError | null {
		return this._appService.error instanceof BpError ? this._appService.error : null;
	}

	// if we were redirected to the pending page before the deposit request we don't have the transaction value
	protected _transaction = <TExpectedTransaction> (this._activatedRoute.snapshot.data['transaction'] ?? this._appService.transaction);

	protected _countDown$ = interval(1000)
		.pipe(
			startWith(-1),
			map(passed => this._timeoutBeforeRedirect - (++passed)),
			takeWhile(it => it >= 0),
		);

	protected get _showProcessingStub(): boolean {
		return this._canContinue && this._session.showRedirectMessageOnFinalStatusPage || this._session.showProcessingMessageOnFinalStatusPage;
	}

	protected get _canContinue(): boolean {
		return !!this._appService.isMerchantAdmin
			|| (this._session.isPayWithCheckout ? this._paymentOptionInstancesManager.isCompleted : true) && !!this._redirectUrl
			|| this._appService.isTab;
	}

	private __setTimeoutTaskId?: ReturnType<typeof setTimeout>;

	constructor() {
		super();

		if (!this._session.isPayWithCheckout || this._paymentOptionInstancesManager.isCompleted)
			this._appService.hasDoneDeposit();

		this._appService.markAppReady();

		document.documentElement.classList.add('result-page');
	}

	ngOnDestroy(): void {
		clearTimeout(this.__setTimeoutTaskId);

		document.documentElement.classList.remove('result-page');
	}

	protected _originalOrderKeyValueComparator = (): number => 0;

	protected _whenCanContinueScheduleContinueExecution(): void {
		if (this._canContinue && !location.hostname.includes('localhost')) {
			if (this._session.showRedirectMessageOnFinalStatusPage)
				this._continue();
			else {
				this.__setTimeoutTaskId = setTimeout(
					() => void this._continue(),
					this._timeoutBeforeRedirectMs,
				);
			}
		}
	}

	protected _continue(): void {
		clearTimeout(this.__setTimeoutTaskId);

		if (this._appService.isMerchantAdmin)
			this._hostNotifier.requestNewOrderId();
		else if (this._redirectUrl)
			this._redirect();
		else if (this._appService.isTab)
			this._appService.changeWindowLocationTo(this._appService.embedded!.backUrl!);
	}

	protected _redirect(): void {
		this._appService.redirectHostTo(this._redirectUrl!);
	}

	protected _navigateByUrl(url: string): void {
		this._appService.removeAlertBeforeUnload();

		this._appService.navigateByUrl(url);
	}

	protected _buildRedirectUrl(url: string, status: string): string {
		return buildUrl(url, {
			orderId: this._session.orderId,
			sessionId: this._session.id,
			status,
		});
	}

	protected _notifyHostAboutPaymentStatus(type: CheckoutPaymentResult['type']): void {
		this._hostNotifier.payment({
			...this._transaction?.summary,
			type,
		});
	}

	protected _whenPaywithCanContinueScheduleContinueExecution(): void {

		if (this._session.isPayWithCheckout && !this._paymentOptionInstancesManager.isCompleted)
			setTimeout(() => void this._paymentOptionInstancesManager.continue(), 4000);
	}
}
