import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bpMultiply',
})
export class MultiplyPipe implements PipeTransform {
	transform(value: number | string | null, rate: number): number | null {
		const number = Number(value);

		return Number.isNaN(number) ? null : (number * rate);
	}
}
