
import { Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { ThreeDsProcessor } from './three-ds-processor';

export class ThreeDsProcessorsDeleteRequest extends MetadataEntity {

	@Mapper(ThreeDsProcessor)
	processors!: ThreeDsProcessor[];

}
