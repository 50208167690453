import { keys } from 'lodash-es';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import type { Dictionary } from '@bp/shared/typings';

import { filterPresent } from '@bp/frontend/rxjs';
import { RouterService } from '@bp/frontend/services/router';

@Injectable({
	providedIn: 'root',
})
export class ObsoleteUrlsRerouterService {

	rerouteUrlsPerAppMap: Dictionary<Dictionary<string> | undefined> = {
		'merchant-admin': {
			'/login': '/intro/login',
		},
	};

	rerouteUrlsMap: Dictionary<string | undefined> = {};

	constructor(private readonly _routing: RouterService) {
		const appName = keys(this.rerouteUrlsPerAppMap)
			.find(v => location.hostname.includes(v));

		this.rerouteUrlsMap = this.rerouteUrlsPerAppMap[appName!] ?? {};
	}

	init() {
		this._routing.navigationStart$
			.pipe(
				map(v => this.rerouteUrlsMap[v.url]),
				filterPresent,
			)
			.subscribe(newUrl => void this._routing.ngRouter.navigateByUrl(newUrl));
	}

}
