import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentCardCvvInputComponent } from '@bp/frontend/components/payment-card/cvv-input';
import { PaymentCardExpireInputComponent } from '@bp/frontend/components/payment-card/expire-input';
import { PaymentCardHolderInputComponent } from '@bp/frontend/components/payment-card/holder-input';
import { PaymentCardNumberInputComponent } from '@bp/frontend/components/payment-card/number-input';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { PaymentCardTokenPageComponent } from './pages';
import { PaymentCardTokenRoutingModule } from './payment-card-token-routing.module';

const EXPOSED = [
	PaymentCardTokenPageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		SharedComponentsCoreModule,
		SharedDirectivesCoreModule,
		PaymentCardTokenRoutingModule,

		PaymentCardCvvInputComponent,
		PaymentCardExpireInputComponent,
		PaymentCardHolderInputComponent,
		PaymentCardNumberInputComponent,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class PaymentCardTokenModule { }
