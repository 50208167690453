import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { AppService } from '@bp/checkout-frontend/providers';
import { CheckoutSession } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: [ './order-summary.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent {

	protected readonly _appService = inject(AppService);

	protected _originalOrderKeyValueComparator = (): number => 0;

	protected get _session(): CheckoutSession | null {
		return this._appService.session;
	}

}
