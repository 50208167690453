<bp-input
	nativeAutocomplete="cc-csc"
	inputmode="numeric"
	[type]="useMaskedChars ? 'password' : 'text'"
	[name]="name"
	[label]="paymentCardBrand?.scheme?.code?.displayName ?? label"
	[color]="color"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[required]="!!required"
	[placeholder]="placeholder"
	[hideClearButton]="hideClearButton"
	[hint]="hint"
	[pending]="pending"
	[mask]="mask"
	[formControl]="_internalControl"
	[isSecret]="true"
/>
