import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-blox-controller-page',
	templateUrl: './blox-controller-page.component.html',
	styleUrls: [ './blox-controller-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BloxControllerPageComponent {

}
