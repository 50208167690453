import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class WindowService {

	isEmbedded = this._isEmbedded();

	constructor(
		@Inject(DOCUMENT)
		@Optional()
		private readonly _document?: Document,
	) {
		this._tryAddEmbeddedClassToDocument();
	}

	whenEmbeddedDontPolluteHostPageBrowsersHistory(): void {
		if (!this.isEmbedded)
			return;

		this.disableBrowserHistory();
	}

	/**
	 * This will render the browsers back button navigation unusable
	 */
	disableBrowserHistory(): void {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		globalThis.history.pushState = () => { };

		// eslint-disable-next-line @typescript-eslint/no-empty-function
		globalThis.history.replaceState = () => { };
	}

	private _isEmbedded(): boolean {
		// Browsers can block access to globalThis.top due to same origin policy
		try {
			return globalThis.self !== globalThis.top;
		} catch {
			return true;
		}
	}

	private _tryAddEmbeddedClassToDocument(): void {
		if (!this._document || !this.isEmbedded)
			return;

		this._document.documentElement.classList.add('embedded');
	}

}
