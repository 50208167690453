import { Component, Input, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-back-btn',
	templateUrl: './back-btn.component.html',
	styleUrls: [ './back-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackBtnComponent {

	protected readonly _paymentOptionsInstancesManager = inject(PaymentOptionInstancesManager);

	protected readonly _appService = inject(AppService);

	private readonly _activatedRoute = inject(ActivatedRoute);

	@Input()
	disabled!: boolean | null;

	@Input()
	url!: string;

	@Input()
	text!: string;

	protected get _session() {
		return this._appService.session;
	}

	constructor() {
		// eslint-disable-next-line rxjs-angular/prefer-takeuntil
		this._activatedRoute.params.subscribe(({ backBtn, backBtnUrl }) => {
			this.url = <string>backBtnUrl;

			this.text = <string>backBtn;
		});
	}
}
