import { Moment } from 'moment';

import { DTO, Default, MapFromDTO, Mapper, MetadataEntity, Required, momentMapper } from '@bp/shared/models/metadata';

import { PaymentCardTokenType } from './payment-card-token-type.enum';
import { MaskedPaymentCard } from './masked-payment-card';
import { PaymentCardTokenStatus } from './payment-card-token-status';
import { PaymentCardTokenSneakPeekAuditEventVm } from './payment-card-token-sneak-peek-audit-event.vm';
import { MigrationSource } from './migration-source';

export class PaymentCardToken extends MetadataEntity {

	@Mapper(PaymentCardTokenType)
	@Default(PaymentCardTokenType.multiUse)
	type!: PaymentCardTokenType;

	@Required()
	token!: string;

	@MapFromDTO()
	cardChecksum!: string;

	@Mapper(MaskedPaymentCard)
	card!: MaskedPaymentCard;

	@MapFromDTO()
	encryptedCvv?: string;

	@MapFromDTO()
	maskedEmail?: string;

	@MapFromDTO()
	maskedPhoneNumber?: string;

	@MapFromDTO()
	customerId?: string;

	@Mapper(PaymentCardTokenStatus)
	status!: PaymentCardTokenStatus;

	@Mapper(momentMapper)
	created!: Moment;

	@Mapper(PaymentCardTokenSneakPeekAuditEventVm)
	sneakPeekHistory?: PaymentCardTokenSneakPeekAuditEventVm[];

	@Mapper(MigrationSource)
	migrationSource?: MigrationSource;

	constructor(dto: DTO<PaymentCardToken>) {
		super(dto);
	}

}
