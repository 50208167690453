
import { Mapper, Required, DTO, MetadataEntity, Secret } from '@bp/shared/models/metadata';

import { ThreeDsProcessorKind } from './three-ds-processor-kind.enum';

export class ThreeDsProcessor extends MetadataEntity {

	@Required()
	@Mapper(ThreeDsProcessorKind)
    kind!: ThreeDsProcessorKind;

	@Required()
	@Secret()
	merchantToken!: string;

	@Required()
	@Secret()
	merchantId!: string;

	constructor(dto: DTO<ThreeDsProcessor>) {
		super(dto);
	}

}
