<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<div cdkTrapFocus cdkTrapFocusAutoCapture class="card-container">
	<bp-back-btn [disabled]="_isSubmitting$ | async" />
	<!-- <button (click)="_paymentOptionInstancesManager.prev()">prev</button>
	<button (click)="_paymentOptionInstancesManager.next()">next</button> -->

	<section
		cdkFocusInitial
		class="card payment-card"
		[class.installments]="_isInstallments"
		[class.submitting]="_isSubmitting$ | async"
	>
		<bp-psps-device-fingerprint-collector
			*ngIf="_isPspsFingerprintsCollectionRequired"
			[pspsFingerprintCollectionHtmls]="_paymentMethod.ddc_htmls!"
			(pspsFingerprints)="_pspsFingerprints = $event"
			(allFingerprintsCollected)="_allFingerprintsCollected$.next(true)"
		/>

		<bp-processing-stub [show]="!_embeddedData.disableProcessingMessages && (_isSubmitting$ | async)" />

		<div *ngIf="_paymentMethod.installments_order_summary?.description as orderSummary" class="order-summary">
			<span class="title"> Order Summary </span>

			<dl>
				<div *ngFor="let orderSummaryItem of orderSummary | keyvalue: _originalOrderKeyValueComparator">
					<dt>{{ orderSummaryItem.key | titleCase }}</dt>
					<dd>{{ orderSummaryItem.value }}</dd>
				</div>
			</dl>
		</div>

		<span *ngIf="_session.isRegularLikeCheckout && _appService.isNotBrightTheme" class="title">
			{{
				'page.credit-card' +
					(_isInstallments ? '-installments' : '') +
					'.' +
					(_isAstroPayProvider ? 'astro-pay-title' : 'title') | translate
			}}
		</span>

		<form [formGroup]="_form" (ngSubmit)="_submit()">
			<!-- we gotta wait till the form group directive form property got set that underlying
form controls got property updateOn setting -->
			<ng-container *bpDelayedRender>
				<div *ngIf="_session.isPayWithCheckout" class="page-amount" @fadeIn>
					<h1 *ngIf="!_paymentMethod.hide_amount" class="amount">
						{{ _getAmount() | currency: _currency!.code }}
					</h1>

					<bp-secure-payment-static-text />
				</div>

				<bp-amount
					*ngIf="_appService.isBrightTheme && _session.isRegularLikeCheckout && !_paymentMethod.hide_amount"
					[config]="_paymentMethod"
					[control]="_controls.amount"
					[fiatCurrencies]="_fiatCurrencies"
					[installmentAmount]="_paymentMethod.installments_order_summary?.estimated_amount"
					[amountLock]="_session.amountLock"
					[(currency)]="_currency"
					(currencyChange)="_revalidatePaymentCardNumber()"
				/>

				<div class="row">
					<div class="left">
						<div *ngIf="_appService.isBrightTheme" class="mat-body-1 mb-n2 flex-justify-content-between">
							<div>{{ 'Payment information' | translate }}</div>

							<bp-payment-instance-out-of-total-text
								*ngIf="_session.isPayWithCheckout"
								class="capitalize-first-letter"
							/>
						</div>

						<div
							*ngIf="_shouldShowDefaultField('firstName') || _shouldShowDefaultField('lastName')"
							class="row"
							[class.display-contents]="_appService.isBrightTheme"
						>
							<mat-form-field>
								<mat-label>{{ 'first-name' | translate }}</mat-label>

								<input
									type="text"
									matInput
									autocomplete="first-name"
									[formControl]="_controls.firstName!"
									[maxlength]="_textFieldMaxLength"
									[required]="true"
								/>

								<mat-error bpFieldError="firstName" />
							</mat-form-field>

							<mat-form-field>
								<mat-label>{{ 'last-name' | translate }}</mat-label>

								<input
									type="text"
									matInput
									autocomplete="last-name"
									[formControl]="_controls.lastName!"
									[maxlength]="_textFieldMaxLength"
									[required]="true"
								/>

								<mat-error bpFieldError="lastName" />
							</mat-form-field>
						</div>

						<mat-form-field *ngIf="_shouldShowDefaultField('address')">
							<mat-label>{{ 'address' | translate }}</mat-label>

							<input
								type="text"
								matInput
								autocomplete="street-address"
								[formControl]="_controls.address!"
								[maxlength]="_textFieldMaxLength"
								[required]="true"
							/>

							<mat-error bpFieldError="address" />
						</mat-form-field>

						<div *ngIf="_shouldShowDefaultField('city') || _shouldShowDefaultField('zipCode')" class="row">
							<mat-form-field *ngIf="_shouldShowDefaultField('city')">
								<mat-label>{{ 'city' | translate }}</mat-label>

								<input
									type="text"
									matInput
									autocomplete="locality"
									[formControl]="_controls.city!"
									[maxlength]="30"
									[required]="true"
								/>

								<mat-error bpFieldError="city" />
							</mat-form-field>

							<mat-form-field *ngIf="_shouldShowDefaultField('zipCode')">
								<mat-label>{{ 'zip-code' | translate }}</mat-label>

								<input
									type="text"
									matInput
									autocomplete="postal-code"
									[formControl]="_controls.zipCode!"
									[maxlength]="_textFieldMaxLength"
									[required]="true"
								/>

								<mat-error bpFieldError="zipCode" />
							</mat-form-field>
						</div>

						<div
							*ngIf="_shouldShowDefaultField('stateCode') || _shouldShowDefaultField('phone')"
							class="row"
						>
							<mat-form-field *ngIf="_shouldShowDefaultField('stateCode')">
								<mat-label>{{ 'state' | translate }}</mat-label>

								<input
									#stateInput
									type="text"
									matInput
									autocomplete="null"
									[formControl]="_controls.state!"
									[matAutocomplete]="stateAutocomplete"
									[required]="true"
								/>

								<mat-autocomplete #stateAutocomplete="matAutocomplete">
									<mat-option
										*ngFor="
											let state of _session.country.states! | bpFilterStates: stateInput.value
										"
										[value]="state.name"
									>
										{{ state.name }}
									</mat-option>
								</mat-autocomplete>

								<mat-error bpFieldError="state" />
							</mat-form-field>

							<bp-phone-input
								*ngIf="_shouldShowDefaultField('phone')"
								label="phone"
								name="phone"
								nativeAutocomplete="tel"
								floatLabel="always"
								[required]="true"
								[formControl]="_controls.phone!"
								[initialDialCodeCountry]="_session.country!"
								(dialCodeChange)="_controls.countryDialCode!.setValue($event)"
							/>
						</div>

						<mat-form-field *ngIf="_shouldShowDefaultField('email')">
							<mat-label>{{ 'email' | translate }}</mat-label>

							<input
								matInput
								type="email"
								inputmode="email"
								autocomplete="email"
								[formControl]="_controls.email!"
								[required]="true"
							/>

							<mat-error bpFieldError="email" />
						</mat-form-field>

						<mat-form-field *ngIf="_isAdditional('personalId') && !_paymentMethod.personal_id_types">
							<mat-label>
								{{
									_isGateToPayProvider
										? ('page.deposit.personal-id.gatetopay' | translate)
										: (_session.country | bpCountryPersonalIdName)
								}}
							</mat-label>

							<input
								matInput
								type="text"
								autocomplete="personalId"
								[formControl]="_controls.personalId!"
								[required]="true"
							/>

							<mat-hint *ngIf="_isGateToPayProvider">
								{{ 'page.deposit.personal-id.gatetopay.hint' | translate }}
							</mat-hint>

							<mat-error bpFieldError="personalId" />
						</mat-form-field>

						<div *ngIf="_isAdditional('personalId') && !!_paymentMethod.personal_id_types" class="row">
							<mat-form-field class="flex-third">
								<mat-label>{{ 'ID Type' | translate }}</mat-label>

								<mat-select formControlName="personalIdType" [required]="true">
									<mat-option
										*ngFor="let personalIdType of _paymentMethod.personal_id_types!"
										[value]="personalIdType"
									>
										{{ personalIdType.title }}
									</mat-option>
								</mat-select>

								<mat-error bpFieldError="personalIdType" />
							</mat-form-field>

							<mat-form-field>
								<mat-label> {{ _controls.personalIdType!.value.title }} </mat-label>

								<input
									formControlName="personalId"
									matInput
									type="text"
									autocomplete="personalId"
									[required]="true"
								/>

								<mat-error bpFieldError="personalId" />
							</mat-form-field>
						</div>

						<div *ngIf="_isAdditional('birthDate') || _isAdditional('gender')" class="row">
							<mat-form-field *ngIf="_isAdditional('birthDate')">
								<mat-label>{{ 'birthday' | translate }}</mat-label>

								<input
									type="text"
									matInput
									autocomplete="bday"
									x-autocompletetype="birthday"
									[formControl]="_controls.birthDate!"
									[matDatepicker]="dp"
									[max]="_outOfAgeDate"
								/>

								<mat-datepicker-toggle matSuffix [for]="dp" />

								<mat-datepicker #dp startView="multi-year" [startAt]="_startDate" />

								<mat-error bpFieldError="birthDate" />
							</mat-form-field>

							<mat-form-field *ngIf="_isAdditional('gender')">
								<mat-label>{{ 'gender.title' | translate }}</mat-label>

								<mat-select [formControl]="_controls.gender!">
									<mat-option *ngFor="let v of _genders$ | async" [value]="v.value">
										{{ v.display }}
									</mat-option>
								</mat-select>

								<mat-error bpFieldError="gender" />
							</mat-form-field>
						</div>

						<mat-form-field *ngFor="let fieldMetadata of _paymentMethod.fields">
							<mat-label>{{ fieldMetadata.title | translate }}</mat-label>

							<mat-select
								*ngIf="fieldMetadata.controlType.isSelect"
								[formControlName]="fieldMetadata.name"
								[required]="!!fieldMetadata.required"
							>
								<mat-option *ngFor="let item of fieldMetadata.items!" [value]="item.id">
									{{ item.title | translate }}
								</mat-option>
							</mat-select>

							<input
								*ngIf="fieldMetadata.controlType.isInput"
								matInput
								type="text"
								[autocomplete]="fieldMetadata.name"
								[formControlName]="fieldMetadata.name"
								[required]="!!fieldMetadata.required"
							/>

							<mat-error [bpFieldError]="fieldMetadata.name" />
						</mat-form-field>

						<div
							*ngIf="
								_paymentMethod.installments_order_summary?.installment_options as installment_options
							"
							class="row"
						>
							<mat-form-field>
								<mat-label>{{ 'Payment Terms' | translate }}</mat-label>

								<mat-select [formControl]="_controls.installmentOptionId!">
									<mat-option *ngFor="let v of installment_options" [value]="v.id">
										{{ v.name }}
									</mat-option>
								</mat-select>

								<mat-error bpFieldError="installmentOptionId" />
							</mat-form-field>
						</div>

						<bp-payment-card-number-input
							#savedPaymentCardTokensSelectOrigin="matAutocompleteOrigin"
							matAutocompleteOrigin
							label="page.credit-card.number"
							name="cardNumber"
							[required]="true"
							[formControl]="_controls.cardNumber!"
							[pending]="_controls.cardNumber!.pending"
							[placeholder]="_session.showPlaceholderForCardNumber ? '0000 0000 0000 0000' : ''"
							[nativeAutocomplete]="(_showSavedPaymentCardTokensSelect$ | async) === false"
							[skipLuhnValidation]="_environment.isNotProduction"
							(paymentCardBrandChange)="_paymentCardBrand = $event"
							(focusin)="_savedPaymentCardTokensSelectComponent?.open()"
							(focusout)="_savedPaymentCardTokensSelectComponent?.closeIfWasntFocused($event)"
							(valueChange)="_savedPaymentCardTokensSelectComponent?.close()"
						/>

						<bp-saved-payment-card-tokens-select
							*ngIf="_showSavedPaymentCardTokensSelect$ | async"
							[connectedTo]="savedPaymentCardTokensSelectOrigin"
							[paymentCardTokens]="_paymentCardTokens$ | async"
							(paymentCardTokenSelected)="_patchPaymentCardInputsOnPaymentCardTokenSelection($event)"
						/>

						<div class="row expiry-cvv">
							<bp-payment-card-expire-input
								label="page.credit-card.expiry"
								name="cardExpiryDate"
								[required]="true"
								[formControl]="_controls.cardExpiryDate!"
							/>

							<bp-payment-card-cvv-input
								label="CVV"
								name="cardCvv"
								[paymentCardBrand]="_paymentCardBrand"
								[formControl]="_controls.cardCvv!"
								[placeholder]="_session.showPlaceholderForCvv ? '---' : ''"
								[required]="!_optionalFields.has('cardCvv')"
							/>
						</div>

						<bp-payment-card-holder-input
							*ngIf="_shouldShowDefaultField('cardHolderName')"
							label="page.credit-card.holder"
							name="cardHolderName"
							[class.no-input-uppercase]="_session.dontRenderCardHolderNameInUppercase"
							[formControl]="_controls.cardHolderName!"
							[required]="true"
							[allowAnyCharInValue]="_environment.isStagingOrPreview"
						/>

						<div *ngIf="_isAdditional('pin')" class="row">
							<mat-form-field>
								<mat-label> PIN </mat-label>

								<input
									class="private"
									data-private
									matInput
									type="password"
									[formControl]="_controls.pin!"
									[required]="true"
								/>

								<mat-error bpFieldError="pin" />
							</mat-form-field>

							<mat-form-field class="invisible">
								<mat-label> placeholder </mat-label>

								<input matInput type="text" />
							</mat-form-field>
						</div>

						<mat-checkbox
							*ngIf="
								_session.allowCreateCreditCardToken &&
								!_session.hideSaveCreditCardCheckbox &&
								!_session.isMerchantAdmin &&
								!_paymentMethod.process_as_credit_card &&
								_controls.cardNumber!.value &&
								!_controls.cardToken!.value
							"
							class="save-card-checkbox"
							color="primary"
							[class.mt-3]="_appService.isNotBrightTheme"
							[formControl]="_controls.saveCard!"
							@slide
						>
							{{
								_appService.isBrightTheme
									? 'Remember my card'
									: ('page.credit-card.save-card-checkbox-caption' | translate)
							}}
						</mat-checkbox>

						<div *ngIf="_globalError" class="global-error mat-error row" @slide>
							{{ _globalError | translate }}
						</div>

						<ng-container *ngIf="_appService.isNotBrightTheme">
							<bp-page-note forPage="paymentCard" [paymentMethod]="_paymentMethod" />

							<bp-page-note *ngIf="_pspBadge$ | async as pspBadge" [note]="pspBadge" />
						</ng-container>

						<div *ngIf="_isInstallments && _appService.isNotBrightTheme" class="secure-logos">
							<img class="master" src="assets/images/secure/master-card{{ _secureLogosPrefix }}.svg" />
							<img class="visa" src="assets/images/secure/visa{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/pci{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/ssl{{ _secureLogosPrefix }}.svg" />
						</div>
					</div>

					<ng-container *ngIf="_appService.isBrightTheme">
						<bp-page-note forPage="paymentCard" [paymentMethod]="_paymentMethod" />

						<bp-page-note *ngIf="_pspBadge$ | async as pspBadge" [note]="pspBadge" />
					</ng-container>

					<div class="right">
						<div
							*ngIf="
								_appService.isNotBrightTheme &&
								_paymentMethod.installments_order_summary?.description as orderSummary
							"
							class="order-summary"
						>
							<span class="title"> Order Summary </span>

							<dl>
								<div
									*ngFor="
										let orderSummaryItem of orderSummary
											| keyvalue: _originalOrderKeyValueComparator
									"
								>
									<dt>{{ orderSummaryItem.key | titleCase }}</dt>
									<dd>{{ orderSummaryItem.value }}</dd>
								</div>
							</dl>
						</div>

						<div *ngIf="_appService.isNotBrightTheme" class="secure-logos">
							<img class="master" src="assets/images/secure/master-card{{ _secureLogosPrefix }}.svg" />
							<img class="visa" src="assets/images/secure/visa{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/pci{{ _secureLogosPrefix }}.svg" />
							<img src="assets/images/secure/ssl{{ _secureLogosPrefix }}.svg" />
						</div>

						<div class="credit-detail">
							<bp-amount
								*ngIf="_appService.isNotBrightTheme && !_paymentMethod.hide_amount"
								[config]="_paymentMethod"
								[control]="_controls.amount"
								[fiatCurrencies]="_fiatCurrencies"
								[installmentAmount]="_paymentMethod.installments_order_summary?.estimated_amount"
								[amountLock]="_session.amountLock"
								[(currency)]="_currency"
								(currencyChange)="_revalidatePaymentCardNumber()"
								(inputFocus)="_markAsDirtyAllControls()"
							/>

							<button
								class="btn btn-submit"
								type="submit"
								matRipple
								[matRippleDisabled]="_cannotSubmit"
								[class.card-float-button-position]="
									!_session.isHighlow &&
									!_session.isHighlowQoneco &&
									!_session.isHighlowXoro &&
									_appService.isNotBrightTheme
								"
								[disabled]="_cannotSubmit"
							>
								{{
									_session.depositButtonText || (_session.payMode ? 'btn.pay' : 'btn.deposit')
										| translate
								}}

								<ng-container *ngIf="_appService.isBrightTheme && !_paymentMethod.hide_amount">
									{{ _getAmount() | currency: _currency!.code }}
								</ng-container>

								<bp-dots-wave
									*ngIf="
										!_session.isHighlow &&
										!_session.isHighlowQoneco &&
										!_session.isHighlowQoneco &&
										!_session.isHighlowXoro &&
										_appService.isNotBrightTheme
									"
									[show]="_isSubmitting$ | async"
								/>
							</button>
						</div>
					</div>
				</div>

				<bp-payment-summary *ngIf="_session.isPayWithCheckout" />
			</ng-container>
		</form>
	</section>
</div>
