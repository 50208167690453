import { Pipe, PipeTransform } from '@angular/core';

import { Country } from '@bp/shared/models/countries';
import { Dictionary } from '@bp/shared/typings';

// eslint-disable-next-line @typescript-eslint/naming-convention
import PERSONAL_ID_ABBREVIATIONS from './personal-id-abbreviations.json';

@Pipe({
	name: 'bpCountryPersonalIdName',
})
export class CountryPersonalIdNamePipe implements PipeTransform {

	transform(country: Country): string {
		return (<Dictionary<string | undefined>>PERSONAL_ID_ABBREVIATIONS)[country.code] ?? 'Personal ID';
	}

}
