import { map, Observable } from 'rxjs';

import { inject, Pipe, PipeTransform } from '@angular/core';

import { filterPresent } from '@bp/frontend/rxjs';
import { LanguagesService } from '@bp/frontend/domains/checkout/services';

@Pipe({
	standalone: true,
	name: 'bpIntlCompactNumber',
})
export class IntlCompactNumberPipe implements PipeTransform {

	private readonly __languagesService = inject(LanguagesService);

	transform(value: number): Observable<string> {
		return this.__languagesService.currentLanguage$.pipe(
			filterPresent,
			map(({ iso }) => {
				try {
					return new Intl.NumberFormat(iso, { notation: 'compact' }).format(value);
				} catch {
					return value.toString();
				}
			}),
		);
	}
}
