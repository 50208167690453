
import { firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { BpError } from '@bp/frontend/models/core';

import { CheckoutSession } from '@bp/checkout-frontend/models';
import { AppService, EmbeddedDataResolver, SessionApiService } from '@bp/checkout-frontend/providers';

import { BloxHostNotifierService } from './blox-host-notifier.service';
import { BloxService } from './blox.service';

@Injectable({
	providedIn: 'root',
})
export class BloxControllerResolver implements Resolve<CheckoutSession | null> {

	private readonly __sessionApiService = inject(SessionApiService);

	private readonly __appService = inject(AppService);

	private readonly __bloxService = inject(BloxService);

	private readonly __hostBloxNotifier = inject(BloxHostNotifierService);

	private readonly __embeddedDataResolver = inject(EmbeddedDataResolver);

	private readonly __telemetry = inject(TelemetryService);

	async resolve(route: ActivatedRouteSnapshot): Promise<CheckoutSession | null> {
		return this.createSession(route);
	}

	private async createSession(route: ActivatedRouteSnapshot): Promise<CheckoutSession | null> {
		const embedded = await this.__embeddedDataResolver.resolve(route);
		const controllerId = route.paramMap.get('controllerId')!;

		this.__bloxService.setControllerId(controllerId);

		this.__hostBloxNotifier.setConfig({
			scope: 'controller',
			id: controllerId,
		});

		if (!embedded) {
			this.__hostBloxNotifier.controllerInitFailed({
				error: 'Missing token',
			});

			return null;
		}

		if (this.__appService.session)
			return this.__appService.session;

		const getApiSession$ = this.__sessionApiService.get(embedded.checkoutSessionId)
			.pipe(catchError((error: unknown) => {
				this.__hostBloxNotifier.controllerInitFailed({
					error: error instanceof BpError ? error.message : `${ error }`,
				});

				return throwError(error);
			}));

		const session = await firstValueFrom(getApiSession$);

		try {
			if (session.checkoutKey && this.__appService.isRegularMerchant()) {
				const isSuspended = await firstValueFrom(
					this.__sessionApiService.getSuspensionStatus(session.checkoutKey),
				);

				session.isSuspended = isSuspended;
			}
		} catch (error: unknown) {
			TelemetryService.captureError(error);
		}

		if (session.isSuspended) {
			this.__hostBloxNotifier.controllerInitFailed({
				error: 'Organization is suspended',
			});

			return null;
		}

		if (!session.checkoutType.isBlox) {
			this.__hostBloxNotifier.controllerInitFailed({
				error: 'Blox can be used only for the blox checkouts',
			});

			return null;
		}

		this.__appService.newSession(session);

		this.__hostBloxNotifier.controllerInit();

		return session;
	}

}
