import { Enumeration } from '@bp/shared/models/core/enum';

export class PaymentCardTokenType extends Enumeration {

	static multiUse = new PaymentCardTokenType();

	static singleUse = new PaymentCardTokenType();

	get isMultiUse(): boolean {
		return this === PaymentCardTokenType.multiUse;
	}

	get isSingleUse(): boolean {
		return this === PaymentCardTokenType.singleUse;
	}
}
