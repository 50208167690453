import { catchError, map, of, shareReplay } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CF_WORKER_PATH_SEGMENT } from '@bp/frontend/services/http';
import { GeolocationInfo, GeolocationInfoDTO, GEOLOCATION_PATH_SEGMENT } from '@bp/frontend/features/geolocation/models';
import { mapTo } from '@bp/frontend/rxjs';
import { TelemetryService } from '@bp/frontend/services/telemetry';

@Injectable({
	providedIn: 'root',
})
export class GeolocationService {

	private readonly __http = inject(HttpClient);

	private readonly __telemetry = inject(TelemetryService);

	private _info: GeolocationInfo = new GeolocationInfo();

	get info(): GeolocationInfo {
		return this._info;
	}

	info$ = this.__http
		.get<GeolocationInfoDTO>(`${ CF_WORKER_PATH_SEGMENT }/${ GEOLOCATION_PATH_SEGMENT }`)
		.pipe(
			mapTo(GeolocationInfo),
			shareReplay({ bufferSize: 1, refCount: false }),
			catchError(() => of(new GeolocationInfo())),
		);

	country$ = this.info$
		.pipe(map(geolocation => geolocation.country));

	state$ = this.info$
		.pipe(map(geolocation => geolocation.state));

	city$ = this.info$
		.pipe(map(geolocation => geolocation.city));

	constructor() {
		this.info$.subscribe(info => {
			this._info = info;

			this.__telemetry.identifyUser({ ipAddress: info.ip });

			this.__telemetry.setTags(info.getTelemetryContext());
		});
	}

}
