<bp-back-btn />

<section class="card payment-card">
	<table>
		<ng-container *ngFor="let cryptoWallet of _cryptoWallets">
			<tr>
				<td class="mat-caption">
					<div>
						<bp-img
							class="crypto-logo"
							[urls]="[
								_cryptoLogoAssets + cryptoWallet.cryptoCurrency.code + '.svg',
								_cryptoLogoAssets + cryptoWallet.cryptoCurrency.code + '.png'
							]"
						/>

						<div>
							<div>
								{{ cryptoWallet.cryptoCurrency.description }} ⸱ {{ cryptoWallet.cryptoCurrency.code }}
							</div>
						</div>
					</div>
				</td>
				<td class="text">
					<div>
						<span class="address">
							{{ cryptoWallet.address }}
						</span>

						<bp-copy-to-clipboard-btn copyText="Copy the address" [value]="cryptoWallet.address" />
					</div>
				</td>
			</tr>
		</ng-container>
	</table>
</section>
