import { isNaN, isString } from 'lodash-es';

import { FiatCurrency, FiatCurrencyCode, getFiatCurrencyFromNumericCode } from '@bp/shared/models/currencies';
import { DTO, MapFromDTO, MetadataEntity, Mapper } from '@bp/shared/models/metadata';

export type ThreeDsProcessorGetTransactionResponseDTO = DTO<ThreeDsProcessorGetTransactionResponse>;

/**
 * https://docs.activeserver.cloud/en/api/admin/#/Transactions/Get%20Transaction_1
 */
export class ThreeDsProcessorGetTransactionResponse extends MetadataEntity {

	@MapFromDTO()
	transId!: string;

	@MapFromDTO()
	transDate!: string;

	@MapFromDTO()
	provider?: string;

	@MapFromDTO()
	purchaseAmount?: string;

	@Mapper(numericOrLetterCodeOrFiatCurrency => isString(numericOrLetterCodeOrFiatCurrency)
		? (isNaN(Number(numericOrLetterCodeOrFiatCurrency))
			? new FiatCurrency(<FiatCurrencyCode>numericOrLetterCodeOrFiatCurrency)
			: getFiatCurrencyFromNumericCode(numericOrLetterCodeOrFiatCurrency))
		: numericOrLetterCodeOrFiatCurrency)
	purchaseCurrency?: FiatCurrency;

	@MapFromDTO()
	purchaseDate?: string;

	@MapFromDTO()
	purchaseExponent?: number;

	@MapFromDTO()
	threeDSCompInd?: string;

	constructor(dto: ThreeDsProcessorGetTransactionResponseDTO) {
		super(dto);
	}

}
