<div class="mat-body-1 mb-1">{{ 'Payment balance' | translate }}</div>

<div class="summary">
	<div class="total-paid">
		<div>
			<b>{{
				_paymentOptionInstancesManager.paidAmount | currency: _session.currency.code : 'symbol' : '1.0-2'
			}}</b>
		</div>

		<div class="mat-body-1">{{ 'Total paid' | translate }}</div>
	</div>

	<div class="total-left">
		<div>
			<b>{{
				_session.$amount - _paymentOptionInstancesManager.paidAmount
					| currency: _session.currency.code : 'symbol' : '1.0-2'
			}}</b>
		</div>
		<div class="mat-body-1">{{ 'Total remaining' | translate }}</div>
	</div>
</div>
