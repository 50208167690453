<bp-input
	nativeAutocomplete="cc-exp"
	inputmode="numeric"
	[name]="name"
	[placeholder]="placeholder ?? 'MM / YY'"
	[label]="label"
	[color]="color"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[hideClearButton]="hideClearButton"
	[required]="!!required"
	[hint]="hint"
	[pending]="pending"
	[mask]="_mask"
	[formControl]="_internalControl"
	[isSecret]="true"
/>
