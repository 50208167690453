import fitty, { FittyInstance } from 'fitty';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';

import { Directive, ElementRef, Input, AfterViewChecked, inject, OnDestroy } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { ZoneService } from '@bp/frontend/rxjs';

@Directive({
	standalone: true,
	selector: '[bpFitText]',
})
export class FitTextDirective extends Destroyable implements AfterViewChecked, OnDestroy {

	private readonly __zoneService = inject(ZoneService);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	@Input() bpFitText: {
		minSize?: number;
		maxSize?: number;
		multiLine?: boolean;
		dynamic?: boolean;
		observeMutations?: MutationObserverInit;
	} = {};

	private __fitty: FittyInstance | null = null;

	ngAfterViewChecked(): void {
		if (!this.__$host.isConnected || !!this.__fitty || !this.__$host.scrollWidth)
			return;

		this.__fitty = this.__zoneService.runOutsideAngular(() => fitty(this.__$host, {
			minSize: 8,
			observeMutations: { childList: true },
			...this.bpFitText,
		}));

		if (!this.bpFitText.dynamic)
			this.__freezeFittyAfterFirstFit();
	}

	ngOnDestroy(): void {
		this.__fitty?.unsubscribe();
	}

	private __freezeFittyAfterFirstFit(): void {
		fromEvent(this.__$host, 'fit')
			.pipe(
				first(),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this.__fitty!.freeze());
	}
}
