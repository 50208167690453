import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ResponseStatusCode } from '@bp/shared/models/core';

import { PspResultPageBaseComponent } from '@bp/checkout-frontend/components';
import { Transaction } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-card-declined-page',
	templateUrl: './card-declined-page.component.html',
	styleUrls: [ './card-declined-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDeclinedPageComponent extends PspResultPageBaseComponent<Transaction> {

	protected _redirectUrl: string | null = null;

	protected _canTryAgain = false;

	constructor() {
		super();

		this._appService.clearPaymentRequestFromStorage();

		this._notifyHostAboutPaymentStatus('declined');

		if (!!this._session.url?.failure && this._error?.status === ResponseStatusCode.TransactionDeclined)
			this._redirectUrl = this._buildRedirectUrl(this._session.url.failure, this._transaction.status!);

		this._whenCanContinueScheduleContinueExecution();

		this._whenPaywithCanContinueScheduleContinueExecution();

		if (this._appService.isEmbedded) {
			this._canTryAgain = this._error
				? (this._error.status === ResponseStatusCode.TransactionDeclined
					? false
					: this._error.messages.every(m => m.type !== 'do_not_try_again'))
				: true;
		}
	}

}
