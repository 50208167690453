import { AliasFor, DTO, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { ThreeDsProcessingResultCallbackEvent } from './three-ds-processing-result-callback-event.enum';

export type ThreeDsProcessingResultCallbackPayloadDTO = DTO<ThreeDsProcessingResultCallbackPayload>;

/**
 * https://docs.activeserver.cloud/en/api/auth/#/Auth%20API%20V2/BRW_INIT_AUTH_V2_1
 * eventCallbackUrl property description
 */
export class ThreeDsProcessingResultCallbackPayload extends MetadataEntity {

	@MapFromDTO()
    requestorTransId!: string;

	@Mapper(ThreeDsProcessingResultCallbackEvent)
    event!: ThreeDsProcessingResultCallbackEvent;

	@AliasFor('param')
    browserInfo!: string;

	@MapFromDTO()
    threeDSSessionData!: string;

	constructor(dto: ThreeDsProcessingResultCallbackPayloadDTO) {
		super(dto);
	}

}
