/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApmService } from '@elastic/apm-rum-angular';
import { ApmBase } from '@elastic/apm-rum';
import { mapValues } from 'lodash-es';

import { Injectable, inject } from '@angular/core';

import { Platform } from '@bp/shared/typings';
import { NEST_PATH_SEGMENT } from '@bp/shared/models/core';
import { REAL_USER_MONITORING_EVENTS_ACTION } from '@bp/shared/features/rum-events';

import { BpError } from '@bp/frontend/models/core';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { HttpConfigService } from '@bp/frontend/services/http';
import { EnvironmentService, PLATFORM } from '@bp/frontend/services/environment';
import { IReporter, IReporterUser, ReporterError } from '@bp/frontend/services/telemetry';

@Injectable({ providedIn: 'root' })
export class RealUserMonitoringReporter implements IReporter {

	private readonly __apmService = inject(ApmService);

	private readonly __httpConfigService = inject(HttpConfigService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __platform: Platform = inject(PLATFORM);

	private readonly __apmBase!: ApmBase;

	constructor() {
		this.__enableMonitoringForMockedBackendIfNeeded();

		this.__apmBase = this.__apmService.init({
			serviceName: this.__platform,
			serviceVersion: this.__environment.appVersion.releaseTitle,
			environment: this.__environment.name,
			serverUrl: this.__httpConfigService.backendBaseSegment,
			serverUrlPrefix: `/${ NEST_PATH_SEGMENT }/${ REAL_USER_MONITORING_EVENTS_ACTION }`,
			...(this.__environment.isLocal ? {
				distributedTracingOrigins: [ /https?:\/\/localhost(:\d{4})?/u ],
			} : {}),
			apmRequest: ({ xhr }) => {
				xhr.setRequestHeader('ngsw-bypass', '');

				return true;
			},
		});
	}

	identifyUser(user: IReporterUser): void {
		this.__apmBase.setUserContext({
			id: user.id ?? user.email ?? undefined,
			email: <string | null> user.email ?? undefined,
		});
	}

	captureError(error: ReporterError): void {
		if (error instanceof BpError)
			error.originalError = undefined;

		this.__apmBase.captureError(this.__toError(error));
	}

	captureMessage(_message: string): void {
		// Does nothing
	}

	warn(_message: string, ..._payload: any[]): void {
		// Does nothing
	}

	log(_message: string, ..._payload: any[]): void {
		// Does nothing
	}

	track(_key: string, _value: any): void {
		// Does nothing
	}

	setTags(tags: Parameters<IReporter['setTags']>[0]): void {
		this.__apmBase.addLabels(
			mapValues(tags, value => value?.toString() ?? 'no-value'),
		);
	}

	private __enableMonitoringForMockedBackendIfNeeded(): void {
		if (MockedBackendState.isActive)
			// By default, requests are gzipped. MirageJs breaks gzip requests.
			// This flag disables deflating, enabling local APM server to work with MirageJs enabled.
			// https://www.elastic.co/guide/en/apm/agent/rum-js/current/troubleshooting.html#disable-events-payload-compression
			sessionStorage.setItem('_elastic_inspect_beacon_', '1');
		else
			sessionStorage.removeItem('_elastic_inspect_beacon_');
	}

	private __toError(error: ReporterError): Error {
		if (error instanceof Error)
			return error;

		return new Error(
			error instanceof ErrorEvent ? error.message : error.reason,
		);
	}

}
