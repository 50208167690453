import { map, Observable } from 'rxjs';

import { inject, PipeTransform, Pipe } from '@angular/core';

import { FiatCurrency } from '@bp/shared/models/currencies';

import { filterPresent } from '@bp/frontend/rxjs';
import { LanguagesService } from '@bp/frontend/domains/checkout/services';

@Pipe({
	standalone: true,
	name: 'bpIntlCurrencyName',
})
export class IntlCurrencyNamePipe implements PipeTransform {

	private readonly __languagesService = inject(LanguagesService);

	transform(
		currency: FiatCurrency | null,
	): Observable<string> {
		return this.__languagesService.currentLanguage$.pipe(
			filterPresent,
			map(({ iso }) => {
				if (currency && this.__languagesService.currenciesDisplayNamesPerLanguageMap) {
					return this.__languagesService.currenciesDisplayNamesPerLanguageMap
						.get(iso)!
						.of(currency.code) ?? '';
				}

				return currency?.displayName ?? '';
			}),
		);
	}

}
