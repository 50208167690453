import { booleanMapper, DTO, MapFromDTO, Mapper, MetadataEntity, Required } from '@bp/shared/models/metadata';

export type ThreeDsProcessorInitAuthenticationResponseDTO = DTO<ThreeDsProcessorInitAuthenticationResponse>;

/**
 * https://docs.activeserver.cloud/en/api/auth/#/Auth%20API%20V2/BRW_INIT_AUTH_V2_1
 */
export class ThreeDsProcessorInitAuthenticationResponse extends MetadataEntity {

	@Required()
	authUrl!: string;

	@MapFromDTO()
    monUrl?: string;

	@Mapper(booleanMapper)
    threeDSMethodAvailable!: boolean;

	@MapFromDTO()
	threeDSServerCallbackUrl?: string;

	@Required()
	threeDSServerTransID!: string;

	constructor(dto: DTO<ThreeDsProcessorInitAuthenticationResponse>) {
		super(dto);
	}

}
