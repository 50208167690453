import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';

import { StatusPageComponent, CardSuccessPageComponent, CardPendingPageComponent, CardDeclinedPageComponent, CardProceedOnOpenTabPageComponent } from './pages';
import { StatusTransactionResolver } from './status-transaction.resolver';

const routes: Routes = [
	{
		matcher: matchRoutePathWithVersionPathSegment('status'),
		children: [
			{
				path: '',
				component: StatusPageComponent,
				data: {
					dontHandleSessionClosedState: true,
				},
				resolve: {
					transaction: StatusTransactionResolver,
				},
			},
			{ path: 'card-proceed-on-open-tab', component: CardProceedOnOpenTabPageComponent },
			{ path: 'card-success', component: CardSuccessPageComponent },
			{ path: 'card-pending', component: CardPendingPageComponent },
			{ path: 'card-declined', component: CardDeclinedPageComponent },
			{
				path: ':sessionId/:transactionId',
				component: StatusPageComponent,
				data: {
					dontHandleSessionClosedState: true,
				},
				resolve: {
					transaction: StatusTransactionResolver,
				},
			},
			{
				path: ':status',
				component: StatusPageComponent,
				data: {
					dontHandleSessionClosedState: true,
				},
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class StatusRoutingModule { }
