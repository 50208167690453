import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { Transaction } from '@bp/checkout-frontend/models';
import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-apm-wire-transfer-details-page',
	templateUrl: './apm-wire-transfer-details-page.component.html',
	styleUrls: [ './apm-wire-transfer-details-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApmWireTransferDetailsPageComponent {

	protected readonly _appService = inject(AppService);

	protected get _transaction(): Transaction {
		return <Transaction> this._appService.transaction;
	}

}
