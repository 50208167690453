import { DTO, MetadataEntity, Validator } from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';

import { IPaymentCardTokenCreateEventPayload } from '@bp/frontend/domains/checkout/core';

export class PaymentCardTokenCreateEventPayload extends MetadataEntity implements IPaymentCardTokenCreateEventPayload {

	@Validator(Validators.required)
	cardHolderName!: string;

	@Validator(Validators.required)
	singleUse!: boolean;

	constructor(dto: DTO<PaymentCardTokenCreateEventPayload>) {
		super(dto);
	}

}
