/* eslint-disable complexity */
import { compact } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BpError } from '@bp/frontend/models/core';
import { PaymentOptionType } from '@bp/frontend/models/business';

import { AppService } from '@bp/checkout-frontend/providers';
import { IPaymentMethod, CheckoutSession } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-home-page',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent {

	private readonly __appService = inject(AppService);

	private readonly __router = inject(Router);

	private get __session(): CheckoutSession | null {
		return this.__appService.session;
	}

	constructor() {
		void this.__initialNavigation();
	}

	private async __initialNavigation(): Promise<void> {
		const route = await this.getInitialRoute();

		this.__appService.markAppReady();

		if (route)
			this.__appService.navigate(route);
	}

	private async getInitialRoute(): Promise<any[] | null> {
		const paymentOptionsPage: string[] = [ '/payments' ];

		if (this.__session!.isPayWithCheckout) {
			return [
				...paymentOptionsPage,
				PaymentOptionType.paywith.name,
			];
		}

		if (this.__session!.isPaymentCardTokenCheckout) {
			return [
				'payment-card-token',
			];
		}

		if (this.__appService.embedded!.onlyCreditCardPaymentMethod) {
			const { paymentProvider } = this.__appService.embedded!;
			const creditCardMethod = this.__session!.paymentMethods
				.find(v => v.type.isCreditCard);

			if (creditCardMethod) {
				return paymentProvider
					? [
						...paymentOptionsPage,
						PaymentOptionType.creditCard.name,
						paymentProvider,
						creditCardMethod.currencyCode,
					]
					: this.__getPaymentMethodRoute(creditCardMethod);
			}

			this.__appService.setError(
				new BpError('The route does not have any credit card payment provider'),
			);

			return [ '/error' ];

		}

		const { singlePaymentMethod, singlePaymentProvider } = this.__session!;

		if (!!singlePaymentMethod || !!singlePaymentProvider) {
			const types = compact([
				singlePaymentMethod,
				singlePaymentMethod?.isApm ? PaymentOptionType.banks : undefined,
			]);

			const paymentMethods = <(IPaymentMethod | undefined)[]> this.__session!.paymentMethods.filter(
				v => (singlePaymentMethod ? types.includes(v.type) : true)
					&& (singlePaymentProvider ? v.provider === singlePaymentProvider || v.provider === 'none' : true),
			);

			if (paymentMethods.length > 1)
				return paymentOptionsPage;

			const [ paymentMethod ] = paymentMethods;

			if (!paymentMethod) {
				this.__appService.setError(`The route does not have any '${ singlePaymentMethod }' payment '${ singlePaymentProvider }' provider`);

				return [ '/error' ];
			}

			return this.__getPaymentMethodRoute(paymentMethod);
		}

		if (this.__session!.paymentMethods.length === 1 && !this.__session!.dontSkipSinglePaymentBox) {
			const [ paymentMethod ] = this.__session!.paymentMethods;

			if (paymentMethod.type.isApm && this.__session!.amountLock && this.__appService.isEmbedded && paymentMethod.open_in_new_window)
				return paymentOptionsPage;

			return this.__getPaymentMethodRoute(paymentMethod);
		} else if (this.__session!.directPaymentMethod && !this.__router.navigated) {
			const types = compact([
				this.__session!.directPaymentMethod,
				this.__session!.directPaymentMethod.isApm ? PaymentOptionType.banks : undefined,
			]);
			const paymentMethods = this.__session!.paymentMethods
				.filter(v => types.includes(v.type));

			if (paymentMethods.length > 1 || paymentMethods.length === 0)
				return paymentOptionsPage;

			const [ paymentMethod ] = paymentMethods;

			return this.__getPaymentMethodRoute(paymentMethod);
		}

		return paymentOptionsPage;
	}

	private async __getPaymentMethodRoute(method: IPaymentMethod): Promise<string[] | null> {
		try {
			if (method.has_banks)
				await firstValueFrom(this.__appService.loadBanks(method));
		} catch {
			// handled by the load banks method
			return null;
		}

		return this.__appService.getPaymentPageRouteCommands(method);
	}

}
