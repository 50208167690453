import { BehaviorSubject, map, Observable } from 'rxjs';
import { Memoize } from 'typescript-memoize';

import { Injectable } from '@angular/core';

export interface IPageExternalExtension {
	note: string; // general note

	providerNote: {
		[provider: string]: string; // provider specific note
	};
}

export interface IPagesExternalExtension {

	/**
	 * We use this to store old used way of note providing
	 */
	deprecatedNote?: string | null;

	all?: IPageExternalExtension;

	apm?: IPageExternalExtension;

	paymentCard?: IPageExternalExtension;

	crypto?: IPageExternalExtension;

	cryptoQR?: IPageExternalExtension;

}

export type ExtendablePages = keyof IPagesExternalExtension;

@Injectable({
	providedIn: 'root',
})
export class ExternalExtensionService {

	private readonly __pages$ = new BehaviorSubject<IPagesExternalExtension>({});

	pages$ = this.__pages$.asObservable();

	setPagesExternalExtension(pagesExternalExtension: IPagesExternalExtension): void {
		this.__pages$.next({
			...this.__pages$.value,
			...pagesExternalExtension,
		});
	}

	@Memoize((page: string, provider: string) => `${ page }-${ provider }`)
	getPageNote$(page: ExtendablePages, provider: string): Observable<string | null> {
		return this.__pages$.pipe(
			map(pagesExternalExtension => {
				const pageExtension = <IPageExternalExtension | null>pagesExternalExtension[page];

				return pageExtension?.providerNote[provider]
					?? pageExtension?.note
					?? pagesExternalExtension.all?.providerNote[provider]
					?? pagesExternalExtension.all?.note
					?? pagesExternalExtension.deprecatedNote
					?? null;
			}),
		);
	}
}
