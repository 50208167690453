import { isEmpty } from 'lodash-es';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { EmbeddedData, EmbeddedDataDTO, IS_MPI } from '@bp/checkout-frontend/models';

import { AppService } from './app.service';

@Injectable({
	providedIn: 'root',
})
export class EmbeddedDataResolver {

	private readonly __appService = inject(AppService);

	private readonly __environment = inject(EnvironmentService);

	async resolve(
		{ queryParams, params, url, data }: ActivatedRouteSnapshot,
	): Promise<EmbeddedData | null> {
		if (this.__appService.embedded)
			return this.__appService.embedded;

		if (params['sessionId']) {
			queryParams = <EmbeddedDataDTO>{
				...queryParams,
				checkoutSessionId: <string>params['sessionId'],
			};
		}

		queryParams = this.__tryGetQueryParamsFromBrowserSession(url.toString(), queryParams);

		if (isEmpty(queryParams))
			return null;

		this.__appService.setCheckoutKey(<string | undefined>queryParams['cashierKey'] ?? <string | undefined>queryParams['checkoutKey']);

		const embedded = new EmbeddedData({ ...queryParams });

		this.__appService.setEmbedded(embedded);

		if (data['awaitPaymentDetailsDecryption'] && this.__appService.isStandalone && !IS_MPI)
			await this.__tryDecryptPaymentToken();

		return embedded;
	}

	private __tryGetQueryParamsFromBrowserSession(url: string, queryParams: Params): Params {
		if (this.__environment.isLocal || url === '') {
			try {
				if (isEmpty(queryParams))
					queryParams = <Params>JSON.parse(sessionStorage.getItem('embedded_query_params')!);
				else
					sessionStorage.setItem('embedded_query_params', JSON.stringify(queryParams));
			} catch {
			}
		}

		return queryParams;
	}

	private async __tryDecryptPaymentToken(): Promise<void> {
		if (this.__appService.getPaymentRequestBody())
			TelemetryService.log('Skip decrypting payment token. Request has been retrieved from local storage');
		else
			await this.__appService.handleEncryptedPaymentToken();
	}
}
