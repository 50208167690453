import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LanguageSelectComponent } from '@bp/frontend/domains/checkout/components';
import { AppVersionComponent } from '@bp/frontend/components/app-version';
import { DeveloperOverlayPanelComponent } from '@bp/frontend/components/developer-overlay-panel';
import { PaymentCardBrandsSvgIconsDefinitionsComponent } from '@bp/frontend/components/payment-card/brands-svg-icons';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { HeaderComponent, FooterComponent, GlobalSpinnerComponent } from './components';
import { ErrorPageComponent, NotFoundPageComponent } from './pages';

const EXPOSED = [
	// containers
	ErrorPageComponent,
	NotFoundPageComponent,

	// dumb components
	HeaderComponent,
	FooterComponent,
	GlobalSpinnerComponent,
];

const MODULES = [
	CommonModule,
	SharedModule,
	RouterModule,
	AppVersionComponent,
	LanguageSelectComponent,
	DeveloperOverlayPanelComponent,
	SharedFeaturesSvgIconsModule,
	PaymentCardBrandsSvgIconsDefinitionsComponent,

	SharedComponentsCoreModule,
];

@NgModule({
	imports: MODULES,
	declarations: EXPOSED,
	exports: [ MODULES, EXPOSED ],
})
export class CoreModule { }
