
import { startCase, has, snakeCase, mapKeys } from 'lodash-es';

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { Dictionary } from '@bp/shared/typings';
import { COUNTRIES_BY_REGION } from '@bp/shared/models/countries';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { CheckoutSession } from '@bp/checkout-frontend/models';
import { AppService } from '@bp/checkout-frontend/providers';

const europeRegionCountries = COUNTRIES_BY_REGION.get('Europe')!;

@Component({
	selector: 'bp-wire-transfer-page',
	templateUrl: './wire-transfer-page.component.html',
	styleUrls: [ './wire-transfer-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WireTransferPageComponent {

	protected readonly _appService = inject(AppService);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _wireTransferMethod = this._session.paymentMethods
		.find(paymentMethod => paymentMethod.type === PaymentOptionType.wireTransfer)!;

	protected _requisites = (<{ requisites: Dictionary<any>[] }> this._wireTransferMethod.parsedJson![0]).requisites;

	protected _rows = [
		'beneficiary',
		'beneficiary_address',
		'bank_name',
		'bank_details',
		'bank_address',
		'bank_branch_code',
		'swift_bic_code',
		'swift',
		'client_reference',
		'account_holder',
		'account_number',
		'beneficiary_account_number',
		'iban',
		'account_holders_name',
		'account_holders_address',
		'payment_instructions',
		'intermediary_bank',
		'intermediary_bank_address',
		'intermediary_swift',
		'bank_to_bank_information',
		'minimum_deposit',
		'routing_number',
	].map(property => ({
		prop: property,
		label: this.__getLabel(property),
	}));

	constructor() {
		this._requisites = this._requisites
			.map(model => mapKeys(model, (v, k) => snakeCase(k)));

		this._requisites
			.forEach(requisite => (requisite['currency'] = requisite['currency'] instanceof FiatCurrency
				? requisite['currency']
				: new FiatCurrency(requisite['currency'])));

		this.__tryFilterModelsByEuCountries();
	}

	private __getLabel(property: string): string {
		switch (property) {
			case 'swift_bic_code':
				return 'SWIFT/BIC code';

			case 'iban':
				return 'IBAN';

			default:
				return startCase(property);
		}
	}

	private __tryFilterModelsByEuCountries(): void {
		const hasAllowedCountriesProperty = this._requisites.some(v => has(v, 'allowed_countries'));

		if (!hasAllowedCountriesProperty)
			return;

		this._requisites = this._requisites.filter(v => v['allowed_countries'] === 'EU'
			? europeRegionCountries.includes(this._session.country)
			: (v['allowed_countries'] === 'Non-EU'
				? !europeRegionCountries.includes(this._session.country)
				: true));
	}
}
