<ng-container *ngIf="_appService.session$ | async as session">
	<div class="left">
		<a
			*ngIf="_appService.isTab"
			type="button"
			mat-icon-button
			color="primary"
			class="btn-icon lg btn-back"
			[href]="session?.backUrl"
		>
			<mat-icon>arrow_back</mat-icon>
		</a>

		<bp-secure-payment-static-text
			*ngIf="session.theme?.isBright && !session.isPaymentCardTokenCheckout && !_appService.isPaywithMode"
		/>

		<img *ngIf="_appService.isPaywithMode" class="paywith-logo" src="assets/paywith-logo.svg" />
	</div>

	<div class="right buttons">
		<bp-language-select *ngIf="!session?.hideLanguagesDropdown" />

		<button
			*ngIf="_appService.isModal"
			type="button"
			mat-icon-button
			color="primary"
			class="btn-icon lg btn-close"
			(click)="closeModal()"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</ng-container>
