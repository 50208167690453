import { DTO, MetadataEntity, Required } from '@bp/shared/models/metadata';

export class ThreeDsProcessingAuthenticateRequest extends MetadataEntity {

	@Required()
	bpTransactionId!: string;

	@Required()
	browserInfo!: string;

	constructor(dto: DTO<ThreeDsProcessingAuthenticateRequest>) {
		super(dto);
	}
}
