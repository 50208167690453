import { ApmModule } from '@elastic/apm-rum-angular';
import { noop } from 'lodash-es';

import { APP_INITIALIZER, ModuleWithProviders, NgModule, inject } from '@angular/core';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { LocalBackendState, MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { EnvironmentService } from '@bp/frontend/services/environment';

import { RealUserMonitoringReporter } from './real-user-monitoring.reporter';

@NgModule({
	imports: [
		ApmModule,
	],
})
export class SharedFeaturesRealUserMonitoringModule {

	static forRoot(): ModuleWithProviders<SharedFeaturesRealUserMonitoringModule> {
		return {
			ngModule: SharedFeaturesRealUserMonitoringModule,
			providers: [
				{
					provide: APP_INITIALIZER,
					useFactory: () => {
						const environment = inject(EnvironmentService);

						const isRumOn = !!environment.realUserMonitoring && environment.isDeployed && !environment.isDevEnvironment && !LocalBackendState.isActive && !MockedBackendState.isActive;

						if (!isRumOn)
							return noop;

						const realUserMonitoringReporter = inject(RealUserMonitoringReporter);

						return () => void TelemetryService
							.registerReporter('RealUserMonitoring', realUserMonitoringReporter);
					},
					multi: true,
				},
			],
		};
	}

}
