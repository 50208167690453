import { Observable, Subject, filter, shareReplay } from 'rxjs';
import { Memoize } from 'typescript-memoize';

import { Injectable } from '@angular/core';

import {
	BLOX_EVENT_SCOPE, BloxHostEvent, BloxControllerHostEvent, BloxHostEventLiterals, BloxControllerHostEventLiterals, EventPayload
} from '@bp/frontend/domains/checkout/core';

type BloxHostPostMessagePayload = {
	event?: BloxControllerHostEventLiterals | BloxHostEventLiterals;
	[key: string]: any;
};

type HostEvent<T extends EventPayload> = BloxControllerHostEvent<T> | BloxHostEvent<T>;

type HostEventWithPayload = EventPayload & {
	event: HostEvent<any>;
};

@Injectable({
	providedIn: 'root',
})
export class BloxHostEventsListenerService {

	private readonly __events$ = new Subject<HostEventWithPayload>();

	constructor() {
		window.addEventListener('message', ({ data }: { data?: BloxHostPostMessagePayload }) => {
			if (!data?.event?.includes(BLOX_EVENT_SCOPE) || window.self === window.parent)
				return;

			const { event, ...payload } = data;

			const [ , eventLiteral ] = event.split(BLOX_EVENT_SCOPE);

			this.__events$.next({
				event: BloxHostEvent.parse(eventLiteral) ?? BloxControllerHostEvent.parseStrict(eventLiteral),
				...payload,
			});
		});

	}

	@Memoize()
	on<T extends EventPayload>(targetEvent: HostEvent<T>): Observable<T> {
		return <Observable<T>><unknown> this.__events$.pipe(
			filter(({ event }) => event === targetEvent),
			shareReplay({ refCount: false, bufferSize: 1 }),
		);
	}
}
