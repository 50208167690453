import { firstValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { BpError } from '@bp/frontend/models/core';

import { TransactionInfo } from '@bp/checkout-frontend/models';
import { PaymentApiService, SessionResolver } from '@bp/checkout-frontend/providers';

@Injectable({
	providedIn: 'root',
})
export class StatusTransactionResolver {

	constructor(
		private readonly sessionDataResolver: SessionResolver,
		private readonly paymentsApi: PaymentApiService,
	) { }

	async resolve(route: ActivatedRouteSnapshot): Promise<TransactionInfo> {
		await this.sessionDataResolver.resolve(route); // to get the auth token to make the next call to the api

		try {
			return await firstValueFrom(this.paymentsApi.transaction(
				route.params['transactionId'] || route.queryParams['id'],
			));
		} catch (error: unknown) {
			if (error instanceof BpError && (<TransactionInfo | undefined>error.payload)?.status)
				return new TransactionInfo(error.payload);

			throw error;
		}
	}
}
