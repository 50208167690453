import '@bp/frontend/startup';
import { bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { TelemetryService } from '@bp/frontend/services/telemetry';

import { AppModule } from './app/app.module';
import { RootComponent } from './app/core';

redirectHostCheckoutToCheckoutHrefWhenCheckoutWasPutInsidePspIframe();

/**
 * We bootstrap app in next event loop because we have microtasks `bpQueueMicrotask` we need to finish
 * executing before running main app
 */
setTimeout(async () => bootstrapApplication(RootComponent, {
	providers: [
		provideAnimations(),
		provideZoneChangeDetection({
			ignoreChangesOutsideZone: true,
			eventCoalescing: true,
			runCoalescing: true,
		}),
		importProvidersFrom(AppModule),
	],
})
	.catch(TelemetryService.appErrorHandler));

/**
 * Sometimes the back loads checkout status page inside our psp iframe, which doesn't make sense, so instead we take
 * the parent checkout iframe and redirect it to psp iframe checkout location.
 */
function redirectHostCheckoutToCheckoutHrefWhenCheckoutWasPutInsidePspIframe(): void {
	try {
		if (window.parent !== window.self && window.parent.origin && window.location.href.includes('/status')) {
			window.parent.postMessage({ event: '[bp]:redirect-master-checkout-to-status-page' }, '*');

			window.parent.bpAppService.navigateByUrl(
				window.location.href.replace(window.location.origin, ''),
			);
		}
	} catch {
	}
}
