import m from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom, lastValueFrom, timer } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';

import { AsyncFlashSubject, ZoneService } from '@bp/frontend/rxjs';
import { TelemetryService } from '@bp/frontend/services/telemetry';

/**
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 5 mins.
 * 3. Whenever an update is available, it activates the update and shows a notification to update
 * 4. Logs each time PWA gets installed
 *
 */
@Injectable({
	providedIn: 'root',
})
export class ProgressiveWebAppService {

	private readonly _swUpdateService = inject(SwUpdate);

	private readonly _toaster = inject(ToastrService);

	private readonly _zoneService = inject(ZoneService);

	private readonly _newVersionIsAvailable$ = new AsyncFlashSubject<true>();

	newVersionIsAvailable$ = this._newVersionIsAvailable$.asObservable();

	private readonly _checkNewVersionIntervalOnStartup = 5000;

	private readonly _checkNewVersionIntervalOnStartupTimeout = 1000 * 30;

	private readonly _checkNewVersionInterval = 1000 * 60 * 5; // Each 5 mins

	constructor() {
		this._captureMessageWhenPWAGotInstalled();

		this._captureMessageWhenPWAOpenAsStandalone();

		this._forceReloadAppOnUnrecoverableStateError();

		this._captureErrorWhenVersionInstallationFailed();
	}

	async listenForNewVersion(): Promise<void> {
		if (!this._swUpdateService.isEnabled)
			return;

		await this._whenOnStartupNewVersionAvailableForceAppReload();

		await this._waitForNewVersion();

		this._newVersionIsAvailable$.complete(true);
	}

	reloadApp(): void {
		globalThis.location.reload();
	}

	whenNewVersionAvailableReloadApp(): void {
		if (!this._swUpdateService.isEnabled)
			return;

		void this._zoneService.runOutsideAngular(async () => {
			await this._waitForNewVersion();

			this.reloadApp();
		});
	}

	private async _whenOnStartupNewVersionAvailableForceAppReload(): Promise<void> {
		const hasNewVersion = await this._whenNewVersionAvailableDuringStartupShowToast();

		hasNewVersion && this.reloadApp();
	}

	private async _waitForNewVersion(): Promise<void> {
		const intervalCheckingSubscription = this._inIntervalCheckForUpdate(this._checkNewVersionInterval);

		await firstValueFrom(
			this._swUpdateService
				.versionUpdates
				.pipe(filter(event => event.type === 'VERSION_READY')),
		);

		intervalCheckingSubscription.unsubscribe();

		this._log('A new version is ready');
	}

	private _forceReloadAppOnUnrecoverableStateError(): void {
		this._swUpdateService.unrecoverable
			.subscribe(() => void this.reloadApp());
	}

	private async _whenNewVersionAvailableDuringStartupShowToast(): Promise<boolean> {
		this._log('Check a new version is available during startup, and activate it');

		const intervalCheckingSubscription = this._inIntervalCheckForUpdate(this._checkNewVersionIntervalOnStartup);

		const versionReadyEvent: VersionReadyEvent | null = await firstValueFrom(
			this._swUpdateService
				.versionUpdates
				.pipe(
					first((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
					takeUntil(timer(this._checkNewVersionIntervalOnStartupTimeout)),
				),
			{ defaultValue: null },
		);

		intervalCheckingSubscription.unsubscribe();

		if (!versionReadyEvent) {
			this._log('No new version is available during startup');

			return false;
		}

		this._showNewVersionIsAvailableToast();

		await lastValueFrom(timer(3000)); // Time to read the message

		this._log('The new version is ready');

		return true;
	}

	private _showNewVersionIsAvailableToast(): void {
		this._log('Show new version is available toast');

		this._toaster.info(
			'A new version is available. The page will be reloaded in a moment.',
			undefined,
			{ disableTimeOut: true, tapToDismiss: false },
		);
	}

	private _inIntervalCheckForUpdate(interval: number): Subscription {
		this._log(`Initiate check for update each ${ interval } ms`);

		return timer(0, interval)
			.subscribe(() => void this._checkForUpdate());
	}

	private async _checkForUpdate(): Promise<void> {
		this._log('Checking for update...');

		await this._swUpdateService.checkForUpdate();
	}

	private _captureErrorWhenVersionInstallationFailed(): void {
		this._zoneService.runOutsideAngular(() => {
			this._swUpdateService
				.versionUpdates
				.pipe(filter(this.__isInstallationErrorToCapture))
				.subscribe(event => void TelemetryService.captureError('Version installation failed', event));
		});
	}

	private _log(message: string): void {
		console.log(`%c[PWA][${ m().format('LLL') }]: ${ message }`, 'color:#fd720c;');
	}

	private _captureMessageWhenPWAGotInstalled(): void {
		globalThis.addEventListener('appinstalled', () => void TelemetryService.captureMessage('PWA got installed'));
	}

	private _captureMessageWhenPWAOpenAsStandalone(): void {
		if (globalThis.matchMedia('(display-mode: standalone)').matches)
			TelemetryService.captureMessage('PWA is started as standalone');
	}

	private __isInstallationErrorToCapture(this: void, event: VersionEvent): boolean {
		if (event.type !== 'VERSION_INSTALLATION_FAILED')
			return false;

		return ![
			'Unexpected internal error',
			'Failed to fetch',
		]
			.some(errorSubstring => event.error.includes(errorSubstring));
	}
}
