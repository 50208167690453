import { inject, Injectable } from '@angular/core';

import { CookiesService } from '@bp/frontend/services/storage';
import { EnvironmentService } from '@bp/frontend/services/environment';

@Injectable({
	providedIn: 'root',
})
export class CookieWebAppVersionService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __cookiesService = inject(CookiesService);

	constructor() {
		this.__cookiesService.set(
			`${ this.__environment.appVersionLiteral }__${ location.hostname }__`,
			this.__environment.appVersion.releaseTitle,
			{ domain: location.hostname },
		);
	}

}
