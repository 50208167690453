import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { HomeRoutingModule } from './home-routing.module';
import { HomePageComponent } from './pages';

const EXPOSED = [
	HomePageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		HomeRoutingModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class HomeModule { }
