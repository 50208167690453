// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular-ivy';
import { noop } from 'lodash-es';

import { APP_INITIALIZER, ErrorHandler, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';

import { APP_STORAGE_PREFIX } from '@bp/shared/models/core';

import { ZoneService } from '@bp/frontend/rxjs';
import { AppStorageService, LocalStorageService, SessionStorageService } from '@bp/frontend/services/storage';
import { LocalBackendState, MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import {
	HttpMockResponseRetryInterceptor, HttpRequestInterceptorService, HttpResponseInterceptorService,
	NoopInterceptor
} from '@bp/frontend/services/http';
import { AppErrorHandler } from '@bp/frontend/services/telemetry';
import { GeolocationService } from '@bp/frontend/features/geolocation';

import { WindowService } from './window.service';
import { CookieWebAppVersionService } from './cookie-web-app-version.service';
import { SupportAgentDetectorService } from './support-agent-detector.service';

@NgModule()
export class SharedServicesModule {

	static forRoot(): ModuleWithProviders<SharedServicesModule> {
		return {
			ngModule: SharedServicesModule,
			providers: [
				{
					provide: APP_INITIALIZER,
					useFactory: () => noop,
					deps: [
						CookieWebAppVersionService,
						Sentry.TraceService,
						ZoneService,
						WindowService,
						GeolocationService,
					],
					multi: true,
				},
				{
					provide: AppStorageService,
					useFactory: () => new AppStorageService({
						prefix: APP_STORAGE_PREFIX,
						storageAreaType: inject(SupportAgentDetectorService).isSupportAgent
						|| MockedBackendState.isActive
							? 'sessionStorage'
							: 'localStorage',
					}),
				},
				{
					provide: SessionStorageService,
					useFactory: () => new SessionStorageService({
						prefix: APP_STORAGE_PREFIX,
					}),
				},
				{
					provide: LocalStorageService,
					useFactory: () => new LocalStorageService({
						prefix: APP_STORAGE_PREFIX,
					}),
				},
				{ provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptorService, multi: true },
				{
					provide: HTTP_INTERCEPTORS,
					useClass: MockedBackendState.isActive && LocalBackendState.isActive
						? HttpMockResponseRetryInterceptor
						: NoopInterceptor,
					multi: true,
				},
				{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptorService, multi: true },
				{ provide: ErrorHandler, useClass: AppErrorHandler },
				{
					provide: Sentry.TraceService,
					deps: [ Router ],
				},
			],
		};
	}

}
