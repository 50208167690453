<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<section
	*ngIf="_showProcessingStub || _transaction.isTryAgain || _transaction.isCreditCardTrxInProcess; else defaultTpl"
	class="card status-card"
>
	<bp-processing-stub
		class="inherit-color"
		[show]="true"
		[redirecting]="_session.showRedirectMessageOnFinalStatusPage"
	/>
	<span class="invisible"> just stub to reserve space since bp-processing-stub is absolutely positioned </span>
</section>

<ng-template #defaultTpl>
	<section
		class="card status-card"
		[class.success-card]="_transaction.isApproved"
		[class.pending-card]="_transaction.isPendingOrInProcess"
		[class.error-card]="_transaction.isDeclined || _transaction.isError || _transaction.isCancelled"
	>
		<bp-processing-stub
			[show]="_transaction.isPendingOrInProcess && (_checkingTransaction$ | async) === true"
			[checking]="true"
		/>

		<div class="header">
			<div class="card-logo">
				<ng-container *ngIf="_transaction.isApproved">
					<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
						<path
							d="M17.5 19H2.6A2.65 2.65 0 0 1 0 16.4V2.6A2.65 2.65 0 0 1 2.6 0h22.8A2.65 2.65 0 0 1 28 2.6v7.9a.5.5 0 0 1-1 0V2.6A1.58 1.58 0 0 0 25.4 1H2.6A1.58 1.58 0 0 0 1 2.6v13.8A1.58 1.58 0 0 0 2.6 18h14.9a.5.5 0 0 1 0 1z"
						/>
						<g opacity=".5">
							<path d="M.5 3.4h27v4.2H.5z" />
							<path d="M28 8H0V3h28zM1 7.2h26V3.8H1z" />
						</g>
						<path
							d="M0 3v5h28V3zm27 4H1V4h26zM8 11H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a.94.94 0 0 0 1-1v-2a1 1 0 0 0-1-1zm0 3H4v-2h4zm15-3a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0-1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"
						/>
						<path
							class="stroked tick"
							fill="none"
							stroke="#000"
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M21 15l1.49 1.5 2.7-2.69"
						/>
					</svg>

					<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-success.svg" />
				</ng-container>
				<ng-container *ngIf="_transaction.isDeclined">
					<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
						<path
							d="M17.5 19H2.6A2.65 2.65 0 0 1 0 16.4V2.6A2.65 2.65 0 0 1 2.6 0h22.8A2.65 2.65 0 0 1 28 2.6v7.9a.5.5 0 0 1-1 0V2.6A1.58 1.58 0 0 0 25.4 1H2.6A1.58 1.58 0 0 0 1 2.6v13.8A1.58 1.58 0 0 0 2.6 18h14.9a.5.5 0 0 1 0 1z"
						/>
						<g opacity=".5">
							<path d="M.5 3.4h27v4.2H.5z" />
							<path d="M28 8H0V3h28zM1 7.2h26V3.8H1z" />
						</g>
						<path
							d="M0 3v5h28V3zm27 4H1V4h26zM8 11H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a.94.94 0 0 0 1-1v-2a1 1 0 0 0-1-1zm0 3H4v-2h4zm15-3a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0-1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"
						/>
						<path
							class="stroked cross"
							fill="none"
							stroke="#000"
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M21.5 13.5l3 3m0-3l-3 3"
						/>
					</svg>

					<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-failure.svg" />
				</ng-container>
				<ng-container *ngIf="_transaction.isPendingOrInProcess">
					<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
						<path
							d="M17.5 19H2.6C1.2 19 0 17.8 0 16.4V2.6C0 1.2 1.2 0 2.6 0h22.8C26.8 0 28 1.2 28 2.6v7.9c0 .3-.2.5-.5.5s-.5-.2-.5-.5V2.6c0-.9-.7-1.6-1.6-1.6H2.6C1.7 1 1 1.7 1 2.6v13.8c0 .9.7 1.6 1.6 1.6h14.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
						/>
						<g opacity=".5">
							<path d="M.5 3.4h27v4.2H.5V3.4z" />
							<path d="M28 8H0V3h28v5zM1 7.2h26V3.8H1v3.4z" />
						</g>
						<path
							d="M0 3v5h28V3H0zm27 4H1V4h26v3zM8 11H4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h4c.5 0 1-.4 1-.9V12c0-.6-.4-1-1-1zm0 3H4v-2h4v2zm15-3c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4m0-1c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z"
						/>
						<path
							d="M21 12v2l1.8 1-1.8 1v2h4v-2l-1.8-1 1.8-1v-2h-4zm3 4.3v.7h-2v-.8l1-1.3 1 1.4zM23 15l-1-1.3V13h2v.8L23 15z"
						/>
					</svg>

					<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-pending.svg" />
				</ng-container>
			</div>

			<div class="heading">
				<ng-container *ngIf="_session.isRegularLikeCheckout">
					<div class="title">
						{{ getTexts().title | translate }}
					</div>
				</ng-container>

				<ng-container *ngIf="_session.isPayWithCheckout && _transaction.isPendingOrInProcess">
					<div class="title">
						<bp-payment-instance-out-of-total-text />
						{{ 'is pending' | translate }}
					</div>

					<div class="slogan">
						<div *ngIf="_paymentOptionInstancesManager.isActiveInstanceLast">
							{{ 'You will be automatically redirected' | translate }}
						</div>
						<div *ngIf="!_paymentOptionInstancesManager.isActiveInstanceLast">
							{{ 'You will be automatically redirected to complete the rest payments' | translate }}
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<ng-container *ngIf="_session.isRegularLikeCheckout">
			<div *ngIf="getTexts().description as description" class="card-content">
				<div class="text capitalize-first-letter">
					{{ description | translate }}
				</div>

				<div *ngIf="getTexts().description2 as description2" class="text capitalize-first-letter">
					{{ description2 | translate }}
				</div>
			</div>
		</ng-container>

		<table
			*ngIf="_transaction.transaction_id || _transaction.amount || _transaction.processing_date"
			class="card-table"
			@fade
		>
			<tbody>
				<tr *ngIf="_transaction.transaction_id">
					<td>{{ 'transaction-id' | translate }}:</td>
					<td>{{ _transaction.transaction_id }}</td>
				</tr>

				<tr *ngIf="_transaction.formatted_masked_credit_card_number">
					<td>{{ 'card' | translate }}:</td>
					<td>{{ _transaction.formatted_masked_credit_card_number }}</td>
				</tr>

				<tr
					*ngIf="
						_transaction &&
						_transaction.amount &&
						!_transaction?.details &&
						!_transaction?.installment_details
					"
				>
					<td>{{ 'amount' | translate }}</td>
					<td>{{ _transaction.currency?.symbol }}{{ _transaction.amount }}</td>
				</tr>

				<tr *ngIf="_transaction.processingDateDisplay">
					<td>{{ 'date' | translate }}:</td>
					<td>{{ _transaction.processingDateDisplay }}</td>
				</tr>

				<ng-container *ngIf="_transaction.details as details">
					<tr *ngFor="let detail of details | keyvalue: _originalOrderKeyValueComparator">
						<td>{{ detail.key | titleCase }}:</td>
						<td>{{ detail.value }}</td>
					</tr>
				</ng-container>

				<ng-container *ngIf="_transaction.installment_details as installment_details">
					<tr
						*ngFor="
							let installmentDetail of installment_details | keyvalue: _originalOrderKeyValueComparator
						"
					>
						<td>{{ installmentDetail.key | titleCase }}:</td>
						<td>{{ installmentDetail.value }}</td>
					</tr>
				</ng-container>
			</tbody>
		</table>

		<div *ngIf="_canContinue" class="subtext redirect-info" @fade>
			{{ 'count-down.1' | translate }}
			<b>{{ _countDown$ | async }}</b>
			{{ 'count-down.2' | translate }}
		</div>

		<div
			*ngIf="_canContinue"
			class="buttons right"
			@fade
			[class.card-float-button-position]="_session.isRegularLikeCheckout && _appService.isNotBrightTheme"
		>
			<button class="btn" type="button" (click)="_continue()">
				{{ 'btn.continue' | translate }}
			</button>
		</div>
	</section>
</ng-template>
