import initHelpHero, { HelpHero } from 'helphero';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class HelpHeroService {

	private _helpHero?: HelpHero;

	init(appId: string): void {
		this._helpHero = initHelpHero(appId);
	}

	identify(email: string, data?: Parameters<HelpHero['identify']>[1]): void {
		this._helpHero?.identify(email, data);
	}

	update(data: Parameters<HelpHero['update']>[0]): void {
		this._helpHero?.update(data);
	}

	cancelTour(): void {
		this._helpHero?.cancelTour();
	}
}
