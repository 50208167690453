<div
	bpPositioningStrategy="fixed"
	[bpTooltip]="_isCopied ? copiedText : copyText"
	[bpTooltipHideOnClick]="!copiedText"
	[bpTooltipShowOnCreate]="!copyText"
	(mousedown)="copy($event)"
	(click)="$event.stopPropagation()"
>
	<ng-content />
</div>
