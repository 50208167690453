import luhn from 'fast-luhn';
import { range, reduce, min, max, isString } from 'lodash-es';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PaymentCardBrand, PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { Dictionary } from '@bp/shared/typings';
import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';
import { TextMask, TextMaskConfig } from '@bp/shared/features/text-mask';
import { hasMaskedChars } from '@bp/shared/utilities/core';

import { PaymentCardBrandIconComponent } from '@bp/frontend/components/payment-card/brand';
import { InputComponent, SharedComponentsControlsModule } from '@bp/frontend/components/controls';
import { FADE } from '@bp/frontend/animations';
import { FormFieldControlComponent } from '@bp/frontend/components/core';

@Component({
	selector: 'bp-payment-card-number-input',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,

		SharedComponentsControlsModule,

		PaymentCardBrandIconComponent,
	],
	templateUrl: './payment-card-number-input.component.html',
	styleUrls: [ './payment-card-number-input.component.scss' ],
	animations: [ FADE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusout)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PaymentCardNumberInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PaymentCardNumberInputComponent,
			multi: true,
		},
	],
})
export class PaymentCardNumberInputComponent extends FormFieldControlComponent<string | null> {

	@Input() skipLuhnValidation = false;

	@Output()
	readonly paymentCardBrandChange = new EventEmitter<PaymentCardBrand | null>();

	@ViewChild(InputComponent, { static: true })
	private readonly _input!: InputComponent;

	protected _mask = new TextMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		placeholderFromMask: false,
		guide: false,
		maskOnFocus: false,
		mask: (numberInputValue: string) => this.__buildMaskAccordingToValueLengthSplittedInGroupsOfFour(
			numberInputValue,
		),
	});

	protected _paymentCardBrand: PaymentCardBrand | null = null;

	private readonly _defaultMinPaymentCardNumberLength = 12;

	private __minPaymentCardNumberLength: number = this._defaultMinPaymentCardNumberLength;

	private readonly __defaultMaxPaymentCardNumberLength = 19;

	private __maxPaymentCardNumberLength: number = this.__defaultMaxPaymentCardNumberLength;

	private readonly __masksCache: Dictionary<TextMask> = {};

	private readonly __cardNumberCharRegexp = new RegExp(`\\d|${ PaymentCardsUtils.maskDotChar }`, 'u');

	focus(): void {
		this._input.focus();
	}

	protected override _onInternalControlValueChange(value: string | null): void {
		if (value?.includes(PaymentCardsUtils.maskDotChar)) {
			this._eraseInternalControlValue();

			return;
		}

		super._onInternalControlValueChange(value);
	}

	private readonly __buildMaskAccordingToValueLengthSplittedInGroupsOfFour = (numberInputValue: string): TextMask => {
		this.__guessAndSetPaymentCardBrand(numberInputValue);

		const maxPaymentCardLength = Math.min(
			this.__maxPaymentCardNumberLength,
			Math.max(this.__minPaymentCardNumberLength, numberInputValue.length),
		);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		return (this.__masksCache[maxPaymentCardLength] ??= reduce(
			range(maxPaymentCardLength),
			(accumulator, _v, index) => {
				index++;

				return [
					...accumulator,
					this.__cardNumberCharRegexp,
					...(index % 4 || index === maxPaymentCardLength ? [] : [ ' ' ]),
				];
			},
			<TextMask>[],
		));
	};

	// #region Implementation of the Validator interface

	protected override _validator: IValidatorFunc<string | null> | null = ({ value }) => {
		if (Validators.isEmptyValue(value) || hasMaskedChars(value))
			return null;

		return Validators.compose([
			Validators.minLength(this.__minPaymentCardNumberLength),
			this.__luhnValidation,
			this.__israCardValidation,
		])!({ value });
	};

	private readonly __luhnValidation: IValidatorFunc<string > = ({ value: cardNumber }) => {
		if (this.skipLuhnValidation || this._paymentCardBrand?.scheme.skipLuhn)
			return null;

		if (!isString(cardNumber))
			throw new Error('`luhn` validator expects string to be validated');

		return luhn(cardNumber)
			? null
			: { ccNumber: true };
	};

	private readonly __israCardValidation: IValidatorFunc<string | null> = ({ value: cardNumber }) => {
		if (!isString(cardNumber))
			throw new Error('`israCard` validator expects string to be validated');

		if (cardNumber.length < 8 || cardNumber.length > 9)
			return null;

		const sum = [ ...cardNumber ].map(Number).reduce(
			(accumulator, value, index) => accumulator + value * (cardNumber.length - index),
			0,
		);

		return sum % 11 === 0 ? null : { ccNumber: true };
	};

	// #endregion Implementation of the Validator interface

	private __guessAndSetPaymentCardBrand(number: string): void {
		const guessedPaymentCardBrand = PaymentCardsUtils.guessBestMatchingBrandByCardNumber(number);

		this.__setMinMaxPaymentCardNumberLengths(guessedPaymentCardBrand);

		if (guessedPaymentCardBrand !== this._paymentCardBrand) {
			this._paymentCardBrand = guessedPaymentCardBrand;

			this.paymentCardBrandChange.emit(guessedPaymentCardBrand);
		}
	}

	private __setMinMaxPaymentCardNumberLengths(guessedPaymentCardBrand: PaymentCardBrand | null): void {
		this.__minPaymentCardNumberLength = guessedPaymentCardBrand
			? min(guessedPaymentCardBrand.scheme.lengths)!
			: this._defaultMinPaymentCardNumberLength;

		this.__maxPaymentCardNumberLength = guessedPaymentCardBrand
			? max(guessedPaymentCardBrand.scheme.lengths)!
			: this.__defaultMaxPaymentCardNumberLength;
	}
}
