/* eslint-disable @typescript-eslint/naming-convention */
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';

import { CheckoutSession, EmbeddedData, IRequestSession } from '@bp/checkout-frontend/models';

import { EmbeddedDataResolver } from './embedded-data.resolver';
import { SessionApiService } from './api';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root',
})
export class SessionResolver implements Resolve<CheckoutSession | null> {

	private readonly __sessionApiService = inject(SessionApiService);

	private readonly __appService = inject(AppService);

	private readonly __hostNotifierService = inject(HostNotifierService);

	private readonly __embeddedDataResolver = inject(EmbeddedDataResolver);

	private readonly __telemetry = inject(TelemetryService);

	async resolve(route: ActivatedRouteSnapshot): Promise<CheckoutSession | null> {
		return this.__fetchSession(route);
	}

	private async __fetchSession(route: ActivatedRouteSnapshot): Promise<CheckoutSession | null> {
		const embedded = await this.__embeddedDataResolver.resolve(route);

		if (!embedded) {
			this.__appService.setError('page.error.empty-embedded-params');

			this.__appService.navigate([ '/error' ]);

			return null;
		}

		if (this.__appService.session)
			return this.__appService.session;

		const getApiSession$ = this.__getSession(embedded, route.data['cashierFirstInitialization'])
			.pipe(catchError((error: unknown) => {
				this.__appService.removeAlertBeforeUnload();

				this.__hostNotifierService.sessionInit({ type: 'failure' });

				return throwError(error);
			}));

		const apiSession = await firstValueFrom(getApiSession$);

		try {
			if (apiSession.checkoutKey && this.__appService.isRegularMerchant()) {
				const isSuspended = await firstValueFrom(
					this.__sessionApiService.getSuspensionStatus(apiSession.checkoutKey),
				);

				apiSession.isSuspended = isSuspended;
			}
		} catch (error: unknown) {
			TelemetryService.captureError(error);
		}

		const theme = embedded.dto.theme ?? apiSession.dto.theme;

		const session = new CheckoutSession({
			...(embedded.preventOverrideBySession
				? {
					...apiSession.dto,
					...embedded.dto,
				}
				: {
					...embedded.dto,
					...apiSession.dto,
				}
			),
			initialCheckoutType: apiSession.dto.checkoutType,
			initialTheme: theme,
			theme,
		});

		session.isSuspended = apiSession.isSuspended;

		if (session.isSuspended) {
			this.__appService.setError('Organization is suspended');

			this.__appService.navigate([ '/error' ]);
		}

		this.__appService.newSession(session);

		this.__hostNotifierService.sessionInit({ type: 'success' });

		if (session.isDemostand) {
			this.__telemetry.userSessionRecordingUrl$?.subscribe(
				url => void this.__hostNotifierService.logrocketSessionUrl(url),
			);
		}

		if (!session.isPayWithCheckout && !session.isPaymentLinkCheckout && session.isClosed && !route.data['dontHandleSessionClosedState'])
			this.__navigateToSessionClosedPageAndCloseTabIfStandalone();

		return session;
	}

	private __getSession(embedded: EmbeddedData, checkoutFirstInitialization: boolean): Observable<CheckoutSession> {
		const request: IRequestSession = this.__buildSessionRequestFromEmbeddedData(embedded);

		return embedded.checkoutSessionId
			? (checkoutFirstInitialization && embedded.checkoutKey
				? this.__sessionApiService.getOnFirstCashierInit(embedded.checkoutSessionId, request)
				: this.__sessionApiService.get(embedded.checkoutSessionId))
			: this.__sessionApiService
				.create(request);
	}

	private __buildSessionRequestFromEmbeddedData(embedded: EmbeddedData): IRequestSession {
		return {
			cashier_key: embedded.checkoutKey!,
			theme: embedded.theme.name,
			currency: embedded.currencyCode,
			order_id: embedded.orderId,
			platform_id: embedded.platformId,
			email: embedded.email.raw,
			first_name: embedded.firstName.raw,
			last_name: embedded.lastName.raw,
			card_holder_name: embedded.cardHolderName.value,
			country: embedded.countryCode.value,
			currency_lock: embedded.currencyLock,
			amount: Number(embedded.amount.raw) || 0,
			amount_lock: embedded.amountLock,
			birth_date: embedded.birthDate,
			deposit_button_text: embedded.depositButtonText,
			pay_mode: embedded.payMode,
			hide_languages_dropdown: embedded.hideLanguagesDropdown,
			hide_ui: embedded.hideUi,
			show_redirect_message: embedded.showRedirectMessageOnFinalStatusPage,
			show_processing_message_on_final_status_page: embedded.showProcessingMessageOnFinalStatusPage,
			show_redirect_message_on_final_status_page: embedded.showRedirectMessageOnFinalStatusPage,
			tick_save_credit_card_checkbox_by_default: embedded.tickSaveCreditCardCheckboxByDefault,
			hide_save_credit_card_checkbox: embedded.hideSaveCreditCardCheckbox,
			show_placeholder_for_card_number: embedded.showPlaceholderForCardNumber,
			show_placeholder_for_cvv: embedded.showPlaceholderForCvv,
			dont_render_card_holder_name_in_uppercase: embedded.dontRenderCardHolderNameInUppercase,
			hide_card_holder_name_when_full_name_is_available: embedded.hideCardHolderNameWhenFullNameIsAvailable,
		};
	}

	private __navigateToSessionClosedPageAndCloseTabIfStandalone(): void {
		this.__appService.setError('The session is closed or expired!\n Please start over!');

		this.__appService.navigate([ '/error' ]);

		if (this.__appService.isStandalone && !location.hostname.includes('localhost'))
			setTimeout(() => void window.close(), 5000);
	}
}
