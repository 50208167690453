import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SessionResolver } from '@bp/checkout-frontend/providers';
import { matchRoutePathWithVersionPathSegment } from '@bp/checkout-frontend/utilities';

import { DepositProcessingPageComponent } from './deposit-processing-page';

const routes: Routes = [
	{
		matcher: matchRoutePathWithVersionPathSegment('deposit-processing'),
		component: DepositProcessingPageComponent,
		data: {
			dontHandleSessionClosedState: true,
			awaitPaymentDetailsDecryption: true,
		},
		resolve: {
			session: SessionResolver,
		},
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class DepositProcessingRoutingModule { }
