import { isString } from 'lodash-es';

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { BpError } from '@bp/frontend/models/core';

import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: [ './error-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {

	protected readonly _appService = inject(AppService);

	protected readonly _canGoHome = !!inject(ActivatedRoute).snapshot.params['canGoHome'];

	protected readonly _hostNotifier = inject(HostNotifierService);

	protected get _error(): BpError | null {
		return this._appService.error instanceof BpError ? this._appService.error : null;
	}

	protected get _publicErrorText(): string | null {
		return isString(this._appService.error) ? this._appService.error : null;
	}

	constructor() {
		this._appService.markAppReady();

		this._appService.removeAlertBeforeUnload();

		this._hostNotifier.error(this._appService.error ?? 'Unknown error');
	}
}
