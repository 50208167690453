
import { Country, countryMapper } from '@bp/shared/models/countries';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { Mapper, MetadataEntity, Required, booleanMapper, Secret } from '@bp/shared/models/metadata';

import { ThreeDsProcessorProvider } from './three-ds-processor-provider';

export class ThreeDsProcessorsCreateRequest extends MetadataEntity {

	@Required()
	name!: string;

	@Required()
	@Mapper(booleanMapper)
	isEnabled!: boolean;

	@Required()
	@Secret()
	acquirerMerchantId!: string;

	@Required()
	@Mapper(countryMapper)
	merchantCountry!: Country;

	@Required()
	@Mapper(FiatCurrency)
	merchantCurrency!: FiatCurrency;

	@Required()
	@Mapper(ThreeDsProcessorProvider)
	providers!: ThreeDsProcessorProvider[];

}
