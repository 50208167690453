/* eslint-disable @typescript-eslint/naming-convention */
import { BehaviorSubject, fromEvent } from 'rxjs';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import { isString } from 'lodash-es';

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, ChangeDetectionStrategy, inject } from '@angular/core';

import { isURL } from '@bp/shared/utilities/core';
import { IApiResponse } from '@bp/shared/models/core';

import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { DepositProcessingService, AppService } from '@bp/checkout-frontend/providers';
import { IApiTransaction, CheckoutSession, Transaction } from '@bp/checkout-frontend/models';
import { documentWrite } from '@bp/checkout-frontend/utilities';

import { EmbeddedData } from '../../../shared/models/embedded-data';

@Component({
	selector: 'bp-iframe-page',
	templateUrl: './iframe-page.component.html',
	styleUrls: [ './iframe-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IFramePageComponent extends Destroyable implements OnInit, OnDestroy {

	protected readonly _appService = inject(AppService);

	protected readonly _depositProcessingService = inject(DepositProcessingService);

	protected readonly _activatedRoute = inject(ActivatedRoute);

	private readonly _hostNotifier = inject(HostNotifierService);

	@ViewChild('pspIframeOutlet', { static: true })
	protected _pspIframeOutlet!: ElementRef<HTMLDivElement>;

	private get __$pspIframeOutlet(): HTMLDivElement {
		return this._pspIframeOutlet.nativeElement;
	}

	private __$iframe?: HTMLIFrameElement;

	protected get _embedded(): EmbeddedData {
		return this._appService.embedded!;
	}

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _showProcessingStub$ = new BehaviorSubject(true);

	protected get _provider(): string {
		return this._activatedRoute.snapshot.params['provider'] || 'unknown';
	}

	private get __isPayPal(): boolean {
		return this._provider === 'pay_pal';
	}

	private get __isPaybisCrypto(): boolean {
		return this._provider === 'paybis_crypto';
	}

	protected _isRetrying = false;

	private __loadingPSPType: 'first-psp' | 'next-psp' = 'first-psp';

	private __isLoadingPSP = false;

	private __isProcessingPSP = false;

	private __isPspIframeRequestedRedirectingToStatusPage = false;

	constructor() {
		super();

		this._depositProcessingService
			.redepositSuccessHtmlOrURL$
			.pipe(takeUntilDestroyed(this))
			.subscribe(htmlOrURL => {
				this.__notifyHostOnPSPProcessingChange(false);

				this.__initIFrameContent(htmlOrURL, 'next-psp');
			});
	}

	ngOnInit(): void {
		this.__initIFrameContent(this._appService.iframeLinkOrHtml!, 'first-psp');

		this.__listenToIframeMessages();
	}

	ngOnDestroy(): void {
		if (!this.__isPspIframeRequestedRedirectingToStatusPage)
			this.__notifyHostOnPSPProcessingChange(false);

		void this._depositProcessingService.terminated$.next(true);
	}

	private __initIFrameContent(htmlOrLink: string, type: 'first-psp' | 'next-psp'): void {
		this.__loadingPSPType = type;

		this.__$iframe = this.__recreateIFrameElement();

		if (isURL(htmlOrLink))
			this.__$iframe.src = htmlOrLink;
		else
			this.__writeIFrameContent(htmlOrLink);

		this.__notifyHostOnPSPLoadingChange(true);

		this._showProcessingStub$.next(true);

		fromEvent(this.__$iframe, 'load')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this._showProcessingStub$.next(this.__isProcessingPSP);

				this.__notifyHostOnPSPLoadingChange(false);
			});

		fromEvent(this.__$iframe, 'error')
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => {
				TelemetryService.warn('Psp iframe load error', v);

				this._showProcessingStub$.next(false);

				this.__notifyHostOnPSPLoadingChange(false);
			});
	}

	private __notifyHostOnPSPLoadingChange(isLoading: boolean): void {
		if (this.__isLoadingPSP === isLoading)
			return;

		this.__isLoadingPSP = isLoading;

		this._hostNotifier.loadingPSP({
			value: isLoading,
			type: this.__loadingPSPType,
			provider: this._provider,
		});
	}

	private __notifyHostOnPSPProcessingChange(isProcessing: boolean): void {
		if (this.__isProcessingPSP === isProcessing)
			return;

		this.__isProcessingPSP = isProcessing;

		this._hostNotifier.processingPSP({
			value: isProcessing,
			provider: this._provider,
		});
	}

	private __listenToIframeMessages(): void {
		fromEvent<MessageEvent<{ event?: string; data: string; transactionId?: string } | undefined>>(window, 'message')
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ data }) => {
				if (!data?.event?.startsWith('[bp]:'))
					return;

				TelemetryService.log(data.event, data.data);

				const shouldShowProcessingStub = [
					'[bp]:show-processing-stub',
					'[bp]:redirect-master-checkout-to-status-page',
				].includes(data.event);

				if (shouldShowProcessingStub) {
					this._isRetrying = false;

					this._showProcessingStub$.next(true);

					this.__notifyHostOnPSPProcessingChange(true);

					this.__isPspIframeRequestedRedirectingToStatusPage = data.event === '[bp]:redirect-master-checkout-to-status-page';

					return;
				}

				if (data.event === '[bp]:hide-processing-stub') {
					this._showProcessingStub$.next(false);

					this.__notifyHostOnPSPProcessingChange(false);

					return;
				}

				if ([ '[bp]:3d-secure-result', '[bp]:3d-secure-continue' ].includes(data.event)) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const eventData = isString(data.data) ? JSON.parse(data.data) : data.data;
					const response = <IApiResponse<IApiTransaction | { transaction_id: string }>>eventData;

					if (data.event === '[bp]:3d-secure-result') {
						const transaction = new Transaction(<IApiTransaction>response.result);

						this._isRetrying = transaction.isTryAgain;

						if (transaction.isPendingOrInProcess || transaction.isTryAgain) {
							this._showProcessingStub$.next(true);

							this.__notifyHostOnPSPProcessingChange(true);
						}

						this._depositProcessingService.handleAccordingTransaction(
							transaction,
							true,
						);
					}

					if (data.event === '[bp]:3d-secure-continue' && 'transaction_id' in response.result) {
						this._showProcessingStub$.next(true);

						void this._depositProcessingService.continueProcessing({
							transactionId: response.result.transaction_id,
						});
					}
				}

				if (data.event === '[bp]:three-ds-processor-auth-result-ready') {
					this._showProcessingStub$.next(true);

					void this._depositProcessingService.continueProcessing(
						{
							transactionId: data.transactionId!,
							threeDsProcessorAuthResultReady: true,
						},
					);

				}
			});
	}

	private __recreateIFrameElement(): HTMLIFrameElement {
		this.__$iframe?.remove();

		const $iframe = document.createElement('iframe');

		$iframe.setAttribute('id', 'pspIframe');

		$iframe.setAttribute('frameBorder', '0');

		$iframe.setAttribute('allowtransparency', 'true');

		if (this.__isPaybisCrypto) {
			$iframe.setAttribute('allow', 'clipboard-read; clipboard-write *; payment *; camera *; microphone *');

			$iframe.setAttribute('allowpaymentrequest', 'true');
		}

		this.__$pspIframeOutlet.append($iframe);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		this.__isPayPal && iframeResizer({ checkOrigin: false }, this.__$iframe!);

		return $iframe;
	}

	private __writeIFrameContent(html: string): void {
		documentWrite(this.__$iframe!.contentDocument!, html);
	}
}
