import { inject, Injectable } from '@angular/core';

import { EncryptCvvResponse, PaymentCardToken } from '@bp/shared/domains/payment-card-tokens';
import { toPlainObject } from '@bp/shared/utilities/core';

import { BLOX_EVENT_SCOPE, BloxEvent, BloxControllerEvent, EventPayload } from '@bp/frontend/domains/checkout/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';

@Injectable({
	providedIn: 'root',
})
export class BloxHostNotifierService {

	private readonly __telemetry = inject(TelemetryService);

	private __config?: {
		id: string;
		scope: string;
	};

	setConfig(id: BloxHostNotifierService['__config']): void {
		this.__config = id;
	}

	controllerInit(): void {
		void this.__dispatch({
			event: BloxControllerEvent.init,
		});
	}

	controllerInitFailed(payload: typeof BloxControllerEvent.initFailed.payloadSchema): void {
		void this.__dispatch({
			event: BloxControllerEvent.initFailed,
			...payload,
		});
	}

	paymentCardToken(token: PaymentCardToken): void {
		void this.__dispatch({
			event: BloxControllerEvent.paymentCardToken,
			...toPlainObject(token),
		});

	}

	encryptedCvv(response: EncryptCvvResponse): void {
		void this.__dispatch({
			event: BloxControllerEvent.encryptedCvv,
			...toPlainObject(response),
		});
	}

	bloxInit(): void {
		void this.__dispatch({
			event: BloxEvent.init,
		});
	}

	bloxRendered(): void {
		void this.__dispatch({
			event: BloxEvent.rendered,
		});
	}

	blur(payload: typeof BloxEvent.blur.payloadSchema): void {
		void this.__dispatch({
			event: BloxEvent.blur,
			...payload,
		});
	}

	focus(payload: typeof BloxEvent.focus.payloadSchema): void {
		void this.__dispatch({
			event: BloxEvent.focus,
			...payload,
		});
	}

	change(payload: typeof BloxEvent.change.payloadSchema): void {
		void this.__dispatch({
			event: BloxEvent.change,
			...payload,
		});
	}

	validate(payload: typeof BloxEvent.validate.payloadSchema): void {
		void this.__dispatch({
			event: BloxEvent.validate,
			...payload,
		});
	}

	setPaymentCardBrand(payload: typeof BloxEvent.setPaymentCardBrand.payloadSchema): void {
		void this.__dispatch({
			event: BloxEvent.setPaymentCardBrand,
			...payload,
		});
	}

	error(payload: typeof BloxControllerEvent.error.payloadSchema): void {
		void this.__dispatch({
			event: BloxControllerEvent.error,
			...payload,
		});
	}

	private __dispatch<T extends EventPayload>({ event, ...payload }: T & {
		event: BloxControllerEvent<T> | BloxEvent<T>;
	}): void {
		if (!this.__config)
			throw new Error('BloxHostNotifierService: id is not set');

		this.__telemetry.log(`[${ this.__config.scope }]: Host Blox Notification: ${ event }`, payload);

		void window.parent.postMessage(
			{
				event: `${ BLOX_EVENT_SCOPE }${ this.__config.id }:${ event }`,
				...payload,
			},
			'*',
		);
	}
}
