<ng-container *ngIf="_appService.session$ | async as session">
	<div *ngIf="session.theme?.isBright" class="trust-logos-footer">
		<small *ngIf="!session.isMerchantAdmin && session.isFreeSubscriptionPlan" class="mb-2">
			Powered by BridgerPay
		</small>
		<div class="trust-logos-wrapper">
			<bp-picture
				*ngFor="let logoDef of _trustLogosDefs"
				[src]="logoDef.src"
				[alt]="logoDef.alt"
				[svg]="logoDef.svg"
			/>
		</div>
	</div>
</ng-container>

<div class="aux-footer">
	<div>
		<small
			*ngIf="
				_appService.isEmbedded && ((_appService.embedded$ | async)?.isDemostand || _environment.isNotProduction)
			"
			class="mr-2"
		>
			<a target="_blank" class="link interactive" href="{{ _appService.initialUrl }}"> Open in new tab </a>
		</small>

		<bp-developer-overlay-panel [show]="(_appService.embedded$ | async)?.isDemostand" />
	</div>

	<bp-app-version
		[renderVersion]="_environment.isNotProduction || (_appService.embedded$ | async)?.isDemostand === true"
	/>

	<ng-container *ngIf="_appService.session$ | async as session">
		<a
			*ngIf="!session.isMerchantAdmin && !session.theme.isBright && session.isFreeSubscriptionPlan"
			class="powered-by-bridgerpay ml-auto"
			href="https://bridgerpay.com"
			target="_blank"
		>
			<img src="assets/images/bridgerpay-logo.svg" />
			Powered by BridgerPay
		</a>
	</ng-container>
</div>
