import { omit } from 'lodash-es';
import { filter, takeUntil } from 'rxjs/operators';

import { FormControlName } from '@angular/forms';
import { Directive, inject, OnInit } from '@angular/core';

import { Destroyable } from '@bp/frontend/models/common';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[formControlName]' })
export class ClearServerErrorOnFormControlChangeDirective extends Destroyable implements OnInit {

	private readonly __formControlName = inject(FormControlName, { self: true });

	ngOnInit(): void {
		this.__formControlName.valueChanges
			?.pipe(
				filter(() => this.__formControlName.hasError('server')),
				takeUntil(this.destroyed$),
			)
			.subscribe(() => {
				this.__formControlName.control.setErrors(
					omit(this.__formControlName.errors, 'server'),
				);

				this.__formControlName.control.updateValueAndValidity();
			});
	}

}
