<section class="card status-card pending-card">
	<div class="header">
		<div class="card-logo">
			<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
				<path
					d="M17.5 19H2.6C1.2 19 0 17.8 0 16.4V2.6C0 1.2 1.2 0 2.6 0h22.8C26.8 0 28 1.2 28 2.6v7.9c0 .3-.2.5-.5.5s-.5-.2-.5-.5V2.6c0-.9-.7-1.6-1.6-1.6H2.6C1.7 1 1 1.7 1 2.6v13.8c0 .9.7 1.6 1.6 1.6h14.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
				/>
				<g opacity=".5">
					<path d="M.5 3.4h27v4.2H.5V3.4z" />
					<path d="M28 8H0V3h28v5zM1 7.2h26V3.8H1v3.4z" />
				</g>
				<path
					d="M0 3v5h28V3H0zm27 4H1V4h26v3zM8 11H4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h4c.5 0 1-.4 1-.9V12c0-.6-.4-1-1-1zm0 3H4v-2h4v2zm15-3c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4m0-1c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z"
				/>
				<path
					d="M21 12v2l1.8 1-1.8 1v2h4v-2l-1.8-1 1.8-1v-2h-4zm3 4.3v.7h-2v-.8l1-1.3 1 1.4zM23 15l-1-1.3V13h2v.8L23 15z"
				/>
			</svg>

			<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-pending.svg" />
		</div>

		<div class="heading">
			<div class="title">
				{{ 'page.card-pending.title' | translate }}
			</div>
		</div>
	</div>

	<div class="card-content">
		<span class="text">
			<span *ngIf="_session.payMode" [innerHTML]="'page.pay-card-pending.text' | translate"> </span>

			<span *ngIf="!_session.payMode" [innerHTML]="'page.deposit-card-pending.text' | translate"> </span>
		</span>
	</div>

	<table class="card-table">
		<tbody>
			<tr *ngIf="_transaction?.transaction_id">
				<td>{{ 'transaction-id' | translate }}:</td>
				<td>{{ _transaction?.transaction_id }}</td>
			</tr>
			<tr *ngIf="_transaction?.amount">
				<td>{{ 'amount' | translate }}:</td>
				<td>{{ _transaction?.currency?.symbol }}{{ _transaction?.amount }}</td>
			</tr>
			<tr *ngIf="_transaction?.processingDateDisplay">
				<td>{{ 'date' | translate }}:</td>
				<td>{{ _transaction?.processingDateDisplay }}</td>
			</tr>
		</tbody>
	</table>
</section>
