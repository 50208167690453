
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BpError } from '@bp/frontend/models/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { ErrorPageComponent, NotFoundPageComponent } from './core';

// app routing module is for lazy routes

const routes: Routes = [
	{ path: 'error', component: ErrorPageComponent },
	{ path: '**', component: NotFoundPageComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false,
			paramsInheritanceStrategy: 'always',
			bindToComponentInputs: true,
			errorHandler(error: unknown): void {
				if (error instanceof BpError && error.isHttpError)
					return;

				TelemetryService.routerErrorHandler(error);
			},
		}),
	],
	exports: [ RouterModule ],
})
export class AppRoutingModule { }
