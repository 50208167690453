<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<section *ngIf="_showProcessingStub; else defaultTpl" class="card">
	<bp-processing-stub
		class="inherit-color"
		[show]="true"
		[redirecting]="_session.showRedirectMessageOnFinalStatusPage"
	/>
		<span class="invisible"> just stub to reserve space since bp-processing-stub is absolutely positioned </span>
</section>

<ng-template #defaultTpl>
	<section class="card status-card success-card">
		<ng-container *ngIf="_session.isRegularLikeCheckout">
			<div class="header">
				<div class="card-logo">
					<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
						<path
							d="M17.5 19H2.6A2.65 2.65 0 0 1 0 16.4V2.6A2.65 2.65 0 0 1 2.6 0h22.8A2.65 2.65 0 0 1 28 2.6v7.9a.5.5 0 0 1-1 0V2.6A1.58 1.58 0 0 0 25.4 1H2.6A1.58 1.58 0 0 0 1 2.6v13.8A1.58 1.58 0 0 0 2.6 18h14.9a.5.5 0 0 1 0 1z"
						/>
						<g opacity=".5">
							<path d="M.5 3.4h27v4.2H.5z" />
							<path d="M28 8H0V3h28zM1 7.2h26V3.8H1z" />
						</g>
						<path
							d="M0 3v5h28V3zm27 4H1V4h26zM8 11H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a.94.94 0 0 0 1-1v-2a1 1 0 0 0-1-1zm0 3H4v-2h4zm15-3a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0-1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"
						/>
						<path
							class="stroked tick"
							fill="none"
							stroke="#000"
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M21 15l1.49 1.5 2.7-2.69"
						/>
					</svg>

					<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-success.svg" />
				</div>

				<div class="heading">
					<div class="title">{{ _i18nTitleKey | translate }}</div>

					<div class="slogan">{{ _i18nContentKey | translate }}</div>
				</div>
			</div>

			<table class="card-table">
				<tbody>
					<tr>
						<td>{{ 'transaction-id' | translate }}</td>
						<td>{{ _transaction?.transaction_id }}</td>
					</tr>

					<tr *ngIf="_transaction?.formatted_masked_credit_card_number">
						<td>{{ 'card' | translate }}</td>
						<td>{{ _transaction?.formatted_masked_credit_card_number }}</td>
					</tr>

					<tr
						*ngIf="
							_transaction &&
							_transaction.amount &&
							!_transaction?.details &&
							!_transaction?.installment_details
						"
					>
						<td>{{ 'amount' | translate }}</td>
						<td>{{ _transaction.currency?.symbol }}{{ _transaction.amount }}</td>
					</tr>

					<tr>
						<td>{{ 'date' | translate }}</td>
						<td>{{ _transaction?.processingDateDisplay }}</td>
					</tr>

					<ng-container *ngIf="_transaction?.details as details">
						<tr *ngFor="let detail of details | keyvalue: _originalOrderKeyValueComparator">
							<td>{{ detail.key | upperFirst }}</td>
							<td>{{ detail.value }}</td>
						</tr>
					</ng-container>

					<ng-container *ngIf="_transaction?.installment_details as installment_details">
						<tr
							*ngFor="
								let installmentDetail of installment_details
									| keyvalue: _originalOrderKeyValueComparator
							"
						>
							<td>{{ installmentDetail.key | upperFirst }}</td>
							<td>{{ installmentDetail.value }}</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</ng-container>

		<ng-container *ngIf="_session.isPayWithCheckout">
			<div class="header">
				<div class="card-logo">
					<img src="assets/images/payment-success.svg" />
				</div>

				<div class="heading">
					<ng-container *ngIf="_paymentOptionInstancesManager.isCompleted">
						<div class="title">{{ 'Thank you!' | translate }}</div>

						<div class="slogan">
							{{ 'Your payment has been successfully processed.' | translate }}
						</div>
					</ng-container>

					<ng-container *ngIf="_paymentOptionInstancesManager.isPartiallyCompleted">
						<div class="title">
							<bp-payment-instance-out-of-total-text />
							{{ 'was successful' | translate }}
						</div>

						<div class="slogan">
							<div>{{ 'You will be automatically redirected' | translate }}</div>
							<div>{{ 'to complete the rest payments' | translate }}</div>
						</div>
					</ng-container>
				</div>
			</div>

			<div *ngIf="_paymentOptionInstancesManager.isCompleted" class="card-content">
				<div class="summary">
					<div class="summary-item">
						<div class="summary-item-label">{{ 'Total amount' | translate }}</div>
						<div class="summary-item-value">
							{{ _session.$amount | currency: _session.currency.code : 'symbol' : '1.0-2' }}
						</div>
					</div>

					<div class="summary-item">
						<div class="summary-item-label">{{ 'Order ID' | translate }}</div>
						<div class="summary-item-value">{{ _session.orderId }}</div>
					</div>
				</div>

				<div class="transactions-summary">
					<div *ngFor="let instance of _paymentOptionInstancesManager.instances" class="transaction">
						<div class="psp-logo">
							<bp-payment-option-logo [paymentOption]="instance.paymentOption" />
						</div>

						<div class="date">{{ instance.processingDateDisplay }}</div>

						<div class="amount">
							{{ instance.amount | currency: _session.currency.code : 'symbol' : '1.0-2' }}
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<div *ngIf="_canContinue" class="subtext redirect-info">
			{{ 'count-down.1' | translate }}
			<b>{{ _countDown$ | async }}</b>
			{{ 'count-down.2' | translate }}
		</div>

		<div
			class="buttons right"
			[class.card-float-button-position]="_session.isRegularLikeCheckout && _appService.isNotBrightTheme"
		>
			<button *ngIf="_canContinue" class="btn" type="button" (click)="_continue()">
				{{ 'btn.continue' | translate }}
			</button>
		</div>
	</section>
</ng-template>
