<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<section *ngIf="_showProcessingStub; else defaultTpl" class="card status-card">
	<bp-processing-stub
		class="inherit-color"
		[show]="true"
		[redirecting]="_session.showRedirectMessageOnFinalStatusPage"
	/>
	<span class="invisible"> just stub to reserve space since bp-processing-stub is absolutely positioned </span>
</section>

<ng-template #defaultTpl>
	<section class="card status-card declined-card error-card">
		<div class="header">
			<div class="card-logo">
				<svg *ngIf="_appService.isNotBrightTheme" viewBox="0 0 28 20">
					<path
						d="M17.5 19H2.6A2.65 2.65 0 0 1 0 16.4V2.6A2.65 2.65 0 0 1 2.6 0h22.8A2.65 2.65 0 0 1 28 2.6v7.9a.5.5 0 0 1-1 0V2.6A1.58 1.58 0 0 0 25.4 1H2.6A1.58 1.58 0 0 0 1 2.6v13.8A1.58 1.58 0 0 0 2.6 18h14.9a.5.5 0 0 1 0 1z"
					/>
					<g opacity=".5">
						<path d="M.5 3.4h27v4.2H.5z" />
						<path d="M28 8H0V3h28zM1 7.2h26V3.8H1z" />
					</g>
					<path
						d="M0 3v5h28V3zm27 4H1V4h26zM8 11H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a.94.94 0 0 0 1-1v-2a1 1 0 0 0-1-1zm0 3H4v-2h4zm15-3a4 4 0 1 1-4 4 4 4 0 0 1 4-4m0-1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"
					/>
					<path
						class="stroked cross"
						fill="none"
						stroke="#000"
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M21.5 13.5l3 3m0-3l-3 3"
					/>
				</svg>

				<img *ngIf="_appService.isBrightTheme" src="assets/images/payment-failure.svg" />
			</div>

			<div class="heading">
				<ng-container *ngIf="!_session.isPayWithCheckout">
					<div class="title">{{ 'page.card-declined.title' | translate }}</div>
				</ng-container>

				<ng-container *ngIf="_session.isPayWithCheckout">
					<div class="title">
						<bp-payment-instance-out-of-total-text />
						{{ 'was declined' | translate }}
					</div>

					<div class="slogan">
						{{ 'You will be automatically redirected to try again' | translate }}
					</div>
				</ng-container>
			</div>
		</div>

		<ng-container *ngIf="!_session.isPayWithCheckout">
			<div class="card-content">
				@if (
					_transaction.status === 'declined_due_to3_d_auth_failure' ||
					_transaction.status === 'three_ds_not_authenticated'
				) {
					<div class="text">
						{{ 'Card authentication was unsuccessful' | translate }}
					</div>
				} @else {
					<ng-container *ngIf="_error && !_error.isTransactionDeclined && !_canTryAgain">
						<div *ngFor="let msg of _error.messages" class="text">
							{{ msg.message }}
						</div>
					</ng-container>

					<div *ngIf="_canTryAgain" class="text">
						{{ 'page.card-declined.correct-card' | translate }}
					</div>
				}
			</div>

			<table *ngIf="_transaction?.details as details" class="card-table">
				<tbody>
					<tr *ngFor="let detail of details | keyvalue: _originalOrderKeyValueComparator">
						<td>{{ detail.key | titleCase }}:</td>
						<td>{{ detail.value }}</td>
					</tr>
				</tbody>
			</table>

			<div *ngIf="_canContinue" class="subtext redirect-info">
				{{ 'count-down.1' | translate }}
				<b>{{ _countDown$ | async }}</b>
				{{ 'count-down.2' | translate }}
			</div>

			<div
				class="buttons right"
				[class.card-float-button-position]="_appService.isNotBrightTheme"
				[class.two]="_canTryAgain && _canContinue"
			>
				<button
					*ngIf="_canTryAgain && _appService.returnUrl"
					class="btn"
					type="button"
					(click)="_navigateByUrl(_appService.returnUrl)"
				>
					{{ 'btn.try-again' | translate }}
				</button>

				<button *ngIf="_canContinue" class="btn" type="button" (click)="_continue()">
					{{ 'btn.continue' | translate }}
				</button>
			</div>
		</ng-container>
	</section>
</ng-template>
