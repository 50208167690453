<h4>
	<strong *ngIf="_appService.isNotBrightTheme">{{ 'amount' | translate }}</strong>

	<mat-form-field
		*ngIf="fiatCurrencies && fiatCurrencies.length > 1 && !(_session.currencyLock && _session.currencyCode)"
		class="currency-selector"
		appearance="standard"
	>
		<mat-select [value]="currency" (valueChange)="_onCurrencyChange($event)">
			<mat-select-trigger> {{ _currentCurrency?.symbol }}</mat-select-trigger>
			<mat-option *ngFor="let c of fiatCurrencies" [value]="c">
				{{ c.code }} {{ c.code === c.symbol ? '' : c.symbol }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</h4>

<div *ngIf="amountLock; else amountInputTpl" class="total-amount">
	<ng-container *ngIf="installmentAmount; else defaultTpl">
		<span class="accented" [bpFitText]="{ maxSize: _amountMaxFontSize }">
			≈ {{ installmentAmount | currency: currency!.code }}
		</span>
	</ng-container>

	<ng-template #defaultTpl>
		<ng-container *ngIf="_rate; else nonConvertibleAmountTpl">
			<span class="accented" [bpFitText]="{ maxSize: _amountMaxFontSize }">
				{{ _initialAmount | currency: currency!.code }}
			</span>
			<span class="converted" [bpFitText]="{ maxSize: 12 }">
				equals to {{ _initialAmount | bpMultiply: _rate | currency: _session.currencyCode }}
			</span>
		</ng-container>

		<ng-template #nonConvertibleAmountTpl>
			<span class="accented" [bpFitText]="{ maxSize: _amountMaxFontSize }">
				{{ _initialAmount | currency: currency!.code }}
			</span>
		</ng-template>
	</ng-template>
</div>

<ng-template #amountInputTpl>
	<div
		*ngIf="_appService.isNotBrightTheme && (_min || _max)"
		class="amount-constraints"
		[bpFitText]="{ maxSize: 12 }"
	>
		{{ 'cmpt.amount.min' | translate }}

		<span matTooltip="{{ _min | currency: _currentCurrency!.code }}">
			{{ _currentCurrency!.symbol }}{{ _min || 0 | bpIntlCompactNumber | async }}
		</span>
		/

		<span class="warning">
			{{ 'cmpt.amount.max' | translate }}
			<span matTooltip="{{ _max | currency: _currentCurrency!.code }}"
				>{{ _currentCurrency!.symbol }}{{ _max | bpIntlCompactNumber | async }}</span
			>
		</span>
	</div>

	<mat-form-field class="total-amount accented">
		<input
			name="amount"
			type="text"
			inputmode="decimal"
			matInput
			[bpTextMask]="_amountMask"
			[formControl]="control!"
			(focus)="inputFocus.emit()"
		/>

		<mat-hint *ngIf="_rate" [bpFitText]="{ maxSize: 12 }">
			equals to {{ _value$ | async | bpMultiply: _rate | currency: _session.currencyCode }}
		</mat-hint>

		<mat-error bpFieldError />
	</mat-form-field>
</ng-template>
